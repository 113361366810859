import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Box,
} from "@mui/material";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import {
  fetchAttendance,
  fetchchapterbyuser,
  fetchMemberAttendanceById,
} from "src/utils/common";
import Select from "react-select";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import {
  fetchChaptList,
  fetchMemberAttendanceByChepter,
} from "src/utils/common";
import moment from "moment";
import { notification } from "src/utils/messages";
import DataTable from "react-data-table-component";
import Modal from "@mui/material/Modal";
import {
  DeleteSharp,
  CalendarMonth,
  EditSharp,
  PlaylistAddCheckCircleIcon,
  RemoveRedEye,
  CheckCircleRounded,
  Cancel,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function ChapterAttendanceReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const memberActiveType = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "De-Active" },
  ];
  const [chaptlist, setchaplist] = useState([]);
  const [myModal, setMyModal] = useState(false);
  const [searchdata, setsearchdata] = useState({
    chept_id: 0,
    mActive: -1,
    s_date: "",
    e_date: "",
  });
  const [memberdata, setmemberdata] = useState([]);
  const [adata, setadata] = useState([]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 4,
  };
  useEffect(async () => {
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  }, []);

  const fetchmemberattendace = async (m_id, s_date, e_date) => {
    const fndata = new FormData();
    fndata.append("m_id", m_id);
    fndata.append("s_date", s_date);
    fndata.append("e_date", e_date);
    const resp = await fetchMemberAttendanceById(fndata);
    if (resp.status == 1) {
      setMyModal(true);
      setmemberdata(resp.data);
    }
  };
  const cancelModal = () => {
    setMyModal(false);
  };
  const expo_columns = [
    { label: "No", key: "m_id" },
    { label: "Name", key: "m_name" },
    { label: "Business Name", key: "business_name" },
    { label: "Total Meeting", key: "total" },
    { label: "Present", key: "present" },
    { label: "Absent", key: "absent" },
  ];
  const columns = [
    {
      name: "No",
      selector: (row) => row.m_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.m_name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Business Name",
      selector: (row) => row.business_name,
      sortable: true,
    },

    {
      name: "Total Meeting",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "Present",
      selector: (row) => row.present,
      sortable: true,
    },
    {
      name: "Absent",
      selector: (row) => row.absent,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <a
            onClick={() => {
              fetchmemberattendace(
                row.m_id,
                searchdata?.s_date,
                searchdata?.e_date
              );
            }}
          >
            <Tooltip title="View Details">
              <IconButton>
                <RemoveRedEye color="info" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];
  const handleSubmit = async () => {
    if (
      searchdata.chept_id == undefined ||
      searchdata.e_date == undefined ||
      searchdata.s_date == undefined
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      setadata(await fetchMemberAttendanceByChepter(searchdata));
    }
  };

  const ClearSearchResult = async () => {
    setsearchdata({
      chept_id: 0,
      mActive: -1,
      s_date: "",
      e_date: "",
    });
    setadata([]);
  };
  return (
    <>
      <Modal
        open={myModal}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {memberdata[0]?.m_name} ({memberdata[0]?.business_name}) Attendance
            Report
          </Typography>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                maxHeight: "280px",
                overflowY: "scroll",
                overflowX: "hidden",
                padding: "15px",
              }}
            >
              <div className="Poll_details">
                {memberdata &&
                  memberdata?.map((m) => {
                    return (
                      <>
                        <div
                          className="loginBtn"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px 15px",
                            borderRadius: "15px",
                            boxShadow: "0 0 5px -2px #000",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ margin: "0 auto" }}
                          >
                            <Grid
                              item
                              xs={10}
                              style={{
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {m.date}
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                textAlign: "center",
                                padding: "10px 15px",
                              }}
                            >
                              <Tooltip
                                title={m.status == 0 ? "Present" : "Absent"}
                              >
                                <IconButton>
                                  {m.status == 0 ? (
                                    <CheckCircleRounded color="success" />
                                  ) : (
                                    <Cancel color="error" />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={cancelModal}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Page title="Chapter's Attendance Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Chapter's Attendance Report
            </Typography>
          </Stack>

          <Card style={{ marginTop: "25px", padding: "25px" }}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Stack spacing={3} style={{ marginBottom: "15px" }}>
                    <ChapterSelectDropDown
                      dropDownLabel={"Select Chapter"}
                      selectedState={searchdata}
                      chapterKey="chept_id"
                      handleChange={(e) => {
                        setsearchdata({
                          ...searchdata,
                          chept_id: e.value,
                        });
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={3}>
                  <Stack spacing={3} style={{ marginBottom: "15px" }}>
                    <label style={{ marginBottom: "10px" }}>
                      Member Active Type
                    </label>
                    <Select
                      menuPortalTarget={document.body}
                      value={
                        memberActiveType &&
                        memberActiveType.map((option) => {
                          if (option.value == searchdata.mActive) {
                            return { value: option.value, label: option.label };
                          }
                        })
                      }
                      onChange={(e) => {
                        setsearchdata({ ...searchdata, mActive: e.value });
                      }}
                      options={
                        memberActiveType &&
                        memberActiveType.map((option) => {
                          return { value: option.value, label: option.label };
                        })
                      }
                    />
                  </Stack>
                </Grid>

                <Grid item xs={6}>
                  <Stack spacing={3}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      style={{ marginTop: "15px" }}
                    >
                      <DatePicker
                        type="date"
                        name="s_date"
                        label="Start Date "
                        inputFormat="dd/MM/yyyy"
                        value={
                          searchdata?.s_date != ""
                            ? moment(searchdata?.s_date, "DD-MM-YYYY")
                            : null
                        }
                        onChange={(newDate) => {
                          setsearchdata({
                            ...searchdata,
                            s_date: moment(newDate, "DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            ),
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={3}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      style={{ marginTop: "15px" }}
                    >
                      <DatePicker
                        type="date"
                        name="e_date"
                        label="End Date "
                        inputFormat="dd/MM/yyyy"
                        value={
                          searchdata?.e_date != ""
                            ? moment(searchdata?.e_date, "DD-MM-YYYY")
                            : null
                        }
                        onChange={(newDate) => {
                          setsearchdata({
                            ...searchdata,
                            e_date: moment(newDate, "DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            ),
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <div style={{ float: "right" }}>
                    <Button
                      variant="contained"
                      style={{ margin: "0 10px" }}
                      onClick={handleSubmit}
                    >
                      Search
                    </Button>

                    <Button
                      variant="outlined"
                      style={{ margin: "0 10px" }}
                      onClick={ClearSearchResult}
                    >
                      Clear
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Stack>
            {adata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={adata}
                    headers={expo_columns}
                    filename={"Chapter's Attendance Report.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  title="Attendance History"
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={adata}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
