import * as Yup from "yup";
import { React, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Stack, Container, TextField, Button, Avatar } from "@mui/material";
import Grid from "@mui/material/Grid";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import {
  GetAllChapter,
  fetchCityList,
  fetchStateList,
  fetchchapterbyuser,
} from "../../../utils/common";
import {
  checkMemberValidation,
  insertmember,
  UpdateMember,
} from "src/api/commonapi";
import Select from "react-select";
import { notification } from "src/utils/messages";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
// ----------------------------------------------------------------------

export default function MemberForm({ setStep, data, step, memberlist }) {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const memberTypeOption = [
    { value: 0, label: "Member" },
    { value: 1, label: "President" },
    { value: 2, label: "Vice President" },
    { value: 3, label: "ST" },
    { value: 4, label: "LVH" },
  ];
  let d = new Date();
  const [citylist, setcitylist] = useState(null);
  const [statelist, setstatelist] = useState(null);
  const [chapterlist, setchapterlist] = useState(null);
  const [response, setresponse] = useState(0);
  const [formdata, setformdata] = useState({
    chpt_id: 0,
    joining_date: "",
    due_date: "",
    memberType: 0,
    m_name: "",
    type: 0,
    m_email_id: "",
    mobile_no: "",
    password: "",
    business_name: "",
    business_category: "",
    state_id: 0,
    city_id: 0,
    photo: "",
    reference_m_id: 0,
  });
  const [joindate, setjoinDate] = useState(null);
  const [duedate, setdueDate] = useState(null);
  const [setfile, setFile] = useState(null);

  let startdate = {};
  let new_date = {};
  const setnewformdata = (e) => {
    e.preventDefault();
    if (e.target.name == "mobile_no") {
      if (e.target.value.length > 10) {
      } else {
        setformdata({ ...formdata, [e.target.name]: e.target.value });
      }
    } else {
      setformdata({ ...formdata, [e.target.name]: e.target.value });
    }
  };
  useEffect(async () => {
    if (step == 3) {
      setformdata(data);
      setcitylist(await fetchCityList(data.state_id));
    }
    setchapterlist(
      await fetchchapterbyuser(loginUser.city_id, loginUser.u_type)
    );
    setstatelist(await fetchStateList());
  }, []);

  const handleStateSelect = async (selectedState) => {
    setcitylist(await fetchCityList(selectedState.value));
    setformdata({ ...formdata, state_id: selectedState.value });
  };
  const handleChapterSelect = async (selectedChapter) => {
    setformdata({ ...formdata, chpt_id: selectedChapter.value });
  };
  const handleCitySelect = async (selectedCity) => {
    setformdata({ ...formdata, city_id: selectedCity.value });
  };

  const handleChaptermemberType = async (selectedMemberType) => {
    setformdata({ ...formdata, memberType: selectedMemberType.value });
  };
  const handleSubmit = async () => {
    let resp = null;
    if (step == 2) {
      if (
        formdata.chpt_id !== 0 &&
        formdata.m_name != "" &&
        formdata.mobile_no != "" &&
        formdata.password != "" &&
        formdata.business_name != "" &&
        formdata.business_category != "" &&
        formdata.joining_date != "" &&
        formdata.due_date != ""
      ) {
        let fdata = new FormData();
        fdata.append("chpt_id", formdata.chpt_id);
        fdata.append("m_name", formdata.m_name);
        fdata.append("photo", formdata.photo);
        fdata.append("mobile_no", formdata.mobile_no);
        fdata.append("m_email_id", formdata.m_email_id);
        fdata.append("password", formdata.password);
        fdata.append("type", formdata.memberType);
        fdata.append("business_name", formdata.business_name);
        fdata.append("business_category", formdata.business_category);
        fdata.append("joining_date", formdata.joining_date);
        fdata.append("due_date", formdata.due_date);
        fdata.append("state_id", formdata.state_id);
        fdata.append("city_id", formdata.city_id);
        fdata.append("reference_m_id", formdata.reference_m_id);
        //0-new member 1-renewal member
        fdata.append("member_type", 0);
        resp = await insertmember(fdata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
      } else {
        let notify = notification({
          type: "error",
          message: "Enter All Details And Try Agani",
        });
        notify();
      }
    } else {
      if (
        formdata.m_name != "" &&
        formdata.mobile_no != "" &&
        formdata.password != "" &&
        formdata.business_name != "" &&
        formdata.business_category != "" &&
        formdata.joining_date != "" &&
        formdata.due_date != ""
      ) {
        let checkMobile = await checkMemberValidation({
          m_id: data.m_id,
          mobile_no: formdata.mobile_no,
        });
        if (checkMobile?.status != 0) {
          let fdata = new FormData();
          fdata.append("m_id", data.m_id);
          fdata.append("chpt_id", formdata.chpt_id);
          fdata.append("m_name", formdata.m_name);
          fdata.append("photo", formdata.photo);
          fdata.append("mobile_no", formdata.mobile_no);
          fdata.append("m_email_id", formdata.m_email_id);
          fdata.append("password", formdata.password);
          fdata.append("type", formdata.type);
          fdata.append("business_name", formdata.business_name);
          fdata.append("business_category", formdata.business_category);
          fdata.append("joining_date", formdata.joining_date);
          fdata.append("due_date", formdata.due_date);
          fdata.append("state_id", formdata.state_id);
          fdata.append("city_id", formdata.city_id);
          fdata.append("reference_m_id", formdata.reference_m_id);
          resp = await UpdateMember(fdata);
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message,
          });
          notify();
          setresponse(response + 1);
        } else {
          let notify = notification({
            type: checkMobile.status == 0 ? "error" : "success",
            message: checkMobile.message,
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Enter All Details And Try Agani",
        });
        notify();
      }
    }
    if (resp?.status == 1) setStep(1);
  };

  return (
    <Container>
      <Stack spacing={10} style={{ marginBottom: "15px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <ChapterSelectDropDown
                dropDownLabel={"Select Chapter"}
                selectedState={formdata}
                chapterKey="chpt_id"
                handleChange={handleChapterSelect}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select Member Type</label>
              <Select
                styles={{ marginTop: "0" }}
                isDisabled
                selectedValue
                value={
                  memberTypeOption == null
                    ? { value: 0, label: "Select Chapter" }
                    : memberTypeOption
                        .map((mt) => {
                          if (mt.value == formdata.type) {
                            return { value: mt.value, label: mt.label };
                          }
                        })
                        .filter((n) => n)[0]
                }
                style={{ marginTop: "0px" }}
                name="memberType"
                onChange={handleChaptermemberType}
                options={
                  memberTypeOption != null &&
                  memberTypeOption.map((mt) => {
                    return { value: mt.value, label: mt.label };
                  })
                }
              ></Select>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              spacing={3}
              style={{ marginBottom: "15px", marginTop: "5px" }}
            >
              {/* <label style={{ marginBottom: '10px' }}>Join Date</label> */}
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                isDisabled
                style={{ marginTop: "15px" }}
              >
                <DatePicker
                  disabled={
                    loginUser?.u_type == 0 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id == 1 &&
                      loginUser?.management_p != 0)
                      ? false
                      : true
                  }
                  type="date"
                  name="joindate"
                  label="Joining Date "
                  value={
                    formdata.joining_date != ""
                      ? moment(formdata.joining_date, "DD-MM-YYYY")
                      : null
                  }
                  inputFormat="dd/MM/yyyy"
                  onChange={(newDate) => {
                    setformdata({
                      ...formdata,
                      joining_date: moment(newDate, "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      ),
                      due_date: moment(newDate, "DD-MM-YYYY")
                        .add(1, "years")
                        .format("DD-MM-YYYY"),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              spacing={3}
              style={{ marginBottom: "15px", marginTop: "5px" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                style={{ marginTop: "15px" }}
              >
                <DatePicker
                  disabled={
                    loginUser?.u_type == 0 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id == 1 &&
                      loginUser?.management_p != 0)
                      ? false
                      : true
                  }
                  label="Expire Date "
                  name="expiredate"
                  value={
                    formdata.due_date != ""
                      ? moment(formdata.due_date, "DD-MM-YYYY")
                      : null
                  }
                  inputFormat="dd/MM/yyyy"
                  onChange={(newDate) => {
                    setformdata({
                      ...formdata,
                      due_date: moment(newDate, "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      ),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <TextField
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Member Name"
                name="m_name"
                value={formdata?.m_name}
                onChange={setnewformdata}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <TextField
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="email"
                label="Member Email Id"
                name="m_email_id"
                value={formdata?.m_email_id}
                onChange={setnewformdata}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <TextField
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="number"
                label="Member Contact No"
                onWheel={(e) => e.currentTarget.blur()}
                name="mobile_no"
                value={formdata?.mobile_no}
                onChange={setnewformdata}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <TextField
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Member Password"
                name="password"
                value={formdata?.password}
                onChange={setnewformdata}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <TextField
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Business Name"
                name="business_name"
                value={formdata?.business_name}
                onChange={setnewformdata}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <TextField
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Business Category"
                name="business_category"
                value={formdata?.business_category}
                onChange={setnewformdata}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select State</label>
              <Select
                value={
                  statelist == null
                    ? { value: 0, label: "Select State" }
                    : statelist
                        .map((c) => {
                          if (c.state_id == formdata.state_id) {
                            return { value: c.state_id, label: c.state_name };
                          }
                        })
                        .filter((n) => n)[0]
                }
                style={{ marginTop: "0px" }}
                name="state_id"
                onChange={handleStateSelect}
                options={
                  statelist != null &&
                  statelist.map((sta) => {
                    return { value: sta.state_id, label: sta.state_name };
                  })
                }
              ></Select>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select City</label>
              <Select
                value={
                  citylist == null
                    ? { value: 0, label: "Select State" }
                    : citylist
                        .map((c) => {
                          if (c.city_id == formdata.city_id) {
                            return { value: c.city_id, label: c.city_name };
                          }
                        })
                        .filter((n) => n)[0]
                }
                style={{ marginTop: "0px" }}
                name="city_id"
                onChange={handleCitySelect}
                options={
                  citylist != null &&
                  citylist.map((c) => {
                    return { value: c.city_id, label: c.city_name };
                  })
                }
              ></Select>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Reference By</label>
              <Select
                value={
                  memberlist == null
                    ? { value: 0, label: "Select Chapter" }
                    : memberlist
                        .map((c) => {
                          if (c.m_id == formdata.reference_m_id) {
                            return {
                              value: c.m_id,
                              label: c.m_name + " (" + c.business_name + ")",
                            };
                          }
                        })
                        .filter((n) => n)[0]
                }
                style={{ marginTop: "0px" }}
                name="reference_m_id"
                onChange={(e) => {
                  setformdata({ ...formdata, reference_m_id: e.value });
                }}
                options={
                  memberlist != null &&
                  memberlist.map((chpt) => {
                    return {
                      value: chpt.m_id,
                      label: chpt.m_name + " (" + chpt.business_name + ")",
                    };
                  })
                }
              ></Select>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <label style={{ marginBottom: "10px" }}>
              {step == 3
                ? "Upload New Image If You Want "
                : "Upload User Profile Image"}
            </label>
            <br />
            <Stack direction="row" alignItems={"center"} spacing={2}>
              <Button
                variant="contained"
                component="label"
                sx={{ height: 40 }}
                style={{ marginTop: "10px" }}
              >
                Click To Upload File
                <input
                  id="file"
                  name="file"
                  type="file"
                  hidden
                  onChange={(event) => {
                    setformdata({
                      ...formdata,
                      photo: event.currentTarget.files[0],
                    });
                  }}
                  className="form-control"
                />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Button
        style={{ marginTop: "15px" }}
        onClick={handleSubmit}
        variant="contained"
      >
        Save
      </Button>
    </Container>
  );
}
