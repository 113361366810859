// material
import {
  ArrowCircleLeft,
  ArrowDropUp,
  ArrowDropUpOutlined,
  ArrowRight,
  ArrowRightAlt,
  AutoStories,
  Book,
  CallMade,
  CallMadeOutlined,
  CallReceivedOutlined,
  ContentCopy,
  CurrencyRupeeSharp,
  Facebook,
  FacebookRounded,
  FacebookTwoTone,
  LeaderboardSharp,
  Link,
  PersonSharp,
  Task,
  TaskAltSharp,
} from "@mui/icons-material";

import {
  Box,
  Card,
  Grid,
  Container,
  Typography,
  CardHeader,
  Tooltip,
  IconButton,
  Icon,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getDashboardCounter } from "src/api/commonapi";
import {
  fetchcityambassadorbyid,
  fetchdashboardcounter,
  fetchLvbCounterWeb,
} from "src/utils/common";
import { notification } from "src/utils/messages";

// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";
import { alpha, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Icons } from "react-toastify";
import { Routes } from "src/constant/api_url";
// import Paper from "src/theme/overrides/Paper";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  let navigate = useNavigate();
  const [formLink, setFormLink] = useState();
  const [dashboardCount, setDashboardCount] = useState({});
  const [myLvbCounter, setMyLvbCounter] = useState({});
  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    padding: theme.spacing(5, 0),
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.lighter,
  }));

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.info.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.info.dark,
      0
    )} 0%, ${alpha(theme.palette.info.dark, 0.24)} 100%)`,
  }));
  // let formLink = "";
  useEffect(async () => {
    setDashboardCount(
      await fetchdashboardcounter(
        loginUser?.u_id,
        loginUser?.u_type,
        loginUser?.city_id
      )
    );
    if (
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 && loginUser?.p_user_id != 1)
    ) {
      setCityFormLink();
    } else if (loginUser?.u_type == 2 && loginUser?.p_user_id == 1) {
      setFormLink(window.location.origin + "/inquiry-form");
    } else {
      setFormLink(window.location.origin + "/inquiry-form");
    }
    if (
      loginUser?.u_type == 0 ||
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 && loginUser?.management_p == 1)
    ) {
      if (
        (loginUser?.u_type == 2 && loginUser?.p_user_id == 1) ||
        loginUser?.u_type == 0
      ) {
        setMyLvbCounter(await fetchLvbCounterWeb(0));
      } else if (
        loginUser?.u_type == 1 ||
        (loginUser?.u_type == 2 && loginUser?.management_p == 1)
      ) {
        setMyLvbCounter(await fetchLvbCounterWeb(loginUser?.city_id));
      }
    }
  }, []);

  function strToAmountNumberConvert(value) {
    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000) {
      val = (val / 1000).toFixed(2) + " K";
    } else {
      val = val;
    }
    return val;
  }

  const setCityFormLink = async () => {
    const resp = await fetchcityambassadorbyid(loginUser?.city_id);
    let data = resp && resp.u_id.toString();
    let buff = new Buffer(data);
    let base64data = buff.toString("base64");
    let customURL =
      Routes.LEADFORMPUBLICURL + "/lvb-membership-form/" + base64data;
    setFormLink(Routes.LEADFORMPUBLICURL + "lvb-membership-form/" + base64data);
  };
  const openInNewTab = async () => {
    navigator.clipboard.writeText(formLink);
    let notify = notification({
      type: "info",
      message: "Copy to clipboard",
      position: "top",
    });
    await notify();
  };
  return (
    <Page title="Local Vocal Business Group">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome {loginUser?.name}</Typography>
        </Box>
        {/* <CardHeader title="Form Link" /> */}
        <Grid container spacing={12}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ py: 3 }}>
              <Card>
                <CardHeader
                  title={
                    <>
                      <span>Form Link</span>
                      <span
                        onClick={async () => {
                          openInNewTab();
                        }}
                      >
                        <Tooltip title="Click to Copy Link">
                          <IconButton>
                            <ContentCopy color="dark" />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </>
                  }
                />
                <Box sx={{ px: 3, py: 1 }}>
                  <div style={{ display: "flex" }}>
                    <span>
                      <Link
                        color="dark"
                        className="RotateIcon"
                        style={{ transform: "rotate(140deg)" }}
                      />
                    </span>
                    <span style={{ marginLeft: "5px" }}>{formLink}</span>
                  </div>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
        {/* Counter */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ py: 3 }}>
              <Card className="box-hover">
                <Box sx={{ px: 3, py: 4 }} style={{ textAlign: "center" }}>
                  <IconWrapperStyle>
                    <Task
                      width={24}
                      height={24}
                      color="primary"
                      className="tada-amin"
                    />
                  </IconWrapperStyle>
                  <Typography variant="h3">
                    {dashboardCount?.task_count}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                  >
                    Total Task
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ py: 3 }}>
              <Card className="box-hover">
                <Box sx={{ px: 3, py: 4 }} style={{ textAlign: "center" }}>
                  <IconWrapperStyle>
                    <LeaderboardSharp
                      width={24}
                      height={24}
                      color="warning"
                      className="tada-amin"
                    />
                  </IconWrapperStyle>
                  <Typography variant="h3">
                    {dashboardCount?.lead_count}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                  >
                    Total Lead
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Grid>
          {loginUser?.u_type != 2 && (
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ py: 3 }}>
                <Card className="box-hover">
                  <Box sx={{ px: 3, py: 4 }} style={{ textAlign: "center" }}>
                    <IconWrapperStyle>
                      <AutoStories
                        width={24}
                        height={24}
                        color="success"
                        className="tada-amin"
                      />
                    </IconWrapperStyle>
                    <Typography variant="h3">
                      {dashboardCount?.chepter_count}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                    >
                      Total Chapter
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          )}
          {loginUser?.u_type == 1 && (
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ py: 3 }}>
                <Card className="box-hover">
                  <Box
                    sx={{ px: 3, py: 4 }}
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      navigate("/wallethistory/" + loginUser.u_id, {
                        replace: true,
                      });
                    }}
                  >
                    <IconWrapperStyle>
                      <CurrencyRupeeSharp
                        width={24}
                        height={24}
                        color="error"
                        className="tada-amin"
                      />
                    </IconWrapperStyle>
                    <Typography variant="h3">
                      {dashboardCount?.wallet_balance}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                    >
                      Wallet Balance
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          )}
          {loginUser?.u_type == 0 && (
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ py: 3 }}>
                <Card className="box-hover">
                  <Box sx={{ px: 3, py: 4 }} style={{ textAlign: "center" }}>
                    <IconWrapperStyle>
                      <PersonSharp
                        width={24}
                        height={24}
                        color="error"
                        className="tada-amin"
                      />
                    </IconWrapperStyle>
                    <Typography variant="h3">
                      {dashboardCount?.member_count}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                    >
                      Total Members
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          )}
          {loginUser?.u_type == 2 && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ py: 3 }}>
                  <Card className="box-hover">
                    <Box sx={{ px: 3, py: 4 }} style={{ textAlign: "center" }}>
                      <IconWrapperStyle>
                        <Task
                          width={24}
                          height={24}
                          color="success"
                          className="tada-amin"
                        />
                      </IconWrapperStyle>
                      <Typography variant="h3">
                        {dashboardCount?.task_allocated_count}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                      >
                        My Task
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ py: 3 }}>
                  <Card className="box-hover">
                    <Box sx={{ px: 3, py: 4 }} style={{ textAlign: "center" }}>
                      <IconWrapperStyle>
                        <LeaderboardSharp
                          width={24}
                          height={24}
                          color="error"
                          className="tada-amin"
                        />
                      </IconWrapperStyle>
                      <Typography variant="h3">
                        {dashboardCount?.lead_allocated_count}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ opacity: 1, color: "#707070", fontSize: "17px" }}
                      >
                        My Lead
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {/* End Counter */}

        {/* City Or LVB Counter */}

        {/* {loginUser?.u_type == 0 ||
        loginUser?.u_type == 1 ||
        (loginUser?.u_type == 2 && loginUser?.management_p == 1) ? (
          <Card style={{ paddingLeft: "15px", paddingRight: "15px", paddingBottom: "15px" }}>
            <CardHeader
              title={
                (loginUser?.u_type == 2 && loginUser?.p_user_id == 1) || loginUser?.u_type == 0
                  ? "LVB Counter"
                  : `${myLvbCounter.Type} City Counter`
              }
              style={{ paddingLeft: "0", marginBottom: "15px" }}
            />
            <Grid container spacing={3}>
              {(loginUser?.u_type == 2 && loginUser?.p_user_id == 1) || loginUser?.u_type == 0 ? (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">Total Visitor</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.LVB_visitor_count}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallMadeOutlined
                            width={32}
                            height={32}
                            color="#1877F2"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">Reference Given</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.LVB_reference_given_count}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <FacebookTwoTone
                            width={32}
                            height={32}
                            color="#1877F2"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">Business Given</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {strToAmountNumberConvert(myLvbCounter.LVB_business_recive_amt)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <FacebookTwoTone
                            width={32}
                            height={32}
                            color="#1877F2"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">Total V2V</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.LVB_v2v_count}
                      </Typography>
                    </Paper>
                  </Grid>
                </>
              ) : loginUser?.u_type == 1 ||
                (loginUser?.u_type == 2 && loginUser?.management_p == 1) ? (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">City Visitor</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.City_visitor_count}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">City Reference Given</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.City_reference_given_count}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">City Reference Receive</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.City_reference_recive_count}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">City V2V</Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {myLvbCounter.City_v2v_count}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">City Business Given </Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {strToAmountNumberConvert(myLvbCounter.City_business_given_amt)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                      <Box sx={{ px: 1, py: 2 }}>
                        <IconWrapperStyle style={{ margin: "0 auto" }}>
                          <CallReceivedOutlined
                            width={32}
                            height={32}
                            color="#00FF00"
                            style={{ margin: "0" }}
                          />
                        </IconWrapperStyle>
                      </Box>
                      <Typography variant="h4">City Business Receive </Typography>
                      <Typography variant="body1" sx={{ color: "#000" }}>
                        {strToAmountNumberConvert(myLvbCounter.City_business_recive_amt)}
                      </Typography>
                    </Paper>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Card>
        ) : (
          ""
        )} */}
        {/* <Card style={{ paddingLeft: "15px", paddingRight: "15px", paddingBottom: "15px" }}>
          
          <CardHeader
            title={
              (loginUser?.u_type == 2 && loginUser?.p_user_id == 1) || loginUser?.u_type == 0
                ? "LVB Counter"
                : `${myLvbCounter.Type} City Counter`
            }
            style={{ paddingLeft: "0", marginBottom: "15px" }}
          />
          <Grid container spacing={3}>
            {(loginUser?.u_type == 2 && loginUser?.p_user_id == 1) || loginUser?.u_type == 0 ? (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <CallReceivedOutlined
                          width={32}
                          height={32}
                          color="#00FF00"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Total Visitor</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      {myLvbCounter.LVB_visitor_count}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <CallMadeOutlined
                          width={32}
                          height={32}
                          color="#1877F2"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Reference Given</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      {myLvbCounter.LVB_reference_given_count}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <FacebookTwoTone
                          width={32}
                          height={32}
                          color="#1877F2"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Business Given</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      {strToAmountNumberConvert(myLvbCounter.LVB_business_recive_amt)}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <FacebookTwoTone
                          width={32}
                          height={32}
                          color="#1877F2"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Total V2V</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      {myLvbCounter.LVB_v2v_count}
                    </Typography>
                  </Paper>
                </Grid>
              </>
            ) : loginUser?.u_type == 1 ||
              (loginUser?.u_type == 2 && loginUser?.management_p == 1) ? (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <CallReceivedOutlined
                          width={32}
                          height={32}
                          color="#00FF00"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Business Given</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      ₹ 1000000000.00/-
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <CallMadeOutlined
                          width={32}
                          height={32}
                          color="#1877F2"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Business Given</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      ₹ 1000000000.00/-
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <FacebookTwoTone
                          width={32}
                          height={32}
                          color="#1877F2"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Business Given</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      ₹ 1000000000.00/-
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper variant="outlined" sx={{ px: 1, py: 1, textAlign: "center" }}>
                    <Box sx={{ px: 1, py: 2 }}>
                      <IconWrapperStyle style={{ margin: "0 auto" }}>
                        <FacebookTwoTone
                          width={32}
                          height={32}
                          color="#1877F2"
                          style={{ margin: "0" }}
                        />
                      </IconWrapperStyle>
                    </Box>
                    <Typography variant="h4">Business Given</Typography>
                    <Typography variant="body1" sx={{ color: "#000" }}>
                      ₹ 1000000000.00/-
                    </Typography>
                  </Paper>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card> */}

        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid> */}
      </Container>
    </Page>
  );
}
