import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LoginRequest } from "src/api/auth";
import { notification } from "src/utils/messages";
import { Lock, LockOutlined, PermIdentity, PersonRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import "../../../Assets/css/style.css";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const loginStyle = {};
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string().required("Password is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true
    },
    validationSchema: LoginSchema,

    onSubmit: async (e) => {
      const inputemail = e.email;
      const inputpassword = e.password;

      const logindata = {
        email: inputemail,
        password: inputpassword
      };
      const jslogindata = JSON.stringify(logindata);
      const requestOption = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: jslogindata
      };
      const resp = await LoginRequest(jslogindata);
      if (resp.status == 1) {
        localStorage.setItem("userData", JSON.stringify(resp.data));
        navigate("/dashboard", { replace: true });
        // if (resp?.data?.u_type == 0) {
        //   navigate("/chapter", { replace: true });
        // } else if (resp?.data?.u_type == 1) {
        //   navigate("/staff", { replace: true });
        // } else {
        //   navigate("/lead", { replace: true });
        // }
        window.location.reload();
      } else {
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message
        });
        notify();
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* <div style={{ alignItems: "center" }}>
            <Icon>
              <PersonRounded color="primary" />
            </Icon>
          </div> */}
          <TextField
            sx={{ my: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>
                    <PermIdentity color="primary" />
                  </Icon>
                </InputAdornment>
              )
            }}
            fullWidth
            autoComplete="false"
            type="email"
            placeholder="Enter Email Address"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            sx={{ my: 2 }}
            fullWidth
            autoComplete="false"
            type={showPassword ? "text" : "password"}
            label="Password"
            placeholder="Enter Password"
            {...getFieldProps("password")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>
                    <LockOutlined color="primary" />
                  </Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon style={{ color: "#1c4766" }} icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps("remember")} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton
          sx={{ my: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          className="loginBtn"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
