import { async } from "q";
import Request from ".";
import { Routes } from "../constant/api_url";

// const insertmember = async (data) => {
//   try {
//     const result = await Request.post(Routes.INSERTMEMBER, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "application/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };
const getAllChapter = async () => {
  try {
    const result = await Request.get(Routes.ALLCHAPTER);
    return result;
  } catch (error) {
    throw error;
  }
};

const GetAllMemberApi = async () => {
  try {
    const result = await Request.get(Routes.GETALLMEMBER);
    return result.data;
  } catch (error) {
    throw error;
  }
};
// GETMEETING
const getAllMeetingApi = async () => {
  try {
    const result = await Request.get(Routes.GETMEETING);
    return result;
  } catch (error) {
    throw error;
  }
};

// const UpdateMemberStatus = async (data) => {
//   try {
//     const result = await Request.post(Routes.UPDATEMEMBERSTATUS, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "apllication/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const UpdateMemberPost = async (data) => {
//   try {
//     const result = await Request.post(Routes.UPDATEMEMBERPOST, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "apllication/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const UpdateMember = async (data) => {
//   try {
//     const result = await Request.post(Routes.UPDATEMEMBER, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "application/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const userLogout = async (data) => {
//   try {
//     const result = await Request.get(Routes.USERLOGOUT + "/" + data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "apllication/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const GetMemberById = async (id) => {
//   try {
//     const result = await Request.get(Routes.GETMEMBERBYID + "?memberid=" + id);
//     return result;
//   } catch (error) {
//     throw error;
//   }
// };

// const GetMemberByChptId = async (id) => {
//   try {
//     const result = await Request.get(Routes.GETMEMBERBYCHPT + "/" + id);
//     return result.data.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const getState = async () => {
//   try {
//     const result = await Request.get(Routes.GETSTATE);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const getCity = async (id) => {
//   try {
//     const result = await Request.get(Routes.GETCITY + "?stateid=" + id);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };
// const GetAllUserApi = async (id) => {
//   try {
//     const result = await Request.get(Routes.GETALLUSER);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const InsertMemberApi = async () => {
//   try {
//   } catch (error) {}
// };

// const getPollSelect = async (type) => {
//   try {
//     const result = await Request.get(Routes.GETPOLLSELECT + "/" + type);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const insertpoll = async (data) => {
//   try {
//     const result = await Request.post(Routes.INSERTPOLL, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "application/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };
// const insertevent = async (data) => {
//   try {
//     const result = await Request.post(Routes.INSERTEVENT, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "application/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const insertgallery = async (data) => {
//   try {
//     const result = await Request.post(Routes.INSERTGALLERY, data, {
//       headers: {
//         Accept: "application/json , */*",
//         "Content-Type": "apllication/json"
//       }
//     });
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const getAllGallery = async () => {
//   try {
//     const result = await Request.get(Routes.GETALLGALLERY);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const deleteGallery = async (g_id) => {
//   try {
//     const result = await Request.get(Routes.DELETEGALLERY + "/" + g_id);
//     return result?.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const getAllPoll = async () => {
//   try {
//     const result = await Request.get(Routes.GETALLPOLL);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const deletePoll = async (p_id) => {
//   try {
//     const result = await Request.get(Routes.DELETEPOLL + "/" + p_id);
//     return result?.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const getAllEventAnnou = async () => {
//   try {
//     const result = await Request.get(Routes.GETALLEVENTANNO);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };

// const EventAnnouDelete = async (e_id) => {
//   try {
//     const result = await Request.get(Routes.EVENTANNOUDELETE + "/" + e_id);
//     return result?.data;
//   } catch (error) {
//     throw error;
//   }
// };
// const getpollresult = async (p_id) => {
//   try {
//     const result = await Request.get(Routes.GETPOLLRESULT + "/" + p_id);
//     return result?.data;
//   } catch (error) {
//     throw error;
//   }
// };

export { getAllChapter, GetAllMemberApi, getAllMeetingApi };
