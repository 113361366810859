import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { Routes } from "../constant/api_url";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      // src="/static/LVB-Logo.png"
      src={Routes.API_HOSTNAME + "logo/LVB-Logo.png"}
      sx={{ width: "100%", height: "115px", objectFit: "contain", ...sx }}
    />
  );
}
