import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";
import { Routes } from "src/constant/api_url";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Select from "react-select";
import Page from "src/components/Page";
import { CSVLink } from "react-csv";
import { notification } from "src/utils/messages";
import Label from "src/components/Label";
import {
  DeleteSharp,
  EditSharp,
  FileUpload,
  FileUploadRounded,
  Filter,
  RemoveRedEye,
} from "@mui/icons-material";
import { ModalFooter } from "react-bootstrap";
import {
  deleteVisitorBlockList,
  getBlockListVisitorListByMobile,
  insertBlackListVisitorFunction,
} from "src/api/commonapi";
import {
  fetchallcitylist,
  getBlockVisitorCommonFunction,
} from "src/utils/common";
import Deletemodal from "./DeleteModal";
export default function VisitorBlock() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
  };
  const VisitorModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
  };
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [step, setStep] = useState(0);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const toggleOpenUploadModal = () => setOpenUploadModal(!openUploadModal);
  const [tableData, setTableData] = useState([]);
  const [fileUploadData, setFileUploadData] = useState([]);
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [blockVisitorList, setBlockVisitorList] = useState([]);
  const [nblockVisitorList, setnblockVisitorList] = useState([]);
  const [uploadFileDetails, setUploadFileDetails] = useState("");
  const [uploadFileIsCSV, setUploadFileIsCSV] = useState(true);
  const [fileUploadFormData, setFileUploadFormData] = useState({
    visitor_name: "",
    visitor_contact: "",
    visitor_business_name: "",
    visitor_business_category: "",
    visitor_type: 1,
    city_id: loginUser?.city_id || 0,
    createdBy: loginUser?.u_id || 1,
  });
  const [cityList, setCityList] = useState([]);
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    search: "",
  });
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [detailModal, setDetailModal] = useState(false);
  const toggleDetailModal = () => setDetailModal(!detailModal);

  const expo_columns = [
    { label: "No", key: "v_b_id" },
    { label: "Visitor Name", key: "visitor_name" },
    { label: "Visitor Mobile Number", key: "visitor_mo_no" },
    { label: "Visitor Business Name", key: "visitor_business_name" },
    { label: "Visitor Business Category", key: "visitor_business_category" },
    { label: "Date", key: "date" },
    { label: "City", key: "city_name" },
    { label: "Added By", key: "addedBy" },
    { label: "Added From", key: "visitor_type_desc" },
  ];
  const columns = [
    {
      name: "No",
      selector: (row) => row?.v_b_id,
      wrap: true,
      sortable: true,
      width: "70px",
    },

    {
      name: "Visitor Name",
      wrap: true,
      selector: (row) => row?.visitor_name,
      sortable: true,
    },
    {
      name: "Visitor Mobile Number",
      wrap: true,
      selector: (row) => row?.visitor_mo_no,
      sortable: true,
    },
    {
      name: "Visitor Business Name",
      wrap: true,
      selector: (row) => row?.visitor_business_name,
      sortable: true,
    },
    {
      name: "Visitor Business Category",
      wrap: true,
      selector: (row) => row?.visitor_business_category,
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row?.date,
      sortable: true,
    },
    {
      name: "City Name",
      wrap: true,
      selector: (row) => row?.city_name || "-",
      sortable: true,
    },
    {
      name: "Added By",
      wrap: true,
      selector: (row) => row?.addedBy || "-",
      sortable: true,
    },

    {
      name: "Added From",
      wrap: true,
      selector: (row) => (
        <Label
          variant={"ghost"}
          color={row.visitor_type == 1 ? "success" : "info"}
        >
          {row.visitor_type_desc}
        </Label>
      ),
      sortable: true,
    },
    (loginUser?.u_type == 0 ||
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 && loginUser?.management_p != 0)) && {
      name: "Action",
      sortable: true,
      wrap: true,
      selector: (row) => (
        <>
          {row?.visitor_type == 0 ? (
            <>
              <a
                onClick={() => {
                  getVisitorListByMobileNumber(row.visitor_mo_no);
                  //   setExportFileName(row?.name);
                  //   exportFilteredPin(row?.p_id);
                  toggleDetailModal();
                }}
              >
                <Tooltip title="See Previous Invited List">
                  <IconButton>
                    <RemoveRedEye color="success" />
                  </IconButton>
                </Tooltip>
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => {
                  setDvisible({ mval: true, id: row?.v_b_id });
                }}
              >
                <Tooltip title="Edit Pin">
                  <IconButton
                  // onClick={() => {
                  //   setPinData({
                  //     name: row.name,
                  //     p_id: row.p_id,
                  //     file: row.image,
                  //     status: row.status,
                  //   });
                  //   toggleAddEditModal();
                  // }}
                  >
                    <DeleteSharp color="error" />
                  </IconButton>
                </Tooltip>
              </a>
            </>
          )}
          <>
            {/* <Button
              className="btn btn-export"
              style={{ marginBottom: "15px", marginTop: "0" }}
              variant="contained"
              onClick={() => exportFilteredPin(row.p_id)}
              disabled={loading}
            >
              {loading ? "Exporting..." : "Export CSV"}
            </Button> */}
          </>
        </>
      ),
    },
  ].filter((f) => f != false);

  const checkMobileValid = (mobile) => {
    if (mobile != "") {
      const regex = /^(\+?91\s?)?(\d{10})$/;
      if (step == 1) {
        isValidMobile && setIsValidMobile(regex.test(mobile));
      } else {
        setIsValidMobile(regex.test(mobile));
      }
    } else {
      setIsValidMobile(true);
    }
  };
  const handleUploadedCSVFile = (e) => {
    setIsValidMobile(true);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setUploadFileDetails(selectedFile.name);
      if (selectedFile.name.endsWith(".csv")) {
        setUploadFileIsCSV(true);
        const reader = new FileReader();

        reader.onload = (e) => {
          const csvData = e.target.result;
          parseCsvData(csvData);
        };

        reader.readAsText(selectedFile);
      } else {
        setIsValidMobile(false);
        setUploadFileIsCSV(false);
      }
    } else {
      setFileUploadData([]);
    }
  };
  const parseCsvData = (csvData) => {
    const customKeys = [
      "visitor_name",
      "visitor_contact",
      "visitor_business_name",
      "visitor_business_category",
      // "city_id",
      // "createdBy",
    ]; // Your custom keys here
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const parsedData = [];

    for (let i = 1; i < lines.length; i++) {
      const row = lines[i].split(",");
      if (row.length === customKeys.length) {
        const rowData = {};
        for (let j = 0; j < customKeys.length; j++) {
          if (customKeys[j] == "visitor_contact") {
            // Regular expression to validate the mobile number (with optional country code "91")
            const regex = /^(\+?91\s?)?(\d{10})$/;
            checkMobileValid(row[j].trim());
            const value =
              j === customKeys.length - 1 ? row[j].trim() : row[j].trim();
            rowData[customKeys[j]] = value;
          } else if (customKeys[j] == "visitor_contact") {
            const value = j === customKeys.length - 1 ? 1 : 1;
            rowData[customKeys[j]] = value;
          }
          //  else if (customKeys[j] == "city_id") {
          //   const value =
          //     j === customKeys.length - 1
          //       ? loginUser?.city_id || 0
          //       : loginUser?.city_id || 0;
          //   rowData[customKeys[j]] = value;
          // }
          else {
            const value =
              j === customKeys.length - 1 ? row[j].trim() : row[j].trim();
            rowData[customKeys[j]] = value;
          }
          // const value =
          //   j === customKeys.length - 1 ? row[j].trim() : row[j].trim();
          // rowData[customKeys[j]] = value;
        }

        parsedData.push({
          ...rowData,
          city_id: loginUser?.city_id || 0,
          createdBy: loginUser?.u_id || 1,
        });
      }
    }

    setFileUploadData(parsedData);
    // Do whatever you need to do with the parsed data here
  };
  const handleFormSubmit = async () => {
    let fnData = [];
    if (step == 0) {
      if (
        fileUploadFormData.visitor_name.trim().length != 0 &&
        fileUploadFormData.visitor_contact.trim().length != 0
      ) {
        fnData.push(fileUploadFormData);
      } else {
        let notify = notification({
          type: "error",
          message: "Please Enter Visitor Name & Contact Number",
        });
        notify();
      }
    } else if (step == 1) {
      fnData = fileUploadData;
    }

    if (fnData.length > 0) {
      let res = await insertBlackListVisitorFunction(fnData);
      let notify = notification({
        type: res.status == 0 ? "error" : "success",
        message: res.message,
      });
      notify();
      if (res.status == 1) {
        getBlockVisitor();
      }
      setIsValidMobile(true);
      toggleOpenUploadModal();
      setFileUploadData([]);
      setUploadFileDetails("");

      setFileUploadFormData({
        visitor_name: "",
        visitor_contact: "",
        visitor_business_name: "",
        visitor_business_category: "",
      });
    } else {
      let notify = notification({
        type: "error",
        message: "Please Check Your Form Data OR file before submitting Form",
      });
      notify();
    }
  };

  useEffect(async () => {
    await getBlockVisitor();
  }, []);

  const getBlockVisitor = async () => {
    const result = await getBlockVisitorCommonFunction(loginUser.city_id);
    setBlockVisitorList(result);
  };

  useEffect(async () => {
    await filterData();
  }, [filter, blockVisitorList]);

  const filterData = async () => {
    let newData = [];
    newData =
      blockVisitorList &&
      blockVisitorList.filter((visitor) => {
        if (filter.city_id != 0 || filter.search.trim().length != 0) {
          if (
            (visitor.city_id == filter.city_id || filter.city_id == 0) &&
            (visitor.visitor_name
              .toLowerCase()
              .includes(filter.search.toLowerCase()) ||
              visitor.visitor_mo_no
                .toLowerCase()
                .includes(filter.search.toLowerCase()) ||
              visitor.visitor_business_name
                .toLowerCase()
                .includes(filter.search.toLowerCase()) ||
              visitor.visitor_business_category
                .toLowerCase()
                .includes(filter.search.toLowerCase()) ||
              visitor.addedBy
                .toLowerCase()
                .includes(filter.search.toLowerCase()))
          ) {
            return visitor;
          }
        } else {
          return visitor;
        }
      });

    setnblockVisitorList(newData);
  };

  useEffect(() => {
    getAllCityList();
  }, []);
  const getAllCityList = async () => {
    setCityList(await fetchallcitylist());
  };

  const deleteVisitor = async (g_id) => {
    const resp = await deleteVisitorBlockList(g_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      getBlockVisitor();
      setDvisible({ mval: false, id: 0 });
    }
  };

  const [detailsVisitorBlockList, setDetailsVisitorBlockList] = useState([]);

  const expo_detailVisitorTableColumn = [
    { label: "Date", key: "n_m_date" },
    { label: "Invited By", key: "invite_by" },
    { label: "Visitor Mobile Number", key: "n_m_mo_no" },
    { label: "Visitor Name", key: "n_m_person_name" },
    { label: "Business Name", key: "n_m_business_name" },
    { label: "Business Category", key: "n_m_business_category" },
    { label: "Visitor Type", key: "visitor_in_chapter_type" },
    { label: "Invited At", key: "other_chapter_name" },
  ];
  const detailVisitorTableColumn = [
    {
      name: "Date ",
      selector: (row) => row?.n_m_date,
      width: "170px",
    },

    {
      name: "Invited By",
      selector: (row) => row?.invite_by,
      sortable: true,
    },
    {
      name: "Visitor Mobile Number",
      selector: (row) => row?.n_m_mo_no,
      sortable: true,
      width: "170px",
    },
    {
      name: "Visitor Name",
      selector: (row) => row?.n_m_person_name,
      sortable: true,
    },
    {
      name: "Business Name",
      selector: (row) => row?.n_m_business_name,
      sortable: true,
    },
    {
      name: "Business Category",
      selector: (row) => row?.n_m_business_category,
      sortable: true,
    },
    {
      name: "Visitor Type",
      selector: (row) => row?.visitor_in_chapter_type,
      sortable: true,
    },
    {
      name: "Invited At",
      selector: (row) => row?.other_chapter_name,
      sortable: true,
    },
  ];
  const getVisitorListByMobileNumber = async (mobileNumber) => {
    let resp = await getBlockListVisitorListByMobile({
      mobile_no: mobileNumber,
    });

    if (resp.status == 1) {
      setDetailsVisitorBlockList(resp?.data);
    } else {
      setDetailsVisitorBlockList([]);
    }
  };

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deleteVisitor}
      />

      <Page title="Chapter's Member All Report | Local Vocal Business Group">
        <Container>
          {/* Upload CSV or Manually Add Modal */}
          <Modal
            open={openUploadModal}
            onClose={() => {
              setIsValidMobile(true);
              toggleOpenUploadModal();
              setFileUploadData([]);
              setUploadFileDetails("");
              setFileUploadFormData({
                visitor_name: "",
                visitor_contact: "",
                visitor_business_name: "",
                visitor_business_category: "",
              });
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                {step == 0 ? "Add Visitor" : "Upload Visitor File"}
              </Typography>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                {step == 1 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} p={2}>
                      <label
                        className=""
                        htmlFor="CSVfile"
                        style={{
                          display: "flex",
                          margin: "auto",
                          marginTop: "25px",
                          justifyContent: "center",
                          width: "fit-content",
                        }}
                      >
                        <Label
                          variant="filled"
                          color="primary"
                          style={{
                            height: "40px",
                            width: "150px",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "16px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Select File <FileUploadRounded />
                          </div>
                        </Label>
                        {/* </Button> */}
                        <input
                          id="CSVfile"
                          name="file"
                          type="file"
                          hidden
                          accept=".csv, text/csv"
                          className="form-control"
                          onChange={handleUploadedCSVFile}
                        />
                      </label>

                      {/* <h4
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                    }}
                  >
                    Or Add Visitor Manually
                  </h4> */}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item sm={6} xs={6}>
                      <Stack spacing={3}>
                        <TextField
                          //   disabled={fileUploadData.length == 0 ? false : true}
                          label="Visitor Name"
                          name="visitor_name"
                          placeholder="Enter Visitor Name"
                          value={fileUploadFormData?.visitor_name}
                          autoComplete="off"
                          onChange={(e) => {
                            setFileUploadFormData({
                              ...fileUploadFormData,
                              visitor_name: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Stack spacing={3}>
                        <TextField
                          //   disabled={fileUploadData.length == 0 ? false : true}
                          label="Visitor Contact Number"
                          name="visitor_contact"
                          autoComplete="off"
                          placeholder="Enter Contact Number"
                          value={fileUploadFormData?.visitor_contact}
                          onChange={(e) => {
                            checkMobileValid(e.target.value);
                            setFileUploadFormData({
                              ...fileUploadFormData,
                              visitor_contact: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Stack spacing={3}>
                        <TextField
                          //   disabled={fileUploadData.length == 0 ? false : true}
                          label="Visitor Business Name"
                          name="visitor_business_name"
                          autoComplete="off"
                          placeholder="Enter Visitor Business Name"
                          value={fileUploadFormData?.visitor_business_name}
                          onChange={(e) => {
                            setFileUploadFormData({
                              ...fileUploadFormData,
                              visitor_business_name: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Stack spacing={3}>
                        <TextField
                          //   disabled={fileUploadData.length == 0 ? false : true}
                          label="Visitor Business Category"
                          name="visitor_business_category"
                          autoComplete="off"
                          placeholder="Enter Visitor Business Category"
                          value={fileUploadFormData?.visitor_business_category}
                          onChange={(e) => {
                            setFileUploadFormData({
                              ...fileUploadFormData,
                              visitor_business_category: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
                {uploadFileDetails != "" && (
                  <Label
                    variant={"ghost"}
                    color={"info"}
                    style={{
                      margin: "15px auto auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "fit-content",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Selected File : {uploadFileDetails}
                  </Label>
                )}
                {!isValidMobile && (
                  <Label
                    variant={"ghost"}
                    color={"error"}
                    style={{
                      margin: "15px auto auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "fit-content",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {!uploadFileIsCSV
                      ? "Upload File Must Be CSV"
                      : step == 1
                      ? "Please Check Mobile Number on Uploaded"
                      : "Please Enter Valid Mobile Number"}
                  </Label>
                )}
              </Stack>
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsValidMobile(true);
                      toggleOpenUploadModal();
                      setFileUploadData([]);
                      setUploadFileDetails("");
                      setFileUploadFormData({
                        visitor_name: "",
                        visitor_contact: "",
                        visitor_business_name: "",
                        visitor_business_category: "",
                      });
                    }}
                    style={{ marginTop: "15px", marginRight: "15px" }}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={!isValidMobile}
                    variant="contained"
                    style={{ marginTop: "15px" }}
                    onClick={() => handleFormSubmit()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Stack>
            </Box>
          </Modal>
          {/* Upload CSV or Manually Add Modal */}

          <Grid container>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
              >
                <Typography variant="h4" gutterBottom>
                  Visitor Blocked List
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => (
                  setStep(0), setFileUploadData([]), toggleOpenUploadModal()
                )}
                style={{
                  marginBottom: "15px",
                  float: "right",
                }}
                variant="contained"
              >
                Add Visitor
              </Button>
              <Button
                onClick={() => (
                  setStep(1), setFileUploadData([]), toggleOpenUploadModal()
                )}
                style={{
                  marginRight: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
                variant="contained"
              >
                Upload CSV
              </Button>
              <Button
                variant="outlined"
                style={{
                  marginRight: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
                onClick={() => {
                  saveAs(
                    `${Routes.API_HOSTNAME}visitor-block-list/blacklistimportsample.csv`,
                    `sample.csv`
                  );
                }}
              >
                Download Sample CSV
              </Button>
            </Grid>
          </Grid>
          {/* Blocked Visitor Filter List */}
          {/* <Card spacing={3} style={{ padding: "10px", marginTop: "20px" }}> */}

          {/* </Card> */}

          <Card spacing={3} style={{ padding: "10px", marginTop: "20px" }}>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "15px", marginTop: "0" }}
            >
              <Grid item xs={5}>
                <label>Select City</label>
                <Select
                  isDisabled={
                    loginUser?.u_type == 1 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id != 1 &&
                      loginUser?.management_p == 1)
                      ? true
                      : false
                  }
                  className="react-select"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  value={
                    filter.city_id == 0
                      ? { value: 0, label: "All" }
                      : cityList
                          .map((c) => {
                            if (c.city_id == filter.city_id) {
                              return { value: c.city_id, label: c.city_name };
                            }
                          })
                          .filter((n) => n)[0]
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, city_id: e.value });
                  }}
                  options={[
                    {
                      value: 0,
                      label: "All",
                    },
                    ...cityList?.map((c) => {
                      return { value: c.city_id, label: c.city_name };
                    }),
                  ]}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  style={{ marginTop: "0px" }}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Search"
                  name="search_text"
                  value={filter.search}
                  onChange={(e) => {
                    setFilter({ ...filter, search: e.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{ marginRight: "15px" }}
                  // onClick={addanswer}
                  variant="contained"
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    setFilter({
                      city_id:
                        loginUser?.u_type == 1 ||
                        (loginUser?.u_type == 2 &&
                          loginUser?.p_user_id != 1 &&
                          loginUser?.management_p == 1)
                          ? loginUser?.city_id
                          : 0,
                      search: "",
                    });
                  }}
                  variant="outlined"
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
            <Button className="btn btn-export" style={{ padding: "0" }}>
              <CSVLink
                data={nblockVisitorList}
                headers={expo_columns}
                filename={"Block Visitor Data.csv"}
                className="btn btn-primary test"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </Button>
            <DataTable
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={nblockVisitorList}
            />
          </Card>
        </Container>

        <Modal
          open={detailModal}
          onClose={() => toggleDetailModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={VisitorModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: "5px", textAlign: "center" }}
            >
              Visitor Invited List
            </Typography>
            <hr />
            <>
              <Button className="btn btn-export">
                <CSVLink
                  data={detailsVisitorBlockList}
                  headers={expo_detailVisitorTableColumn}
                  filename={`Detailed Visitor BlockList Report.csv`}
                  className="btn btn-primary test"
                  target="_blank"
                >
                  Export CSV
                </CSVLink>
              </Button>
              <DataTable
                // title="Member List"
                filter
                fixedHeaderScrollHeight="450px"
                fixedHeader={true}
                pagination
                highlightOnHover
                columns={detailVisitorTableColumn}
                data={detailsVisitorBlockList}
              />
            </>
            <hr />
            <div
              style={{
                margin: "10px  auto 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={
                  () => toggleDetailModal()
                  //   setExportFileName(""),
                  //   setExportPinList([])
                }
                style={{ marginTop: "0px", marginRight: "0px" }}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
      </Page>
    </>
  );
}
