import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, Box, Icon } from "@mui/material";
import { ReportGmailerrorredOutlined } from "@mui/icons-material";

export default function Deletemodal({ visible, setVisible, deletefunc }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 4
  };
  return (
    <>
      <Modal
        open={visible.mval}
        onClose={() => setVisible({ ...visible, mval: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "100%", textAlign: "center" }}>
              <Icon style={{ height: "128px", width: "128px" }}>
                <ReportGmailerrorredOutlined
                  color="warning"
                  style={{ height: "128px", width: "128px", opacity: "1" }}
                />
              </Icon>
              <br />
            </div>
            <p style={{ textAlign: "center" }}>Are You Sure You Want To Delete ?</p>
            {/* <hr /> */}
            <div style={{ margin: "10px auto", display: "flex", justifyContent: "center" }}>
              <Button
                style={{ margin: "auto 5px" }}
                variant="outlined"
                onClick={() => setVisible({ ...visible, mval: false })}
              >
                NO
              </Button>
              <Button
                style={{ margin: "auto 5px" }}
                variant="contained"
                onClick={() => {
                  deletefunc(visible.id);
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
