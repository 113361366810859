import Request from ".";
import { Routes } from "../constant/api_url";

const UpdateChapter = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATECHAPTER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json"
      }
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const GetChapterById = async (id) => {
  try {
    const result = await Request.get(Routes.GETCHAPTER + "/" + id);
    return result.data;
  } catch (error) {
    throw error;
  }
};
const GetAllChapterApi = async (id) => {
  try {
    const result = await Request.get(Routes.GETALLCHAPTER);
    return result.data;
  } catch (error) {
    throw error;
  }
};
const InsertChapterApi = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTCHAPTER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json"
      }
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export { UpdateChapter, GetChapterById, GetAllChapterApi, InsertChapterApi };
