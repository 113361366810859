import Request from ".";
import { Routes } from "../constant/api_url";

const LoginRequest = async (formdata) => {
  try {
    const res = await Request.post(Routes.LOGIN, formdata, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json"
      }
    }); 
    return res?.data;
  } catch (error) {
    throw error;
  }
};

// const get_api_demo = async (company_id) => {
//   try {
//     const result = await Request.get(Routes.GET_JOB_DESC + "/" + company_id);
//     return result.data;
//   } catch (error) {
//     throw error;
//   }
// };
export { LoginRequest };
