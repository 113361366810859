import { GetAllChapterApi } from "src/api/chapterSetting";
import {
  getChapter,
  getAllChapter,
  insertmember,
  getState,
  getCity,
  GetAllMemberApi,
  getPollSelect,
  getAllGallery,
  getAllPoll,
  getAllEventAnnou,
  getAllMeetingApi
} from "src/api/jilcommonapi";

// export async function fetchStateList() {
//   try {
//     const resp = await getState();
//     return resp;
//   } catch {
//     return null;
//   }
// }

// export async function fetchCityList(cid) {
//   try {
//     const resp = await getCity(cid);
//     return resp;
//   } catch {
//     return null;
//   }
// }
export async function GetAllChapterList(cid) {
  try {
    const resp = await GetAllChapterApi(cid);
    return resp;
  } catch {
    return null;
  }
}

export async function GetAllMember() {
  try {
    const resp = await GetAllMemberApi();
    return resp.data;
  } catch {
    return null;
  }
}

export async function getAllMeeting() {
  try {
    const resp = await getAllMeetingApi();
    return resp.data;
  } catch {
    return null;
  }
}

// export async function fetchGalleryList() {
//   try {
//     const resp = await getAllGallery();
//     if (resp.status == 1) return resp.data;
//     else return null;
//   } catch {
//     return null;
//   }
// }

// export async function fetchPollList() {
//   try {
//     const resp = await getAllPoll();
//     if (resp.status == 1) return resp.data;
//     else return null;
//   } catch {
//     return null;
//   }
// }

// export async function PollSelect(e) {
//   try {
//     const resp = await getPollSelect(e);
//     if (resp.status == 1) return resp.data;
//     else return resp;
//   } catch {
//     return null;
//   }
// }

// export async function fetchEventAnnouList() {
//   try {
//     const resp = await getAllEventAnnou();
//     if (resp.status == 1) return resp.data;
//     else return null;
//   } catch {
//     return null;
//   }
// }

// export async function getChapter() {
//   try {
//     const resp = await getAllChapter();
//     return resp;
//   } catch {
//     return null;
//   }
// }
