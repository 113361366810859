import React, { useState, useEffect } from "react";
import {
  Button,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tabs,
  Tab,
  FormHelperText,
  FormLabel,
  FormControl,
  CircularProgress,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import Logo from "../components/Logo";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import moment from "moment";
import {
  InsertPublicLeadMemberForm,
  insertLead,
  updateLeadMemberForm,
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import { Routes } from "../constant/api_url";
import { getLeadDetailsUsingId } from "src/utils/common";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Label from "src/components/Label";
import { AddPhotoAlternate, Business, Person } from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 720,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "30px 0",
}));

const LoginFormCard = {
  padding: "15px",
  margin: "auto",
  display: "block",
  width: "100%",
};
const Login_body = {
  margin: "15px",
};
const form_title = {
  margin: "15px 0",
  textAlign: "center",
};
// ----------------------------------------------------------------------

export default function LeadForm() {
  let { c_id } = useParams();
  let City_amb_id = 1;
  let p_u_id = 0;
  let date = moment().format("DD-MM-YYYY");
  // let lead_id = Buffer.from(l_id, "base64")?.toString("ascii")?.split("_")?.[1];
  if (c_id != undefined) {
    City_amb_id = Buffer.from(c_id, "base64").toString("ascii");
    p_u_id = 1;
  }

  {
  }
  const [formData, setFormData] = useState({
    // Mail Id
    // lead_id: "",
    l_created_by: City_amb_id,
    l_allocated_to: City_amb_id,
    p_u_id: p_u_id,
    l_type: 0,
    l_phase: 3,
    date: date,
    c_f: true,

    // Step 1
    name: "",
    dob: "",
    l_contact_no: "",
    alternate_contact_number: "",
    insuranceType: [],
    otherInsurance: "",
    aadhaar: "",
    facebook_url: "",
    instagram_url: "",

    // Step 2
    l_business_name: "",
    l_business_category: "",
    l_business_description: "",
    gst_no: "",
    l_email: "",
    other_group_name: "",
    l_address: "", // office address
    l_h_address: "", // home address
    payment_date: "",
    payment_mode: 0,
    l_professional_photo: "",
    l_product_photo: "",
    l_reference_1: "",
    l_reference_2: "",

    //
    updateby_member: 0,
  });
  const handleInsuranceCheckBoxEvent = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      // If checkbox is checked, add the value to the array
      const updatedInsuranceType = [...formData.insuranceType, value];
      setFormData({
        ...formData,
        insuranceType: updatedInsuranceType,
      });
    } else {
      // If checkbox is unchecked, remove the value from the array
      const updatedInsuranceType = formData.insuranceType.filter(
        (item) => item !== value
      );
      setFormData({
        ...formData,
        insuranceType: updatedInsuranceType,
      });
    }
  };
  const [activeTab, setActiveTab] = useState(1);
  const getLeadDetailsByID = async (lead_id) => {
    let res = await getLeadDetailsUsingId(lead_id);
    setFormData({
      ...formData,
      // lead_id: res?.lead_id || "",
      // Step 1
      name: res?.name || "",
      dob: res?.dob || "",
      l_contact_no: res?.l_contact_no || "",
      alternate_contact_number: res?.alternate_contact_number || "",
      insuranceType: res?.insuranceType,
      otherInsurance: res?.otherInsurance || "",
      aadhaar: res?.aadhaar || "",
      facebook_url: res?.facebook_url || "",
      instagram_url: res?.instagram_url || "",

      // Step 2
      l_business_name: res?.l_business_name || "",
      l_business_category: res?.l_business_category || "",
      l_business_description: res?.l_business_description || "",
      gst_no: res?.gst_no || "",
      l_email: res?.l_email || "",
      other_group_name: res?.other_group_name || "",
      l_address: res?.l_address || "",
      l_h_address: res?.l_h_address || "",
      payment_date: res?.payment_date || "",
      payment_mode: res?.payment_mode || 0,
      l_professional_photo: res?.l_professional_photo || "",
      l_product_photo: res?.l_product_photo || "",
      l_reference_1: res?.l_reference_1 || "",
      l_reference_2: res?.l_reference_2 || "",
      updateby_member: res?.updateby_member || 0,
    });
  };

  // useEffect(() => {
  //   getLeadDetailsByID(lead_id);
  // }, []);

  const initialErrorState = {
    // Step 1
    name: "",
    dob: "",
    l_contact_no: "",
    alternate_contact_number: "",
    insuranceType: "",
    aadhaar: "",
    facebook_url: "",
    instagram_url: "",

    // Step 2
    l_business_name: "",
    l_business_category: "",
    l_email: "",
    l_address: "",
    l_h_address: "",
    payment_date: "",
    payment_mode: "",
    l_professional_photo: "",
    l_product_photo: "",
    l_reference_1: "",
    l_reference_2: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const allowedFileTypes = [
    // Images
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",

    // PDFs
    "application/pdf",

    // Microsoft Word Documents
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  ];

  // For Product & Professional Photos
  const allowFileImageTypes = [
    // Images
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
  ];

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const [isLoading, setIsLoading] = useState(false);
  const submitFormData = async () => {
    // if (
    //   formData.name == undefined ||
    //   formData.name == "" ||
    //   formData.l_business_name == undefined ||
    //   formData.l_business_name == "" ||
    //   formData.l_contact_no == undefined ||
    //   formData.l_contact_no == "" ||
    //   formData.l_business_category == undefined ||
    //   formData.l_business_category == "" ||
    //   formData.l_address == undefined ||
    //   formData.l_address == ""
    // ) {
    //   let notify = notification({
    //     type: "error",
    //     message: "Please Fill Required Fields",
    //   });
    //   notify();
    // }
    let firstStepValidationCheck = checkValidation(1);
    let secondStepValidationCheck =
      firstStepValidationCheck && checkValidation(2);

    if (!firstStepValidationCheck || !secondStepValidationCheck) {
      let notify = notification({
        type: "error",
        message: "Please Fill All Required Fields",
      });
      notify();
    } else {
      setIsLoading(true);
      let fndata = new FormData();

      for (const key in formData) {
        // if (key == "dob" || key == "payment_date") {
        //   fndata.append(key, moment(formData[key]).format("DD-MM-YYYY"));
        // } else {
        fndata.append(
          key,
          key == "insuranceType" ? formData[key].join(",") : formData[key]
        );
        // }
      }
      // fndata.append("aadhaar", formData.aadhaar);
      // fndata.append("l_product_photo", formData.l_product_photo);
      // fndata.append("l_professional_photo", formData.l_professional_photo);
      // let resp = await updateLeadMemberForm(fndata);
      let resp = await InsertPublicLeadMemberForm(fndata);

      //   let resp = await insertLead(JSON.stringify(formData));
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setFormData({
          // Mail Id
          // lead_id: "",
          l_created_by: City_amb_id,
          l_allocated_to: City_amb_id,
          p_u_id: p_u_id,
          l_type: 0,
          l_phase: 3,
          date: date,
          c_f: true,

          // Step 1
          name: "",
          dob: "",
          l_contact_no: "",
          alternate_contact_number: "",
          insuranceType: [],
          otherInsurance: "",
          aadhaar: "",
          facebook_url: "",
          instagram_url: "",

          // Step 2
          l_business_name: "",
          l_business_category: "",
          l_business_description: "",
          gst_no: "",
          l_email: "",
          other_group_name: "",
          l_address: "", // office address
          l_h_address: "", // home address
          payment_date: "",
          payment_mode: 0,
          l_professional_photo: "",
          l_product_photo: "",
          l_reference_1: "",
          l_reference_2: "",

          //
          updateby_member: 0,
        });

        // setFormData({
        //   // l_created_by: City_amb_id,
        //   l_type: 0,
        //   // l_allocated_to: City_amb_id,
        //   // p_u_id: p_u_id,
        //   date: date,
        //   l_phase: 0,
        //   name: "",
        //   l_business_name: "",
        //   l_contact_no: "",
        //   l_business_category: "",
        //   l_address: "",
        //   l_email: "",
        //   gst_no: "",
        // });
        // getLeadDetailsByID(lead_id);
      }
      setIsLoading(false);
    }
  };

  const onChangeFunction = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkValidation = (active = undefined) => {
    const newErrors = { ...errors };
    if ((active != undefined ? active : activeTab) == 1) {
      // validation for 1st section
      // Validate Name
      if (
        formData.name == undefined ||
        formData.name == "" ||
        formData.name.trim() === ""
      ) {
        newErrors.name = "Full name is required.";
      } else if (!/^[A-Za-z\s\-_]*$/.test(formData.name)) {
        newErrors.name = "Special character are not allowed.";
      } else {
        newErrors.name = "";
      }
      // Validate DOB

      if (formData.dob == undefined || formData.dob == "") {
        newErrors.dob = "Date of Birth is required.";
      } else {
        newErrors.dob = "";
      }
      // Contact Number validation
      if (
        formData.l_contact_no == undefined ||
        formData.l_contact_no == "" ||
        formData.l_contact_no.trim() === ""
      ) {
        newErrors.l_contact_no = "Contact Number is required.";
      } else if (!/^[0-9]{10}$/.test(formData.l_contact_no)) {
        newErrors.l_contact_no = "Enter a valid Contact Number.";
      } else {
        newErrors.l_contact_no = "";
      }
      // Alternate Contact Validation
      // if (
      //   formData.alternate_contact_number == undefined ||
      //   formData.alternate_contact_number == "" ||
      //   formData.alternate_contact_number.trim() === ""
      // ) {
      //   newErrors.alternate_contact_number =
      //     "Alternate Contact Number is required.";
      // } else if (!/^[0-9]{10}$/.test(formData.alternate_contact_number)) {
      //   newErrors.alternate_contact_number =
      //     "Enter a valid Alternate Contact Number.";
      // } else {
      //   newErrors.alternate_contact_number = "";
      // }
      // insurance Validation
      if (
        formData.insuranceType.length == 0 ||
        (formData.insuranceType.includes("0") &&
          (formData.otherInsurance == undefined ||
            formData.otherInsurance?.trim() === ""))
      ) {
        newErrors.insuranceType =
          (formData.insuranceType.length == 0 ||
            (formData.insuranceType.includes("0") &&
              formData.otherInsurance == undefined) ||
            formData.otherInsurance?.trim()) === ""
            ? "Other Insurance is required."
            : "Insurance is required.";
      } else {
        newErrors.insuranceType = ``;
      }

      if (formData.aadhaar == undefined || formData.aadhaar == "") {
        newErrors.aadhaar = "Aadhaar Image is required.";
      } else {
        newErrors.aadhaar = "";
      }

      // Facebook Validation

      // if (
      //   formData.facebook_url == undefined ||
      //   formData.facebook_url == "" ||
      //   formData.facebook_url.trim() === ""
      // ) {
      //   newErrors.facebook_url = "Facebook  ID is required.";
      // }
      // else if (
      //   formData.facebook_url.trim() &&
      //   !/^https:\/\/www\.facebook\.com\/[^/]+\/?$/.test(formData.facebook_url)
      // ) {
      //   newErrors.facebook_url = "Enter a valid Facebook URL.";
      // }

      // Instagram Validation
      // if (
      //   formData.instagram_url == undefined ||
      //   formData.instagram_url == "" ||
      //   formData.instagram_url.trim() === ""
      // ) {
      //   newErrors.instagram_url = "Instagram  ID is required.";
      // }
      // else if (
      //   formData.instagram_url.trim() &&
      //   !/^https:\/\/www\.instagram\.com\/[^/]+\/?$/.test(
      //     formData.instagram_url
      //   )
      // ) {
      //   newErrors.instagram_url = "Enter a valid Instagram URL.";
      // }

      setErrors(newErrors);

      let x = Object.values(newErrors).every((error) => error === "");

      Object.values(newErrors).every((error) => error === "")
        ? setActiveTab(2)
        : setActiveTab(active != undefined ? active : activeTab);

      return Object.values(newErrors).every((error) => error === "");
    } else {
      //   // validation for 2nd section

      // Business Name
      if (
        formData.l_business_name == undefined ||
        formData.l_business_name.trim() === ""
      ) {
        newErrors.l_business_name = "Business Name is required.";
      } else {
        newErrors.l_business_name = "";
      }
      // Business Category
      if (
        formData.l_business_category == undefined ||
        formData.l_business_category.trim() === ""
      ) {
        newErrors.l_business_category = "Business Category is required.";
      } else {
        newErrors.l_business_category = "";
      }

      // Email validations

      if (formData.l_email == undefined || formData.l_email.trim() === "") {
        newErrors.l_email = "Email is required.";
      } else {
        if (!/^\S+@\S+\.\S+$/.test(formData.l_email)) {
          newErrors.l_email = "Please enter Valid Mail.";
        } else {
          newErrors.l_email = "";
        }
      }

      // Office Address
      if (formData.l_address == undefined || formData.l_address.trim() === "") {
        newErrors.l_address = "Office Address is required.";
      } else {
        newErrors.l_address = "";
      }

      // Home Address
      if (
        formData.l_h_address == undefined ||
        formData.l_h_address.trim() === ""
      ) {
        newErrors.l_h_address = "Home Address is required.";
      } else {
        newErrors.l_h_address = "";
      }

      // Payment Date

      if (formData.payment_date == undefined || formData.payment_date == "") {
        newErrors.payment_date = "Payment Date is required.";
      } else {
        newErrors.payment_date = "";
      }

      // Professional Photo
      if (
        formData.l_professional_photo == undefined ||
        formData.l_professional_photo == ""
      ) {
        newErrors.l_professional_photo = "Professional Image is required.";
      } else {
        newErrors.l_professional_photo = "";
      }
      // Product Photo
      if (
        formData.l_product_photo == undefined ||
        formData.l_product_photo == ""
      ) {
        newErrors.l_product_photo = "Product Image is required.";
      } else {
        newErrors.l_product_photo = "";
      }

      // Reference 1
      if (
        formData.l_reference_1 == undefined ||
        formData.l_reference_1.trim() === ""
      ) {
        newErrors.l_reference_1 = "Reference is required.";
      } else {
        newErrors.l_reference_1 = "";
      }
      // Reference 2
      if (
        formData.l_reference_2 == undefined ||
        formData.l_reference_2.trim() === ""
      ) {
        newErrors.l_reference_2 = "Reference is required.";
      } else {
        newErrors.l_reference_2 = "";
      }

      setErrors(newErrors);

      Object.values(newErrors).every((error) => error === "")
        ? setActiveTab(2)
        : setActiveTab(active != undefined ? active : activeTab);

      return Object.values(newErrors).every((error) => error === "");
    }
  };

  const handleFileChange = (event, inputName) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size <= 11 * 1024 * 1024) {
        // 2MB in bytes
        if (
          (inputName == "aadhaar"
            ? allowedFileTypes
            : allowFileImageTypes
          ).includes(file.type)
        ) {
          // setSelectedFile(file);
          setFormData({ ...formData, [inputName]: file });
          setErrors({ ...errors, [inputName]: "" });
          // setErrorMessage('');
        } else {
          setErrors({
            ...errors,
            [inputName]: `${
              inputName == "aadhaar"
                ? "Upload File Must Be PDF, Image or Doc"
                : "Upload File Must be an Image"
            }  `,
          });
          // setSelectedFile(null);
          // setErrorMessage('Invalid file type. Please upload an image, PDF, or DOC file.');
        }
      } else {
        setErrors({
          ...errors,
          [inputName]: "File Sizes Greater Than 10 MB Are Not Supported",
        });
        // setSelectedFile(null);
        // setErrorMessage('File size exceeds 2MB limit.');
      }
    }
  };

  const ErrorDisplay = ({ msg }) => {
    return (
      msg && (
        <span
          className="error"
          style={{
            margin: "10px 0",
            display: "flex",
            marginTop: "5px",
            width: "fit-content",
            paddingBottom: "5px",
            paddingTop: "5px",
            fontSize: "14px",
            cursor: "pointer",
            padding: "0px 8px",
            color: "#FF4842",
            fontSize: "0.75rem",
            backgroundColor: "transparent",
            fontWeight: "700",
            borderRadius: "8px",
            border: "1px solid #FF4842",
          }}
        >
          {msg}
        </span>
      )
    );

    // return errors[key] && <span className="error">{errors[key]?.name}</span>;
  };
  return (
    <Page title="Inquiry Form | Local Vocal Business Group">
      <Container>
        <ContentStyle>
          <Card className="LoginFormCard" style={LoginFormCard}>
            <Box
              component="img"
              // src="/static/logolvb.png"
              src={Routes.API_HOSTNAME + "logo/LVB-Logo.png"}
              sx={{ display: "block", margin: "auto", height: "100px" }}
            />
            <h4 className="form_title" style={form_title}>
              Fill Your Details
            </h4>

            <div className="Login_body" style={Login_body}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  variant="fullWidth"
                  allowScrollButtonsMobile
                  onChange={
                    (e, value) => {
                      // checkValidation(value);
                      setActiveTab(value);
                    }
                    // setMemberDetailData([]),
                    // setActiveTab(value),
                    // getMembersDetailReport(value, activeRow.m_id)
                  }
                >
                  <Tab
                    style={{ marginBottom: "0", paddingBottom: "0" }}
                    disableRipple
                    key={1}
                    value={1}
                    label="Personal Details"
                    icon={<Person />}
                    iconPosition="start"
                  />
                  <Tab
                    style={{ marginBottom: "0", paddingBottom: "0" }}
                    disableRipple
                    key={2}
                    value={2}
                    label="Business Details"
                    icon={<Business />}
                    iconPosition="start"
                  />
                </Tabs>
              </Box>
              <Grid container spacing={1.5} style={{ marginTop: "15px" }}>
                {activeTab == 1 ? (
                  <>
                    {/*  */}
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          required
                          disabled={formData?.updateby_member == 1 && true}
                          autoComplete="off"
                          type="text"
                          label="Full Name"
                          name="name"
                          value={formData?.name}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.name}
                          error={errors.name != "" ? true : false}
                          onBlur={(e) => errors.name != "" && checkValidation()}
                        />
                        {/* <ErrorDisplay msg={errors.name} /> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px", background: "yellow" }}
                        >
                          <DatePicker
                            disabled={formData?.updateby_member == 1 && true}
                            type="date"
                            name="dob"
                            label="Date of Birth"
                            // value={
                            //   formData?.dob != undefined
                            //     ? moment(formData?.dob, "MM-DD-YYYY").format(
                            //         "DD-MM-YYYY"
                            //       )
                            //     : null
                            // }
                            value={
                              formData?.dob != undefined
                                ? moment(formData.dob, "DD-MM-YYYY")
                                : null
                            }
                            disableFuture
                            inputFormat="dd/MM/yyyy"
                            onChange={(newDate) => {
                              setFormData({
                                ...formData,
                                dob: moment(newDate, "DD-MM-YYYY").format(
                                  "DD-MM-YYYY"
                                ),
                              });
                            }}
                            onBlur={() => errors.dob != "" && checkValidation()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                helperText={errors.dob}
                                onBlur={() =>
                                  errors.dob != "" && checkValidation()
                                }
                                error={errors.dob != "" ? true : false}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <ErrorDisplay msg={errors.dob} /> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          required
                          autoComplete="off"
                          type="text"
                          label="Contact Number"
                          name="l_contact_no"
                          value={formData?.l_contact_no}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_contact_no}
                          error={errors.l_contact_no != "" ? true : false}
                          onBlur={() =>
                            errors.l_contact_no != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                        {/* <ErrorDisplay msg={errors.l_contact_no} /> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          // required
                          autoComplete="off"
                          type="text"
                          label="Alternate Contact Number"
                          name="alternate_contact_number"
                          value={formData?.alternate_contact_number}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          onBlur={() =>
                            errors.alternate_contact_number != "" &&
                            checkValidation()
                          }
                          id="standard-error-helper-text"
                          helperText={errors.alternate_contact_number}
                          error={
                            errors.alternate_contact_number != "" ? true : false
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                        {/* <ErrorDisplay msg={errors.alternate_contact_number} /> */}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <FormControl
                          error={errors.insuranceType != "" ? true : false}
                        >
                          <FormLabel
                            id="demo-error-radios"
                            style={{ fontWeight: "bold" }}
                          >
                            Please Select Insurance you have{" "}
                            <span
                              aria-hidden="true"
                              className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                            >
                              *
                            </span>
                          </FormLabel>
                          <FormGroup
                            name="insuranceType"
                            value={formData?.insuranceType}
                            // onChange={handleOptionChange}
                            onBlur={() =>
                              errors.insuranceType != "" && checkValidation()
                            }
                            // onChange={(e) => {
                            //   console.log("e", e);
                            //   let checkedArr = [];
                            //   // setFormData({
                            //   //   ...formData,
                            //   //   insuranceType: e.target.value,
                            //   //   otherInsurance: "",
                            //   // });
                            // }}
                          >
                            <FormControlLabel
                              onChange={(e) => handleInsuranceCheckBoxEvent(e)}
                              value={1}
                              disabled={formData?.updateby_member == 1 && true}
                              control={
                                <Checkbox
                                  checked={formData.insuranceType.includes("1")}
                                  name="Health_Insurance"
                                />
                              }
                              label="Health Insurance"
                            />
                            <FormControlLabel
                              onChange={(e) => handleInsuranceCheckBoxEvent(e)}
                              value={2}
                              disabled={formData?.updateby_member == 1 && true}
                              control={
                                <Checkbox
                                  name="Life_Insurance"
                                  checked={formData.insuranceType.includes("2")}
                                />
                              }
                              label="Life Insurance"
                            />
                            <FormControlLabel
                              onChange={(e) => handleInsuranceCheckBoxEvent(e)}
                              value={3}
                              disabled={formData?.updateby_member == 1 && true}
                              control={
                                <Checkbox
                                  name="Term_Insurance"
                                  checked={formData.insuranceType.includes("3")}
                                />
                              }
                              label="Term Insurance"
                            />
                            <FormControlLabel
                              onChange={(e) => handleInsuranceCheckBoxEvent(e)}
                              value={4}
                              disabled={formData?.updateby_member == 1 && true}
                              control={
                                <Checkbox
                                  name="None"
                                  checked={formData.insuranceType.includes("4")}
                                />
                              }
                              label="None"
                            />
                            <FormControlLabel
                              value={0}
                              onChange={(e) => handleInsuranceCheckBoxEvent(e)}
                              disabled={formData?.updateby_member == 1 && true}
                              control={
                                <Checkbox
                                  name="Other"
                                  checked={formData.insuranceType.includes("0")}
                                />
                              }
                              label="Other"
                            />

                            {formData.insuranceType.includes("0") && (
                              <TextField
                                // label="Other Option"
                                variant="standard"
                                fullWidth
                                autoComplete="off"
                                autoCorrect="off"
                                name="otherInsurance"
                                placeholder="Other Insurance"
                                value={formData?.otherInsurance}
                                onChange={onChangeFunction}

                                // You can add additional logic here to handle the text field input
                              />
                            )}
                          </FormGroup>
                          <FormHelperText>
                            {errors.insuranceType}
                          </FormHelperText>
                          {/* <ErrorDisplay msg={errors.insuranceType} /> */}
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        {/* <label style={{ fontWeight: "bold" }}>
                          Select Aadhaar Card Front & Back Photo
                        </label> */}
                        <FormControl
                          error={errors.aadhaar != "" ? true : false}
                          variant="standard"
                        >
                          <FormLabel
                            id="demo-error-radios"
                            style={{ fontWeight: "bold" }}
                          >
                            Select Aadhaar Card Front & Back Photo{" "}
                            <span
                              aria-hidden="true"
                              className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                            >
                              *
                            </span>
                          </FormLabel>
                          {/* <TextField type="file" /> */}
                          <div>
                            <label
                              className=""
                              htmlFor="file"
                              style={{
                                margin: "5px auto",
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Label
                                disabled={
                                  formData?.updateby_member == 1 && true
                                }
                                variant="filled"
                                color={
                                  formData?.updateby_member == 1
                                    ? undefined
                                    : "primary"
                                }
                                style={{
                                  height: "40px",
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Select File
                                  <AddPhotoAlternate
                                    style={{
                                      marginLeft: "7px",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                </div>
                              </Label>
                              <input
                                id="file"
                                type="file"
                                hidden
                                name="aadhaar"
                                accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif,.bmp"
                                onChange={(e) => {
                                  handleFileChange(e, "aadhaar");
                                }}
                                disabled={
                                  formData?.updateby_member == 1 && true
                                }
                                onBlur={() =>
                                  errors.aadhaar != "" && checkValidation()
                                }
                                // onBlur={checkValidation}
                              />
                            </label>
                            <div>
                              <>
                                {formData?.aadhaar && (
                                  <div
                                    variant={"ghost"}
                                    color="success"
                                    style={{
                                      margin: "10px auto",
                                      display: "flex",
                                      marginTop: "20px",

                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "fit-content",
                                      paddingBottom: "5px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                      cursor: "default",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0px 8px",
                                      color: "#229A16",
                                      fontSize: "0.75rem",
                                      backgroundColor:
                                        "rgba(84, 214, 44, 0.16)",
                                      fontWeight: "700",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    {formData?.aadhaar?.name}
                                  </div>
                                )}
                                {/* <div>
                                  {allowedFileTypes.includes(
                                    formData?.aadhaar?.type
                                  ) && (
                                    <div>
                                      {formData?.aadhaar?.type.startsWith(
                                        "image/"
                                      ) && (
                                        <img
                                          src={URL.createObjectURL(
                                            formData?.aadhaar
                                          )}
                                          alt="Preview"
                                          style={{
                                            maxWidth: "100%",
                                            margin: "auto",
                                            maxHeight: "130px",
                                          }}
                                        />
                                      )}
                                      {formData?.aadhaar?.type ===
                                        "application/pdf" && (
                                        <embed
                                          src={URL.createObjectURL(
                                            formData?.aadhaar
                                          )}
                                          width="100%"
                                          height="400px"
                                          type="application/pdf"
                                        />
                                      )}

                                      {(formData?.aadhaar?.type ===
                                        "application/msword" ||
                                        formData?.aadhaar?.type ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                                        <iframe
                                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                            URL.createObjectURL(
                                              formData?.aadhaar
                                            )
                                          )}&embedded=true`}
                                          title="Preview"
                                          width="100%"
                                          height="400px"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div> */}
                                <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormHelperText>
                                    {errors.aadhaar}
                                  </FormHelperText>
                                  {/* <ErrorDisplay msg={errors.aadhaar} /> */}
                                </div>

                                {/* Input Helper */}
                                <Label
                                  variant={"ghost"}
                                  color="error"
                                  style={{
                                    margin: "10px 0 10px 0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "fit-content",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  More Then 10MB of File Are Not Supported
                                </Label>
                                <Label
                                  variant={"ghost"}
                                  color="error"
                                  style={{
                                    margin: "0",
                                    display: "flex",
                                    marginTop: "0px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "fit-content",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Note: PDF, Doc & Images are Only Supported
                                </Label>
                              </>
                            </div>
                          </div>
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          type="text"
                          label="Enter Facebook ID"
                          name="facebook_url"
                          value={formData?.facebook_url}
                          onChange={onChangeFunction}
                          // onBlur={checkValidation}
                          disabled={formData?.updateby_member == 1 && true}
                          autoComplete="off"
                          autoCorrect="off"
                        />
                        <ErrorDisplay msg={errors.facebook_url} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          type="text"
                          label="Enter Instagram ID"
                          name="instagram_url"
                          value={formData?.instagram_url}
                          onChange={onChangeFunction}
                          // onBlur={checkValidation}
                          autoComplete="off"
                          autoCorrect="off"
                          disabled={formData?.updateby_member == 1 && true}
                        />
                        <ErrorDisplay msg={errors.instagram_url} />
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          required
                          label="Company Name"
                          name="l_business_name"
                          value={formData?.l_business_name}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_business_name}
                          error={errors.l_business_name != "" ? true : false}
                          onBlur={(e) =>
                            errors.l_business_name != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          required
                          autoComplete="off"
                          type="text"
                          label="Business Category"
                          name="l_business_category"
                          value={formData?.l_business_category}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_business_category}
                          error={
                            errors.l_business_category != "" ? true : false
                          }
                          onBlur={(e) =>
                            errors.l_business_category != "" &&
                            checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          autoCorrect="off"
                          type="text"
                          label="Business Description"
                          name="l_business_description"
                          value={formData?.l_business_description}
                          onChange={onChangeFunction}
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="GST No"
                          name="gst_no"
                          value={formData?.gst_no}
                          onChange={onChangeFunction}
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          required
                          label="Email ID"
                          name="l_email"
                          value={formData?.l_email}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_email}
                          error={errors.l_email != "" ? true : false}
                          onBlur={(e) =>
                            errors.l_email != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Other Networking Platform"
                          name="other_group_name"
                          placeholder="Have you join any Other Networking Platform"
                          value={formData?.other_group_name}
                          onChange={onChangeFunction}
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          required
                          label="Office Address"
                          name="l_address"
                          value={formData?.l_address}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_address}
                          error={errors.l_address != "" ? true : false}
                          onBlur={(e) =>
                            errors.l_address != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          required
                          label="Home Address"
                          name="l_h_address"
                          value={formData?.l_h_address}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_h_address}
                          error={errors.l_h_address != "" ? true : false}
                          onBlur={(e) =>
                            errors.l_h_address != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disabled={formData?.updateby_member == 1 && true}
                            type="date"
                            name="payment_date"
                            label="Payment Date"
                            // value={
                            //   formData?.payment_date != ""
                            //     ? moment(
                            //         formData?.payment_date,
                            //         "MM-DD-YYYY"
                            //       ).format("DD-MM-YYYY")
                            //     : null
                            // }
                            value={
                              formData?.payment_date != undefined
                                ? moment(formData.payment_date, "DD-MM-YYYY")
                                : null
                            }
                            inputFormat="dd/MM/yyyy"
                            onChange={(newDate) => {
                              setFormData({
                                ...formData,
                                payment_date: moment(
                                  newDate,
                                  "DD-MM-YYYY"
                                ).format("DD-MM-YYYY"),
                              });
                              errors.payment_date != "" && checkValidation();
                            }}
                            renderInput={(params) => (
                              <TextField
                                // disabled
                                {...params}
                                required
                                helperText={errors.payment_date}
                                onBlur={() =>
                                  errors.payment_date != "" && checkValidation()
                                }
                                error={errors.payment_date != "" ? true : false}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <ErrorDisplay msg={errors?.payment_date} /> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <FormControl
                          // disabled={formData?.updateby_member == 1 && true}
                          error={errors.payment_mode != "" ? true : false}
                        >
                          <FormLabel
                            id="demo-error-radios"
                            style={{ fontWeight: "bold" }}
                            disabled={formData?.updateby_member == 1 && true}
                          >
                            Please Select Payment Method{" "}
                            <span
                              aria-hidden="true"
                              className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                            >
                              *
                            </span>
                          </FormLabel>

                          <RadioGroup
                            row // display as Row
                            name="payment_mode"
                            value={formData?.payment_mode}
                            required
                            // onChange={handleOptionChange}
                            // onBlur={checkValidation}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                payment_mode: e.target.value,
                              });
                            }}
                          >
                            <FormControlLabel
                              value={0}
                              disabled={formData?.updateby_member == 1 && true}
                              control={<Radio />}
                              label="Cash"
                            />
                            <FormControlLabel
                              value={1}
                              disabled={formData?.updateby_member == 1 && true}
                              control={<Radio />}
                              label="Transfer"
                            />
                            <FormControlLabel
                              value={2}
                              disabled={formData?.updateby_member == 1 && true}
                              control={<Radio />}
                              label="Cheque"
                            />
                          </RadioGroup>
                          <FormHelperText>{errors.payment_mode}</FormHelperText>
                        </FormControl>

                        {/* <ErrorDisplay msg={errors?.payment_mode} /> */}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <FormControl
                          error={
                            errors.l_professional_photo != "" ? true : false
                          }
                          variant="standard"
                        >
                          <FormLabel
                            id="demo-error-radios"
                            style={{ fontWeight: "bold" }}
                            disabled={formData?.updateby_member == 1 && true}
                          >
                            Upload Professional Photo{" "}
                            <span
                              aria-hidden="true"
                              class="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                            >
                              *
                            </span>
                          </FormLabel>
                          <div>
                            <label
                              className=""
                              htmlFor="l_professional_photo"
                              style={{
                                margin: "5px auto",
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Label
                                disabled={
                                  formData?.updateby_member == 1 && true
                                }
                                variant="filled"
                                color={
                                  formData?.updateby_member == 1
                                    ? undefined
                                    : "primary"
                                }
                                style={{
                                  height: "40px",
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Select File
                                  <AddPhotoAlternate
                                    style={{
                                      marginLeft: "7px",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                </div>
                              </Label>
                              <input
                                id="l_professional_photo"
                                type="file"
                                hidden
                                name="l_professional_photo"
                                accept=".jpeg,.jpg,.png,.gif"
                                onChange={(e) =>
                                  handleFileChange(e, "l_professional_photo")
                                }
                                disabled={
                                  formData?.updateby_member == 1 && true
                                }
                                // onBlur={checkValidation}
                              />
                            </label>
                            <div>
                              <>
                                {formData?.l_professional_photo && (
                                  <div
                                    variant={"ghost"}
                                    color="success"
                                    style={{
                                      margin: "10px auto",
                                      display: "flex",
                                      marginTop: "20px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "fit-content",
                                      paddingBottom: "5px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                      cursor: "default",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0px 8px",
                                      color: "#229A16",
                                      fontSize: "0.75rem",
                                      backgroundColor:
                                        "rgba(84, 214, 44, 0.16)",
                                      fontWeight: "700",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    {formData?.l_professional_photo?.name}
                                  </div>
                                )}
                                {/* <div>
                                  {allowedFileTypes.includes(
                                    formData?.aadhaar?.type
                                  ) && (
                                    <div>
                                      {formData?.aadhaar?.type.startsWith(
                                        "image/"
                                      ) && (
                                        <img
                                          src={URL.createObjectURL(
                                            formData?.aadhaar
                                          )}
                                          alt="Preview"
                                          style={{
                                            maxWidth: "100%",
                                            margin: "auto",
                                            maxHeight: "130px",
                                          }}
                                        />
                                      )}
                                      {formData?.aadhaar?.type ===
                                        "application/pdf" && (
                                        <embed
                                          src={URL.createObjectURL(
                                            formData?.aadhaar
                                          )}
                                          width="100%"
                                          height="400px"
                                          type="application/pdf"
                                        />
                                      )}

                                      {(formData?.aadhaar?.type ===
                                        "application/msword" ||
                                        formData?.aadhaar?.type ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                                        <iframe
                                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                            URL.createObjectURL(
                                              formData?.aadhaar
                                            )
                                          )}&embedded=true`}
                                          title="Preview"
                                          width="100%"
                                          height="400px"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div> */}
                                <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormHelperText>
                                    {errors.l_professional_photo}
                                  </FormHelperText>
                                </div>

                                <Label
                                  variant={"ghost"}
                                  color="error"
                                  style={{
                                    margin: "10px 0 10px 0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "fit-content",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  More Then 10MB of File Are Not Supported
                                </Label>
                                <Label
                                  variant={"ghost"}
                                  color="error"
                                  style={{
                                    margin: "0",
                                    display: "flex",
                                    marginTop: "0px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "fit-content",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Note: Only Image Supported
                                </Label>
                              </>
                            </div>
                          </div>
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <FormControl
                          error={errors.l_product_photo != "" ? true : false}
                          variant="standard"
                        >
                          <FormLabel
                            id="demo-error-radios"
                            style={{ fontWeight: "bold" }}
                            disabled={formData?.updateby_member == 1 && true}
                          >
                            Upload Your Product Photo{" "}
                            <span
                              aria-hidden="true"
                              class="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                            >
                              *
                            </span>
                          </FormLabel>
                          <div>
                            <label
                              className=""
                              htmlFor="product_image"
                              style={{
                                margin: "5px auto",
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Label
                                disabled={
                                  formData?.updateby_member == 1 && true
                                }
                                variant="filled"
                                color={
                                  formData?.updateby_member == 1
                                    ? undefined
                                    : "primary"
                                }
                                style={{
                                  height: "40px",
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Select File Product
                                  <AddPhotoAlternate
                                    style={{
                                      marginLeft: "7px",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                </div>
                              </Label>
                              <input
                                id="product_image"
                                type="file"
                                hidden
                                name="l_product_photo"
                                accept=".jpeg,.jpg,.png,.gif"
                                onChange={(e) => {
                                  handleFileChange(e, "l_product_photo");
                                }}
                                disabled={
                                  formData?.updateby_member == 1 && true
                                }
                                // onBlur={checkValidation}
                              />
                            </label>
                            <div>
                              <>
                                {formData?.l_product_photo && (
                                  <div
                                    variant={"ghost"}
                                    color="success"
                                    style={{
                                      margin: "10px auto",
                                      display: "flex",
                                      marginTop: "20px",

                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "fit-content",
                                      paddingBottom: "5px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                      cursor: "default",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0px 8px",
                                      color: "#229A16",
                                      fontSize: "0.75rem",
                                      backgroundColor:
                                        "rgba(84, 214, 44, 0.16)",
                                      fontWeight: "700",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    {formData?.l_product_photo?.name}
                                  </div>
                                )}
                                {/* <div>
                                  {allowedFileTypes.includes(
                                    formData?.aadhaar?.type
                                  ) && (
                                    <div>
                                      {formData?.aadhaar?.type.startsWith(
                                        "image/"
                                      ) && (
                                        <img
                                          src={URL.createObjectURL(
                                            formData?.aadhaar
                                          )}
                                          alt="Preview"
                                          style={{
                                            maxWidth: "100%",
                                            margin: "auto",
                                            maxHeight: "130px",
                                          }}
                                        />
                                      )}
                                      {formData?.aadhaar?.type ===
                                        "application/pdf" && (
                                        <embed
                                          src={URL.createObjectURL(
                                            formData?.aadhaar
                                          )}
                                          width="100%"
                                          height="400px"
                                          type="application/pdf"
                                        />
                                      )}

                                      {(formData?.aadhaar?.type ===
                                        "application/msword" ||
                                        formData?.aadhaar?.type ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                                        <iframe
                                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                            URL.createObjectURL(
                                              formData?.aadhaar
                                            )
                                          )}&embedded=true`}
                                          title="Preview"
                                          width="100%"
                                          height="400px"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div> */}
                                <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormHelperText>
                                    {errors.l_product_photo}
                                  </FormHelperText>
                                </div>

                                <Label
                                  variant={"ghost"}
                                  color="error"
                                  style={{
                                    margin: "10px 0 10px 0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "fit-content",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  More Then 10MB of File Are Not Supported
                                </Label>
                                <Label
                                  variant={"ghost"}
                                  color="error"
                                  style={{
                                    margin: "0",
                                    display: "flex",
                                    marginTop: "0px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "fit-content",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Note: Only Image Supported
                                </Label>
                              </>
                            </div>
                          </div>
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          required
                          autoComplete="off"
                          type="text"
                          label="Reference By (1)"
                          name="l_reference_1"
                          value={formData?.l_reference_1}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_reference_1}
                          error={errors.l_reference_1 != "" ? true : false}
                          onBlur={(e) =>
                            errors.l_reference_1 != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          required
                          autoComplete="off"
                          type="text"
                          label="Reference By (2)"
                          name="l_reference_2"
                          value={formData?.l_reference_2}
                          onChange={onChangeFunction}
                          autoCorrect="off"
                          helperText={errors.l_reference_2}
                          error={errors.l_reference_2 != "" ? true : false}
                          onBlur={(e) =>
                            errors.l_reference_2 != "" && checkValidation()
                          }
                          disabled={formData?.updateby_member == 1 && true}
                        />
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {activeTab == 1 && (
                <Button
                  variant="contained"
                  style={{ margin: "auto 5px" }}
                  onClick={async (e) => {
                    checkValidation();
                  }}
                >
                  Next
                </Button>
              )}

              {activeTab == 2 && (
                <>
                  <Button
                    variant="outlined"
                    style={{ margin: "auto 5px" }}
                    onClick={() => setActiveTab(1)}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "auto 5px" }}
                    // onClick={async () => {
                    //   checkValidation(1);
                    //   checkValidation(2);
                    // }}

                    disabled={isLoading}
                    onClick={submitFormData}
                  >
                    Submit{" "}
                    {isLoading && (
                      <CircularProgress
                        size={14}
                        color="inherit"
                        sx={{ marginLeft: "10px" }}
                      />
                    )}
                  </Button>
                </>
              )}
            </div>

            {formData?.updateby_member == 1 && (
              <div
                style={{
                  display: "block",
                  margin: "auto",
                  width: "fit-content",
                }}
              >
                <Label
                  variant={"ghost"}
                  color="success"
                  style={{
                    margin: "10px 0 10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    fontSize: "14px",
                  }}
                >
                  You Have Submitted The Form , In Order To Update Contact to
                  LocalVocal Business Group.
                </Label>
              </div>
            )}
          </Card>
        </ContentStyle>
      </Container>
    </Page>
  );
}
