import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography, Box } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import { Routes } from "src/constant/api_url";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Local Vocal Business Group">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Box
            component="img"
            // src="/static/logolvb.png"
            src={Routes.API_HOSTNAME + "logo/LVB-Logo.png"}
            sx={{ display: "block", margin: "20px auto", height: "100px" }}
          />
          <div
            style={{
              borderRadius: "20px",
              boxShadow:
                "rgb(0 0 0 / 17%) 0px 0px 2px 0px, rgb(145 158 171 / 55%) 0px 16px 32px -4px",
              padding: "15px",
            }}
          >
            <Stack sx={{ mb: 3 }}>
              <Typography
                variant="h3"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                Sign in
              </Typography>
            </Stack>
            <LoginForm />
          </div>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
