import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Switch,
  FormControl,
  FormLabel,
} from "@mui/material";
import Label from "../components/Label";
import {
  DeleteSharp,
  CalendarMonth,
  EditSharp,
  LogoutSharp,
  CurrencyRupeeSharp,
  RemoveRedEye,
  EmojiEvents,
  VerifiedUser,
} from "@mui/icons-material";
// components
import Page from "../components/Page";
import { MemberForm } from "../components/Form/Member";
import {
  GetAllMember,
  fetchChaptList,
  fetchPinList,
  fetchallcitylist,
  fetchchapterbyuser,
  fetchmemberfeehistory,
  getPinBadgeByMemberId,
} from "src/utils/common";
import {
  GetMemberById,
  UpdateMemberStatus,
  assginPinFunction,
  getPinBadge,
  updateMemberVerificationStatus,
  userLogout,
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import { display, height } from "@mui/system";
import Select from "react-select";
import Modal from "@mui/material/Modal";
import { CSVLink } from "react-csv";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { fa, tr } from "faker/lib/locales";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function Member() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [step, setStep] = useState(1);
  const [citylist, setcitylist] = useState([]);
  const [memberlist, setmemberlist] = useState([]);
  const [editMember, setEditMemberData] = useState(null);
  const [statusresp, setstatusresp] = useState(0);
  const [chaptlist, setchaplist] = useState([]);
  const [newMemeberList, setNewMemberList] = useState([]);
  const [activeMembercnt, setactiveMembercnt] = useState(0);
  const [deactiveMembercnt, setdeactiveMembercnt] = useState(0);
  const [directorcnt, setdirectorcnt] = useState(0);
  const [memberfeeModal, setmemberfeeModal] = useState(false);
  const [memberDeActiveModal, setmemberDeActiveModal] = useState(false);
  const [memberfeedata, setmemberfeedata] = useState([]);
  const [filterOption, setFilterOption] = useState([
    { value: 0, label: "All Chapters" },
  ]);
  const [memberdeactiveReasons, setMemberdeactiveReasons] = useState({
    m_id: "",
    status: "",
    reasons: "",
    deactivation_date: null,
  });

  const [filter, setFilter] = useState({
    defaulselect: { value: 0, label: "All Chapters" },
    chpt_id: 0,
  });
  const [seeMemberDeActiveModal, setSeeMemberDeActiveModal] = useState(false);

  const [deActivateReasonData, setDeActivateReasonData] = useState({
    memberName: "",
    deActivationDate: "",
    deActivationReason: "",
  });

  const [filtereddata, setFilteredData] = useState({
    filterchap: 0,
    filtermember: "",
    startDate: null,
    endDate: null,
    search_date_type: 0,
    activeMemberSearchType: 0,
    activePositionType: 0,
    filterCity:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
  });
  const [showfilterdata, setShowFilterdata] = useState(false);
  const [achievementModal, setAchievementModal] = useState(false);
  const [memberVerificationModal, setMemberVerificationModal] = useState(false); //
  const toggleAchievementModal = () => setAchievementModal(!achievementModal);
  const filterData = (returnFilterData = false) => {
    let selectedCityChapter =
      chaptlist &&
      chaptlist
        ?.map((chpt) => {
          if (filtereddata?.filterCity != 0) {
            if (chpt?.city_id == filtereddata?.filterCity) {
              return chpt.chpt_id;
            }
          } else {
            return chpt.chpt_id;
          }
        })
        .filter((f) => f != undefined);

    let nmember = [];
    nmember =
      memberlist &&
      memberlist.filter((member) => {
        if (
          (member.chpt_id == filtereddata?.filterchap ||
            filtereddata?.filterchap == 0 ||
            (member.launch_director != null &&
              member.launch_director_at == filtereddata?.filterchap) ||
            (member.support_director != null &&
              member.support_director_at == filtereddata?.filterchap)) &&
          selectedCityChapter.length != 0 &&
          selectedCityChapter.includes(member.chpt_id) &&
          (filtereddata?.activeMemberSearchType == 0
            ? member.status == 0 || member.status == 1
            : filtereddata?.activeMemberSearchType == 1
            ? member.status == 1
            : member.status == 0) &&
          (filtereddata?.activePositionType == 0
            ? member.type == -1 ||
              member.type == 0 ||
              member.type == 1 ||
              member.type == 2 ||
              member.type == 3 ||
              member.type == 4 ||
              member.type == 5
            : filtereddata.activePositionType == 1
            ? member.type == 1 ||
              member.type == 2 ||
              member.type == 3 ||
              member.type == 4
            : filtereddata?.activePositionType == 2
            ? (member.launch_director != null &&
                (member.launch_director_at == filtereddata?.filterchap ||
                  filtereddata?.filterchap == 0)) ||
              (member.support_director != null &&
                (member.support_director_at == filtereddata?.filterchap ||
                  filtereddata?.filterchap == 0))
            : member.type == -1 ||
              member.type == 0 ||
              member.type == 1 ||
              member.type == 2 ||
              member.type == 3 ||
              member.type == 4 ||
              member.type == 5) &&
          (member.m_name
            .toLowerCase()
            .includes(filtereddata?.filtermember.toLowerCase()) ||
            member.business_name
              .toLowerCase()
              .includes(filtereddata?.filtermember.toLowerCase()) ||
            member.business_category
              .toLowerCase()
              .includes(filtereddata?.filtermember.toLowerCase()) ||
            member.mobile_no
              .toLowerCase()
              .includes(filtereddata?.filtermember.toLowerCase()))
        ) {
          return member;
        }
      });
    // setNewMemberList(nmember);
    // setactivedeactive(nmember);

    if (returnFilterData == true) {
      return nmember;
    } else {
      setNewMemberList(nmember);
      setactivedeactive(nmember);
    }
  };

  const FilterDateWise = async () => {
    let selectedCityChapter =
      chaptlist &&
      chaptlist
        ?.map((chpt) => {
          if (filtereddata?.filterCity != 0) {
            if (chpt?.city_id == filtereddata?.filterCity) {
              return chpt.chpt_id;
            }
          } else {
            return chpt.chpt_id;
          }
        })
        .filter((f) => f != undefined);

    let nmember = [];
    if (filtereddata.startDate != null && filtereddata.endDate != null) {
      let start = "";
      let end = "";
      nmember =
        memberlist &&
        memberlist.filter((member) => {
          if (filtereddata?.filterchap != 0) {
            if (
              member.joining_date != "" &&
              member.due_date != "" &&
              member.chpt_id == filtereddata?.filterchap
            ) {
              if (
                (member.chpt_id == filtereddata?.filterchap ||
                  filtereddata?.filterchap == 0 ||
                  (member.launch_director != null &&
                    member.launch_director_at == filtereddata?.filterchap) ||
                  (member.support_director != null &&
                    member.support_director_at == filtereddata?.filterchap)) &&
                selectedCityChapter.length != 0 &&
                selectedCityChapter.includes(member.chpt_id) &&
                (filtereddata?.activeMemberSearchType == 0
                  ? member.status == 0 || member.status == 1
                  : filtereddata?.activeMemberSearchType == 1
                  ? member.status == 1
                  : member.status == 0) &&
                (filtereddata?.activePositionType == 0
                  ? member.type == -1 ||
                    member.type == 0 ||
                    member.type == 1 ||
                    member.type == 2 ||
                    member.type == 3 ||
                    member.type == 4 ||
                    member.type == 5
                  : filtereddata.activePositionType == 1
                  ? member.type == 1 ||
                    member.type == 2 ||
                    member.type == 3 ||
                    member.type == 4
                  : filtereddata?.activePositionType == 2
                  ? (member.launch_director != null &&
                      (member.launch_director_at == filtereddata?.filterchap ||
                        filtereddata?.filterchap == 0)) ||
                    (member.support_director != null &&
                      (member.support_director_at == filtereddata?.filterchap ||
                        filtereddata?.filterchap == 0))
                  : member.type == -1 ||
                    member.type == 0 ||
                    member.type == 1 ||
                    member.type == 2 ||
                    member.type == 3 ||
                    member.type == 4 ||
                    member.type == 5) &&
                (member.m_name
                  .toLowerCase()
                  .includes(filtereddata?.filtermember.toLowerCase()) ||
                  member.business_name
                    .toLowerCase()
                    .includes(filtereddata?.filtermember.toLowerCase()) ||
                  member.mobile_no
                    .toLowerCase()
                    .includes(filtereddata?.filtermember.toLowerCase())) &&
                moment(
                  filtereddata.search_date_type == 0
                    ? member.joining_date
                    : member.due_date,
                  "DD-MM-YYYY"
                ).isBetween(
                  filtereddata.startDate,
                  filtereddata.endDate,
                  "days",
                  "[]"
                )
              ) {
                return member;
              }
            }
          } else {
            if (member.joining_date != "" && member.due_date != "") {
              if (
                (member.chpt_id == filtereddata?.filterchap ||
                  filtereddata?.filterchap == 0 ||
                  (member.launch_director != null &&
                    member.launch_director_at == filtereddata?.filterchap) ||
                  (member.support_director != null &&
                    member.support_director_at == filtereddata?.filterchap)) &&
                selectedCityChapter.length != 0 &&
                selectedCityChapter.includes(member.chpt_id) &&
                (filtereddata?.activeMemberSearchType == 0
                  ? member.status == 0 || member.status == 1
                  : filtereddata?.activeMemberSearchType == 1
                  ? member.status == 1
                  : member.status == 0) &&
                (filtereddata?.activePositionType == 0
                  ? member.type == -1 ||
                    member.type == 0 ||
                    member.type == 1 ||
                    member.type == 2 ||
                    member.type == 3 ||
                    member.type == 4 ||
                    member.type == 5
                  : filtereddata.activePositionType == 1
                  ? member.type == 1 ||
                    member.type == 2 ||
                    member.type == 3 ||
                    member.type == 4
                  : filtereddata?.activePositionType == 2
                  ? (member.launch_director != null &&
                      (member.launch_director_at == filtereddata?.filterchap ||
                        filtereddata?.filterchap == 0)) ||
                    (member.support_director != null &&
                      (member.support_director_at == filtereddata?.filterchap ||
                        filtereddata?.filterchap == 0))
                  : member.type == -1 ||
                    member.type == 0 ||
                    member.type == 1 ||
                    member.type == 2 ||
                    member.type == 3 ||
                    member.type == 4 ||
                    member.type == 5) &&
                (member.m_name
                  .toLowerCase()
                  .includes(filtereddata?.filtermember.toLowerCase()) ||
                  member.business_name
                    .toLowerCase()
                    .includes(filtereddata?.filtermember.toLowerCase()) ||
                  member.mobile_no
                    .toLowerCase()
                    .includes(filtereddata?.filtermember.toLowerCase())) &&
                moment(
                  filtereddata.search_date_type == 0
                    ? member.joining_date
                    : member.due_date,
                  "DD-MM-YYYY"
                ).isBetween(
                  filtereddata.startDate,
                  filtereddata.endDate,
                  "days",
                  "[]"
                )
              ) {
                return member;
              }
            }
          }
        });
      setNewMemberList(nmember);
      setactivedeactive(nmember);
    } else {
      // let notify = notification({
      //   type: "error",
      //   message: "Please Check Start Date And End Date",
      // });
      // notify();
    }
  };
  const setactivedeactive = (newMemeberList) => {
    let actcnt = 0;
    let deactcnt = 0;
    let dircnt = 0;

    newMemeberList?.map((e, key) => {
      if (
        (e.launch_director_at == filtereddata?.filterchap ||
          e.support_director_at == filtereddata?.filterchap ||
          filtereddata.filterchap == 0) &&
        (e.launch_director != null || e.support_director != null)
      ) {
        dircnt = dircnt + 1;
      } else if (e.status == 0) {
        deactcnt = deactcnt + 1;
      } else if (e.status == 1) {
        actcnt = actcnt + 1;
      }
    });
    setactiveMembercnt(actcnt);
    setdeactiveMembercnt(deactcnt);
    setdirectorcnt(dircnt);
  };

  const getChapterList = async () => {
    let newChptOption = [{ chpt_id: 0, chpt_name: "All" }];
    let res = await fetchchapterbyuser(loginUser.city_id, loginUser.u_type);
    res?.map((e) => {
      newChptOption.push({
        ...e,
      });
    });

    setchaplist(newChptOption);
  };

  useEffect(async () => {
    // getChapterList();
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
    setmemberlist(await GetAllMember(loginUser.city_id, loginUser.u_type));
  }, [step, statusresp]);

  useEffect(async () => {
    if (
      filtereddata.filterchap != undefined ||
      filtereddata.filtermember != undefined ||
      filtereddata.startDate != null ||
      filtereddata.endDate != null
    ) {
      await filterData();
      await FilterDateWise();
    }
  }, [filtereddata, memberlist]);

  useEffect(async () => {
    chaptlist.map((option) => {
      filterOption.push({ value: option.chpt_id, label: option.chpt_name });
    });
  }, [chaptlist]);

  const changestatus = async (m_id, status, reason = "", date = null) => {
    let fdata = new FormData();
    fdata.append("m_id", m_id);
    fdata.append("status", status);
    fdata.append("reason", reason);
    fdata.append("deactivation_date", moment(date).format("DD-MM-YYYY"));

    if (
      (status == 0 &&
        reason != "" &&
        date != null &&
        moment(date).format("DD-MM-YYYY") != "Invalid date") ||
      (status == 1 && reason == "")
    ) {
      const resp = await UpdateMemberStatus(fdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();

      if (resp.status == 1) {
        setstatusresp(statusresp + 1);
        setMemberdeactiveReasons({
          m_id: "",
          status: "",
          reasons: "",
        });
        setmemberDeActiveModal(false);
      }
    } else {
      let notify = notification({
        type: "error",
        message: "Please Check Reason Or Date And Try Again",
      });
      notify();
    }
  };

  const getmemberfeehistory = async (m_id) => {
    setmemberfeedata(await fetchmemberfeehistory(m_id));
  };

  const ulogout = async (m_id) => {
    const resp = await userLogout(m_id);

    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      setstatusresp(statusresp + 1);
    }
  };

  const getmember = async (id) => {
    const response = await GetMemberById(id);
    setEditMemberData(response.data);
    setStep(3);
  };

  const [memberVerificationData, setMemberVerificationData] = useState({
    date: null,
    blue_tick_assign_date: null,
    m_id: "",
    m_verify_status: "",
  });
  const removeMemberVeification = async (memberId, m_verify_status) => {
    let resp = await updateMemberVerificationStatus({
      member_id: memberId,
      status: m_verify_status,
      date: null,
    });

    if (resp.status == 1) {
      setstatusresp(statusresp + 1);
      setMemberVerificationData({
        m_id: "",
        m_verify_status: "",
        date: null,
        blue_tick_assign_date: null,
      });
      setMemberVerificationModal(false);
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };
  const changeGreenVerify = async () => {
    if (
      memberVerificationData.m_verify_status == 0 ||
      (memberVerificationData.m_verify_status == 1 &&
        memberVerificationData?.date != "" &&
        memberVerificationData?.date != null) ||
      (memberVerificationData.m_verify_status == 2 &&
        memberVerificationData?.blue_tick_assign_date != "" &&
        memberVerificationData?.blue_tick_assign_date != null)
    ) {
      let resp = await updateMemberVerificationStatus({
        member_id: memberVerificationData?.m_id,
        status: memberVerificationData?.m_verify_status,
        date: memberVerificationData?.date,
        blue_tick_assign_date: memberVerificationData?.blue_tick_assign_date,
      });
      if (resp.status == 1) {
        setstatusresp(statusresp + 1);
        setMemberVerificationData({
          m_id: "",
          m_verify_status: "",
          date: null,
          blue_tick_assign_date: null,
        });
        setMemberVerificationModal(false);
      }

      let successMessage = ``;

      // if admin
      if (
        !(
          loginUser?.u_type == 0 ||
          (loginUser?.u_type == 2 &&
            loginUser?.p_user_id == 1 &&
            loginUser?.management_p != 0)
        )
      ) {
        successMessage = `Member  ${
          memberVerificationData?.m_verify_status == 1
            ? "OBT Compeleted "
            : memberVerificationData?.m_verify_status == 2
            ? "MGT Compeleted"
            : "OBT Removed"
        } Successfully`;
      } else {
        // if city amb
        successMessage = `Member  ${
          memberVerificationData?.m_verify_status == 1
            ? "OBT Compeleted "
            : memberVerificationData?.m_verify_status == 2
            ? "MGT Compeleted"
            : "OBT/MGT Removed"
        } Successfully`;
      }

      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.status == 0 ? resp.message : successMessage,
      });
      notify();
    } else {
      let notify = notification({
        type: "error",
        message: "Please Enter Member Verification Date",
      });
      notify();
    }
  };

  const export_columns_pinAssign = [
    { label: "Pin Name", key: "name" },
    { label: "Pin Assign Date", key: "date" },
  ];
  const expo_columns = [
    { label: "No", key: "m_id" },
    { label: "Member Name", key: "m_name" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Contact No", key: "mobile_no" },
    { label: "Email", key: "m_email_id" },
    {
      label: "Join Date",
      key: "joining_date",
    },
    {
      label: "Expire Date",
      key: "due_date",
    },
    { label: "Business Name", key: "business_name" },
    { label: "Business Category", key: "business_category" },
    { label: "Status", key: "Current_Status" },
    { label: "Director At", key: "combined_director" },
    { label: "Member Position", key: "member_new_position" },
    { label: "Member Refer By", key: "member_referal_by" },
    { label: "Deactivation Date", key: "deactivation_date" },
    { label: "Deactivation Reason", key: "reason" },
    { label: "OBT Complete Date", key: "m_verify_date" },
    { label: "MGT Complete Date", key: "blue_tick_assign_date" },
  ];

  const expo_columns_Additional = [
    { label: "No", key: "m_id" },
    { label: "Member Name", key: "m_name" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Contact No", key: "mobile_no" },
    { label: "Email", key: "m_email_id" },
    { label: "Business Name", key: "business_name" },
    { label: "Business Category", key: "business_category" },
    { label: "Business Since", key: "business_since" },
    { label: "Business Description", key: "business_desc" },
    { label: "Business Address", key: "b_address" },
    { label: "Status", key: "Current_Status" },
    { label: "DOB", key: "dob" },
    { label: "Blood Group", key: "blood_group" },
    { label: "Member Position", key: "member_new_position" },
    { label: "Director At", key: "combined_director" },
    { label: "Website URL", key: "website_url" },
    { label: "Facebook URL", key: "fb" },
    { label: "Instagram URL", key: "instagram" },
    { label: "LinkedIn URL", key: "linkedin" },
    { label: "Twitter URL", key: "twitter" },
    { label: "YouTube URL", key: "youtube" },
    {
      label: "Join Date",
      key: "joining_date",
    },
    {
      label: "Expire Date",
      key: "due_date",
    },
    { label: "Reference By", key: "member_referal_by" },
    { label: "Deactivation Date", key: "deactivation_date" },
    { label: "Deactivation Reason", key: "reason" },
    { label: "OBT Complete Date", key: "m_verify_date" },
    { label: "MGT Complete Date", key: "blue_tick_assign_date" },
  ];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.m_id,
      sortable: true,
      wrap: true,
      width: "70px",
    },
    {
      name: "Member Name",
      wrap: true,
      selector: (row) => (
        <>
          <a
            style={{
              cursor: "pointer",
              overflow: "visible",
              whiteSpace: "pre-line",
            }}
            onClick={() => (
              getMemberAchivementByMemberId(row.m_id), toggleAchievementModal()
            )}
          >
            {row.m_name}
          </a>
        </>
      ),
      sortable: true,
    },
    {
      name: "Chapter Name",
      wrap: true,
      selector: (row) => row.chpt_name,
      sortable: true,
    },
    {
      name: "Position",
      wrap: true,
      width: "200px",

      selector: (row) => (
        <div style={{ overflow: "visible", whiteSpace: "pre-line" }}>
          {/* {row.type == 1
            ? "President"
            : row.type == 2
            ? "Vice President"
            : row.type == 3
            ? "ST"
            : row.type == 4
            ? "LVH"
            : row.type == 5
            ? "Support Director"
            : "Member"} */}
          {row.member_new_position}
          <br />
          {row.combined_director != null && " " + row.combined_director}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Business Name",
      selector: (row) => row.business_name,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Business Category",
      selector: (row) => row.business_category,
      sortable: true,
      wrap: true,
      width: "120px",
    },
    {
      name: "Contact No",
      wrap: true,
      width: "120px",

      selector: (row) => row.mobile_no,
    },
    {
      name: "Reference By",
      wrap: true,
      selector: (row) => {
        return (
          <div title={row.member_referal_by != null && row.member_referal_by}>
            {row.member_referal_by != null ? row.member_referal_by : "-"}
          </div>
        );
      },
    },
    {
      name: "Join Date",
      wrap: true,
      selector: (row) => row.joining_date,
    },
    {
      name: "Expire Date",
      wrap: true,
      selector: (row) => row.due_date,
    },
    {
      name: "OBT Complete Date",
      wrap: true,
      selector: (row) => row.m_verify_date || "-",
    },
    {
      name: "MGT Complete Date",
      wrap: true,
      selector: (row) => row.blue_tick_assign_date || "-",
    },
    {
      name: "Status",
      wrap: true,
      width: "150px",
      selector: (row) => (
        <>
          {row.status == 1 ? (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="success"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  Active
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    setMemberdeactiveReasons({
                      m_id: row.m_id,
                      status: 0,
                      reason: "",
                    });

                    setmemberDeActiveModal(true);
                  }}
                  style={{ background: "#ff4842" }}
                >
                  Deactive
                </Button>
              </div>
            </>
          ) : (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="error"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Deactive
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.m_id, 1);
                  }}
                >
                  Active
                </Button>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      wrap: true,
      width: "250px",
      cell: (row) => (
        <>
          <div
            style={
              row.status == 1
                ? {
                    height: "20px",
                    width: "20px",
                    border: "2px Solid #000",
                    backgroundColor: "Green",
                    borderRadius: "50%",
                  }
                : {
                    height: "20px",
                    width: "20px",
                    border: "2px Solid #000",
                    backgroundColor: "Red",
                    borderRadius: "50%",
                  }
            }
          ></div>
          <a
            href="#"
            onClick={() => {
              getmember(row.m_id);
            }}
          >
            <Tooltip title="Edit Member">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
          {row.status == 0 && (
            <a
              onClick={() => {
                setSeeMemberDeActiveModal(true);
                setDeActivateReasonData({
                  ...deActivateReasonData,
                  memberName: row.m_name + "(" + row.business_name + ")",
                  deActivationDate: row.deactivation_date,
                  deActivationReason: row.reason,
                });
              }}
            >
              <Tooltip title="See Deactivate Reason">
                <IconButton>
                  <RemoveRedEye color="success" />
                </IconButton>
              </Tooltip>
            </a>
          )}

          <a
            onClick={async () => {
              setmemberfeeModal(true);
              await getmemberfeehistory(row.m_id);
            }}
          >
            <Tooltip title="Member Fees">
              <IconButton>
                <CurrencyRupeeSharp color="info" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={() => (
              setPinMemberActiveName(
                row?.m_name +
                  " (" +
                  row.business_name +
                  ") [" +
                  row.Current_Status +
                  "]"
              ),
              getMemberAchivementByMemberId(row.m_id),
              toggleAchievementModal()
            )}
          >
            <Tooltip title="Member Achievement">
              <IconButton>
                <EmojiEvents color="success" />
              </IconButton>
            </Tooltip>
          </a>
          {/* Member Verified */}
          <a
            onClick={() => {
              // if (row?.m_verify_status == 0) {
              setMemberVerificationData({
                m_id: row?.m_id,
                m_verify_status: row?.m_verify_status,
                date: row?.m_verify_date,
                blue_tick_assign_date: row?.blue_tick_assign_date,
              });
              setMemberVerificationModal(true);
              // } else {
              //   removeMemberVeification(
              //     row?.m_id,
              //     row?.m_verify_status == 0 ? 1 : 0
              //   );
              // }

              //
            }}
          >
            <Tooltip title="Member Verification">
              <IconButton>
                <VerifiedUser
                  sx={{
                    color:
                      row?.m_verify_status == 0
                        ? "error.main"
                        : row?.m_verify_status == 2
                        ? "#007bff"
                        : "success.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </a>
          {row.is_login == 1 ? (
            <a
              onClick={() => {
                ulogout(row.m_id);
              }}
            >
              <Tooltip title="Logout Member">
                <IconButton>
                  <LogoutSharp color="error" />
                </IconButton>
              </Tooltip>
            </a>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
  };

  const cancelmemberDeActiveModal = () => {
    setmemberDeActiveModal(false);
    setMemberdeactiveReasons({
      m_id: "",
      status: "",
      reasons: "",
      deactivation_date: null,
    });
  };
  const cancelModal = () => {
    setmemberfeeModal(false);
    setmemberfeedata([]);
  };

  const closeMemberDeActiveModal = () => {
    setSeeMemberDeActiveModal(false);
    setDeActivateReasonData({
      memberName: "",
      deActivationDate: "",
      deActivationReason: "",
    });
  };

  const memberType = [
    { label: "All", value: 0 },
    { label: "Active Member", value: 1 },
    { label: "De-Active Member", value: 2 },
  ];

  const memberPosition = [
    { label: "All", value: 0 },
    { label: "LT", value: 1 },
    { label: "Director", value: 2 },
    { label: "Member", value: 3 },
  ];

  const [achievementOptions, setAchievementOption] = useState([]);
  const [pinMemberActiveName, setPinMemberActiveName] = useState("");
  const [memberSelecedAchievement, setMemberSelecedAchievement] = useState({
    u_id: "",
    achievement: [],
  });
  const getAchivementOptions = async () => {
    let res = await fetchPinList(1);
    if (res?.length > 0) {
      setMemberSelecedAchievement({
        ...memberSelecedAchievement,
        achievement: res?.map((achive) => {
          return (
            achive.status == 1 && {
              p_id: achive.p_id,
              name: achive.name,
              date: null,
              check: false,
            }
          );
        }),
      });
      setAchievementOption(res);
    }
  };

  useEffect(() => {
    getAchivementOptions();
  }, []);
  const getMemberAchivementByMemberId = async (memberId) => {
    if (memberId != 0 || memberId != "" || memberId != undefined) {
      let resp = await getPinBadgeByMemberId(memberId);
      setMemberSelecedAchievement({
        u_id: memberId,
        achievement:
          resp != null
            ? memberSelecedAchievement.achievement.map((achievement) => {
                // return resp.find((item) => item.p_id === achievement.p_id)
                //   ? { ...achievement, date: item.date, check: true }
                //   : achievement;
                let foundItem = resp.find(
                  (item) => item.p_id === achievement.p_id
                );
                return foundItem
                  ? { ...achievement, date: foundItem.date, check: true }
                  : achievement;
              })
            : achievementOptions?.map((achive) => {
                return (
                  achive.status == 1 && {
                    p_id: achive.p_id,
                    check: false,
                  }
                );
              }),
      });
    } else {
      let notify = notification({
        type: "error",
        message: "something went wrong Please try again later",
      });
      notify();
    }
  };

  const handleAchievementSubmit = async () => {
    let resp = await assginPinFunction(memberSelecedAchievement);
    if (resp.status == 1) {
      setMemberSelecedAchievement({
        u_id: "",
        achievement: achievementOptions?.map((achive) => {
          return (
            achive.status == 1 && {
              p_id: achive.p_id,
              check: false,
            }
          );
        }),
      });
      toggleAchievementModal();
    } else {
      toggleAchievementModal();
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
  };
  const [checked, setChecked] = React.useState(true);
  const handleSwitchChange = async (index) => {
    const updatedObj = {
      ...memberSelecedAchievement,
    };
    updatedObj.achievement[index].check = !updatedObj.achievement[index].check;
    setMemberSelecedAchievement(updatedObj);
  };
  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
  };
  useEffect(async () => {
    getallcitylist();
  }, []);

  return (
    <>
      <Modal
        open={memberVerificationModal}
        onClose={() => {
          setMemberVerificationData({
            m_id: "",
            m_verify_status: "",
            date: null,
            blue_tick_assign_date: null,
          });
          setMemberVerificationModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Member Verification
          </Typography>

          <div style={{ padding: "10px" }}>
            <hr />
            <div style={{ maxHeight: "480px" }}>
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  {/* For Now Blue Tick Removed from city AMB */}
                  {/* When wver the client will ask for add again then remove the below grid code with login  loginUser?.u_type == 0 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id == 1 &&
                        loginUser?.management_p != 0) */}
                  <Grid container spacing={1}>
                    {!(
                      loginUser?.u_type == 0 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id == 1 &&
                        loginUser?.management_p != 0)
                    ) && <Grid item xs={3}></Grid>}

                    <Grid item xs={6}>
                      <Stack>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Enable OBT Complete
                          </FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  name="m_verify_status"
                                  checked={
                                    memberVerificationData.m_verify_status == 1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let x = 0;
                                    if (e.target.checked == true) x = 1;
                                    setMemberVerificationData({
                                      ...memberVerificationData,
                                      [e.target.name]: x,
                                    });
                                  }}
                                  value="0"
                                />
                              }
                              label="Enable OBT Complete "
                            />
                          </FormGroup>
                        </FormControl>
                        <Stack spacing={3} style={{ marginTop: "15px" }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            style={{ marginTop: "15px" }}
                          >
                            <DatePicker
                              type="date"
                              name="deactivation_date"
                              label="OBT Complete Date"
                              inputFormat="dd/MM/yyyy"
                              value={
                                memberVerificationData.date != null
                                  ? moment(
                                      memberVerificationData.date,
                                      "DD/MM/YYYY"
                                    )
                                  : null
                              }
                              onChange={(newDate) => {
                                setMemberVerificationData({
                                  ...memberVerificationData,
                                  date: moment(newDate).format("DD-MM-YYYY"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Stack>
                    </Grid>
                    {/* For Now Blue Tick Removed from city AMB */}
                    {/* When wver the client will ask for add again then remove the below condition */}
                    {(loginUser?.u_type == 0 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id == 1 &&
                        loginUser?.management_p != 0)) && (
                      <Grid item xs={6}>
                        <Stack>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Enable MGT Complete
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    name="m_verify_status"
                                    checked={
                                      memberVerificationData.m_verify_status ==
                                      2
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      let x = 0;
                                      if (e.target.checked == true) x = 2;
                                      setMemberVerificationData({
                                        ...memberVerificationData,
                                        [e.target.name]: x,
                                      });
                                    }}
                                    value="0"
                                  />
                                }
                                label="Enable MGT Complete"
                              />
                            </FormGroup>
                          </FormControl>
                          <Stack spacing={3} style={{ marginTop: "15px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                type="date"
                                name="blue_tick_assign_date"
                                label="MGT Complete Date"
                                inputFormat="dd/MM/yyyy"
                                value={
                                  memberVerificationData.blue_tick_assign_date !=
                                  null
                                    ? moment(
                                        memberVerificationData.blue_tick_assign_date,
                                        "DD/MM/YYYY"
                                      )
                                    : null
                                }
                                onChange={(newDate) => {
                                  setMemberVerificationData({
                                    ...memberVerificationData,
                                    blue_tick_assign_date: moment(
                                      newDate
                                    ).format("DD-MM-YYYY"),
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Stack>
                        {/* <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            style={{ marginTop: "15px" }}
                          >
                            <DatePicker
                              type="date"
                              name="deactivation_date"
                              label="Date "
                              inputFormat="dd/MM/yyyy"
                              value={
                                memberVerificationData.date != null
                                  ? moment(
                                      memberVerificationData.date,
                                      "DD/MM/YYYY"
                                    )
                                  : null
                              }
                              onChange={(newDate) => {
                                setMemberVerificationData({
                                  ...memberVerificationData,
                                  date: moment(newDate).format("DD-MM-YYYY"),
                                });
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Stack> */}
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setMemberVerificationModal(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  changeGreenVerify();
                  // changestatus(
                  //   memberdeactiveReasons.m_id,
                  //   memberdeactiveReasons.status,
                  //   memberdeactiveReasons.reason,
                  //   memberdeactiveReasons.deactivation_date
                  // );
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
        {/* changestatus(row.m_id, 0); */}
      </Modal>
      {/* Member Deactivate Add Reason Modal */}
      <Modal
        open={memberDeActiveModal}
        onClose={cancelmemberDeActiveModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Member De-activation Reason
          </Typography>

          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "480px" }}>
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            type="date"
                            name="deactivation_date"
                            label="Date "
                            inputFormat="dd/MM/yyyy"
                            value={
                              memberdeactiveReasons.deactivation_date != null
                                ? memberdeactiveReasons.deactivation_date
                                : null
                            }
                            onChange={(newDate) => {
                              setMemberdeactiveReasons({
                                ...memberdeactiveReasons,
                                deactivation_date: newDate,
                              });
                              setMemberVerificationData(
                                moment(newDate).format("DD-MM-YYYY")
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          height={10}
                          fullWidth
                          multiline
                          autoComplete="off"
                          type="text"
                          label="Reason"
                          name="reason"
                          onChange={(e) => {
                            setMemberdeactiveReasons({
                              ...memberdeactiveReasons,
                              reason: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                {/* </Card> */}
              </Stack>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setmemberDeActiveModal(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  changestatus(
                    memberdeactiveReasons.m_id,
                    memberdeactiveReasons.status,
                    memberdeactiveReasons.reason,
                    memberdeactiveReasons.deactivation_date
                  );
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
        {/* changestatus(row.m_id, 0); */}
      </Modal>
      {/* Start Display Member Deactivation Reason Modal */}
      <Modal
        open={seeMemberDeActiveModal}
        onClose={closeMemberDeActiveModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ borderBottom: "1px solid", paddingBottom: "5px" }}
          >
            Member Detactivation Reason
          </Typography>

          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "480px" }}>
              <Stack
                spacing={10}
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <b>Member Name</b>
                  </Grid>
                  <Grid item xs={9}>
                    <span>: {deActivateReasonData.memberName}</span>
                  </Grid>

                  <Grid item xs={3}>
                    <b>Deactivation Date</b>
                  </Grid>
                  <Grid item xs={9}>
                    <span>
                      :
                      {deActivateReasonData.deActivationDate != null ? (
                        deActivateReasonData.deActivationDate
                      ) : (
                        <span style={{ marginLeft: "80px" }}>-</span>
                      )}
                    </span>
                  </Grid>

                  <Grid item xs={3}>
                    <b>Reason</b>
                  </Grid>
                  <Grid item xs={9}>
                    <span>
                      :
                      {deActivateReasonData.deActivationReason != null ? (
                        deActivateReasonData.deActivationReason
                      ) : (
                        <span style={{ marginLeft: "80px" }}>-</span>
                      )}
                    </span>
                  </Grid>
                </Grid>
              </Stack>
            </div>
          </div>
          <div
            style={{
              margin: "10px auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" onClick={closeMemberDeActiveModal}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
      {/* End Display Member Deactivation Reason Modal */}
      {/* Member Fees List Modal */}
      <Modal
        open={memberfeeModal}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Fee History
          </Typography>
          <div>
            <div
              style={{
                maxHeight: "280px",
                overflowY: "scroll",
                overflowX: "hidden",
                padding: "15px",
              }}
            >
              <div className="Poll_details">
                {memberfeedata.length != 0 ? (
                  memberfeedata?.map((m) => {
                    return (
                      <>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px 15px",
                            borderRadius: "10px",
                            boxShadow: "0 0 5px -2px #00000078",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ margin: "0 auto" }}
                          >
                            <Grid
                              item
                              xs={6}
                              style={{
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {m.date}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: "center",
                                padding: "10px 15px",
                              }}
                            >
                              ₹ {m.member_fee}
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div>
                    <h4 style={{ textAlign: "center", color: "silver" }}>
                      No Data Found
                    </h4>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button variant="contained" onClick={cancelModal}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Display Member Deactivation Reason */}

      {/* Member Achivement Modal Open */}
      <Modal
        open={achievementModal}
        onClose={() => (
          toggleAchievementModal(),
          setMemberSelecedAchievement({
            u_id: "",
            achievement: [],
          }),
          getAchivementOptions()
          // setMemberSelecedAchievement({
          //   ...memberSelecedAchievement,
          //   u_id: "",
          //   achievement: achievementOptions?.map((achive) => {
          //     return (
          //       achive.status == 1 && {
          //         p_id: achive.p_id,
          //         date: null,
          //         check: false,
          //       }
          //     );
          //   }),
          //   // achievement: [],
          // })
        )}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
            marginBottom={0.25}
            borderBottom={1}
          >
            {pinMemberActiveName} Member's Achievement
          </Typography>

          <Button className="btn btn-export">
            <CSVLink
              data={memberSelecedAchievement.achievement.filter(
                (achievement) => achievement.check === true
              )}
              headers={export_columns_pinAssign}
              filename={`${pinMemberActiveName} Pin Data.csv`}
              className="btn btn-primary test"
              target="_blank"
            >
              Export CSV
            </CSVLink>
          </Button>
          <div style={{ padding: "15px 0 0 0" }}>
            <div
              style={{ maxHeight: "420px", overflow: "scroll", width: "100%" }}
            >
              <Grid container>
                {achievementOptions?.map((achievement, index) => {
                  return (
                    <>
                      <Grid item xs={6} sm={6} p={1}>
                        <Stack>
                          <FormLabel component="legend">
                            {achievement.name}
                          </FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={achievement.p_id}
                                  checked={
                                    memberSelecedAchievement?.achievement[index]
                                      ?.check
                                  }
                                  onChange={() => {
                                    handleSwitchChange(index);
                                  }}
                                />
                              }
                            />
                          </FormGroup>
                        </Stack>
                        {memberSelecedAchievement?.achievement[index]
                          ?.check && (
                          <Stack>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              style={{ marginTop: "15px" }}
                            >
                              <DatePicker
                                type="date"
                                name="s_date"
                                label="Pin Assign Date "
                                inputFormat="dd/MM/yyyy"
                                // value={null}
                                value={
                                  memberSelecedAchievement?.achievement[index]
                                    ?.date != null
                                    ? moment(
                                        memberSelecedAchievement?.achievement[
                                          index
                                        ]?.date,
                                        "DD/MM/YYYY"
                                      )
                                    : null
                                }
                                onChange={(newDate) => {
                                  const updatedAchievement = [
                                    ...memberSelecedAchievement.achievement,
                                  ];
                                  updatedAchievement[index].date = moment(
                                    newDate
                                  ).format("DD-MM-YYYY");

                                  setMemberSelecedAchievement({
                                    ...memberSelecedAchievement,
                                    achievement: updatedAchievement,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // onKeyDown={(e) => e.preventDefault()}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Stack>
                        )}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </div>
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button variant="outlined" onClick={toggleAchievementModal}>
                Close
              </Button>

              <Button
                variant="contained"
                className="ms-25"
                style={{ marginLeft: "15px" }}
                onClick={handleAchievementSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Page Contend */}
      <Page title="Member | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Member
            </Typography>
          </Stack>
          {(loginUser?.u_type == 0 ||
            (loginUser?.u_type == 2 && loginUser?.p_user_id == 1)) && (
            <Button
              onClick={() => {
                step == 1 ? setStep(2) : setStep(1);
              }}
              style={{ marginBottom: "15px" }}
              variant="contained"
            >
              {step == 1 ? "Add Member" : "Back"}
            </Button>
          )}
          {loginUser?.u_type == 1 && step == 3 && (
            <Button
              onClick={() => {
                step == 1 ? setStep(2) : setStep(1);
              }}
              style={{ marginBottom: "15px" }}
              variant="contained"
            >
              {step == 1 ? "Add Member" : "Back"}
            </Button>
          )}

          {step != 1 && (
            <Card
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                marginBottom: "25px",
              }}
            >
              <MemberForm
                data={editMember}
                setStep={setStep}
                step={step}
                memberlist={memberlist}
              />
            </Card>
          )}
          {step == 1 && (
            <>
              <Card style={{ marginTop: "25px", padding: "25px" }}>
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    {/* Member Filter Features */}
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "0",
                        paddingTop: "0",
                        textAlign: "center",
                      }}
                    >
                      <h3>Member Filter</h3>
                    </Grid>

                    <Grid item xs={2}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select City
                        </label>
                        <Select
                          isDisabled={
                            loginUser?.u_type == 1 ||
                            (loginUser?.u_type == 2 &&
                              loginUser?.p_user_id != 1 &&
                              loginUser?.management_p == 1)
                              ? true
                              : false
                          }
                          value={
                            filtereddata.filterCity == 0
                              ? {
                                  value: 0,
                                  label: "All City",
                                }
                              : citylist.map((city) => {
                                  return (
                                    filtereddata.filterCity == city.city_id && {
                                      value: city.city_id,
                                      label: city.city_name,
                                    }
                                  );
                                })
                          }
                          onChange={(e) => {
                            setFilteredData({
                              ...filtereddata,
                              filterCity: e.value,
                              filterchap: 0,
                            });
                          }}
                          options={[
                            {
                              value: 0,
                              label: "All City",
                            },
                            ...citylist.map((city) => {
                              return {
                                label: city?.city_name,
                                value: city?.city_id,
                              };
                            }),
                          ]}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={3}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select Chapter
                        </label>
                        <Select
                          value={
                            filtereddata.filterchap == 0
                              ? { value: 0, label: "All Chapter" }
                              : chaptlist &&
                                chaptlist.map((c) => {
                                  if (c.chpt_id == filtereddata.filterchap) {
                                    return {
                                      value: c.chpt_id,
                                      label: c.chpt_name,
                                    };
                                  }
                                })
                            // .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="chpt_id"
                          onChange={(e) => {
                            setFilteredData({
                              ...filtereddata,
                              filterchap: e.value,
                            });
                          }}
                          // onChange={handleChapterSelect}
                          options={[
                            // chaptlist &&
                            // chaptlist?.map((chpt) => {
                            //   return {
                            //     value: chpt.chpt_id,
                            //     label: chpt.chpt_name,
                            //   };
                            // })
                            {
                              value: 0,
                              label: "All Chapter",
                            },
                            ...(chaptlist &&
                              chaptlist
                                ?.map((chpt) => {
                                  if (filtereddata?.filterCity != 0) {
                                    if (
                                      chpt?.city_id == filtereddata?.filterCity
                                    ) {
                                      return {
                                        value: chpt.chpt_id,
                                        label: chpt.chpt_name,
                                      };
                                    }
                                  } else {
                                    return {
                                      value: chpt.chpt_id,
                                      label: chpt.chpt_name,
                                    };
                                  }
                                })
                                .filter((f) => f != undefined)),
                          ]}
                        ></Select>
                        {/* <ChapterSelectDropDown
                          dropDownLabel={"Select Chapter"}
                          selectedState={filtereddata}
                          chapterKey="filterchap"
                          handleChange={(e) => {
                            // setFilter({ ...filter, chpt_id: e.value });
                            setFilteredData({
                              ...filtereddata,
                              filterchap: e.value,
                            });
                          }}
                        /> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Search Member
                        </label>
                        <TextField
                          style={{ marginTop: "0px" }}
                          autoComplete="off"
                          type="text"
                          label="Search Member"
                          name="search"
                          value={
                            filtereddata?.filtermember
                              ? filtereddata?.filtermember
                              : ""
                          }
                          onChange={(e) => {
                            setFilteredData({
                              ...filtereddata,
                              filtermember: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select Member Position
                        </label>
                        <Select
                          value={memberPosition.map((c) => {
                            if (c.value == filtereddata.activePositionType) {
                              return { value: c.value, label: c.label };
                            }
                          })}
                          onChange={(e) => {
                            setFilteredData({
                              ...filtereddata,
                              activePositionType: e.value,
                            });
                          }}
                          options={memberPosition}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select Member Type
                        </label>
                        <Select
                          value={memberType.map((c) => {
                            if (
                              c.value == filtereddata.activeMemberSearchType
                            ) {
                              return { value: c.value, label: c.label };
                            }
                          })}
                          onChange={(e) => {
                            setFilteredData({
                              ...filtereddata,
                              activeMemberSearchType: e.value,
                            });
                          }}
                          options={memberType}
                        />
                      </Stack>
                    </Grid>
                    {/* Member Search  */}

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "0",
                        paddingTop: "0",
                        textAlign: "center",
                      }}
                    >
                      <h3>Member Search By Joining Or Expire Date</h3>
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ marginBottom: "0px" }}
                      >
                        Search Member by Date
                      </FormLabel>
                      <RadioGroup
                        style={{ marginTop: "0px" }}
                        row
                        value={filtereddata.search_date_type}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            search_date_type: e.target.value,
                          });
                        }}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="p_type"
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Search on Join Date"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Search on Expire Date"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={3}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            type="date"
                            name="s_date"
                            label="Start Date "
                            inputFormat="dd/MM/yyyy"
                            value={
                              filtereddata.startDate != null
                                ? filtereddata.startDate
                                : null
                            }
                            onChange={(newDate) => {
                              setFilteredData({
                                ...filtereddata,
                                startDate: newDate,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // onKeyDown={(e) => e.preventDefault()}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            type="date"
                            name="e_date"
                            label="End Date "
                            inputFormat="dd/MM/yyyy"
                            value={
                              filtereddata.endDate != null
                                ? filtereddata.endDate
                                : null
                            }
                            onChange={(newDate) => {
                              setFilteredData({
                                ...filtereddata,
                                endDate: newDate,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // onKeyDown={(e) => e.preventDefault()}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        padding: "0",
                      }}
                    >
                      <Button variant="contained" onClick={FilterDateWise}>
                        Search
                      </Button>
                      <Button
                        variant="contained"
                        onClick={async () => {
                          setFilteredData({
                            filterchap: 0,
                            filtermember: "",
                            startDate: null,
                            endDate: null,
                            search_date_type: 0,
                            activeMemberSearchType: 0,
                            activePositionType: 0,
                            filterCity:
                              loginUser?.u_type == 1 ||
                              (loginUser?.u_type == 2 &&
                                loginUser?.p_user_id != 1 &&
                                loginUser?.management_p == 1)
                                ? loginUser?.city_id
                                : 0,
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Card>
                        <div
                          style={{
                            padding: "15px",
                            background: "#1c4766",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#fff",
                          }}
                        >
                          <h3>Active Member </h3>
                          <h3>{activeMembercnt}</h3>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={2}>
                      <Card>
                        <div
                          style={{
                            padding: "15px",
                            background: "#1c4766",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#fff",
                          }}
                        >
                          <h3>Director </h3>
                          <h3>{directorcnt}</h3>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={5}>
                      <Card>
                        <div
                          style={{
                            padding: "15px",
                            background: "#1c4766",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#fff",
                          }}
                        >
                          <h3>De-Active Member </h3>
                          <h3>{deactiveMembercnt}</h3>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Stack>

                {memberlist && (
                  <>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={newMemeberList}
                        headers={expo_columns}
                        filename={"MemberList.csv"}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>

                    <Button
                      className="btn btn-export"
                      color="secondary"
                      variant="contained"
                    >
                      <CSVLink
                        data={newMemeberList}
                        headers={expo_columns_Additional}
                        filename={"MemberList With Additional Details.csv"}
                        target="_blank"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        Export Additional Info
                      </CSVLink>
                    </Button>
                    <DataTable
                      title="Member List"
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={newMemeberList}
                    />
                  </>
                )}
              </Card>
            </>
          )}
          {/* End Category Form */}
        </Container>
      </Page>
    </>
  );
}
