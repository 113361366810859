import Request from ".";
import { Routes } from "../constant/api_url";


const UpdateSetting = async (data) => {
      try {
        const result = await Request.post(Routes.UPDATESETTING ,data,{
            headers: {
              Accept: "application/json , */*",
              "Content-Type": "application/json"
            }
          });
        return result.data;
      } catch (error) {
        throw error;
      }
    };

    const getSetting = async () => {
        try {
          const result = await Request.get(Routes.GETESETTING);
          return result.data;
        } catch (error) {
          throw error;
        }
      };

     

      // const chapterSetting = async () => {
      //   try {
      //     const result = await Request.get(Routes.GETESETTING);
      //     return result.data;
      //   } catch (error) {
      //     throw error;
      //   }
      // };  

export  {UpdateSetting,getSetting };    