import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";

import {
  Box,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Page from "src/components/Page";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Select from "react-select";
import { GetMemberByChptId, getPinAssignReport } from "src/api/commonapi";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import { notification } from "src/utils/messages";
import {
  fetchPinList,
  fetchallcitylist,
  fetchchapterbyuser,
  getMembersDetailedReportHandler,
  getMembersSpecificDetailedReportHandler,
  getPinAssignReportHandler,
} from "src/utils/common";
import { BorderBottom, Download, Send } from "@mui/icons-material";
import moment from "moment";
// import { ExcelFile, ExcelSheet } from "react-data-export";

export default function PinAssignReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const memberActiveType = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "De-Active" },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "800px",
    width: "80%",
    maxWidth: "80%",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 0.5,
  };
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer", // override the row height
      },
    },
  };
  const TabsWrapperStyle = styled("div")(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: "100%",
    display: "flex",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      paddingRight: theme.spacing(3),
    },
  }));
  const [filter, setFilter] = useState({
    chpt_id: 0,
    m_id: 0,
    p_id: 0,
    startDate: "",
    endDate: "",
    mActive: -1,
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
  });
  const [cityList, setClityList] = useState([]);
  const [chapoption, setchptoption] = useState([]);

  const [loader, setLoader] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [memberFilterResult, setMemberFilterResult] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const toggleOpenDetailModal = () => setOpenModal(!openModal);
  const [memberDetailData, setMemberDetailData] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [achievementOptions, setAchievementOption] = useState([]);
  const [pinAssignData, setPinAssignData] = useState([]);
  const getmemberbychp = async (ch_id) => {
    // setselectedmember({ defaulselect: "", m_id: 0 });
    setMemberData(await GetMemberByChptId(ch_id));
  };

  useEffect(async () => {
    let res = await fetchallcitylist();
    let newCityList = [];
    res?.map((res) => {
      newCityList.push({ value: res.city_id, label: res.city_name });
    });
    setClityList(newCityList);

    setchptoption(
      await fetchchapterbyuser(loginUser.city_id, loginUser.u_type)
    );
  }, []);

  const restFilterSearch = () => {
    setFilter({
      chpt_id: 0,
      m_id: 0,
      p_id: 0,
      startDate: "",
      endDate: "",
      city_id:
        loginUser?.u_type == 1 ||
        (loginUser?.u_type == 2 &&
          loginUser?.p_user_id != 1 &&
          loginUser?.management_p == 1)
          ? loginUser?.city_id
          : 0,
    });
    setMemberData([]);
  };
  const getAchievementOptions = async () => {
    let res = await fetchPinList(1);
    setAchievementOption(res);
  };
  useEffect(() => {
    getAchievementOptions();
  }, []);

  const searchPinDetailsCounterReport = async () => {
    if (filter.city_id != 0 || filter.chpt_id != 0) {
      let resp = await getPinAssignReport(filter);
      if (resp.status == 1) {
        setPinAssignData(resp.data);
      } else {
        setPinAssignData([]);

        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
      }
    } else {
      setPinAssignData([]);
      let notify = notification({
        type: "error",
        message: "Please select City or Chapter",
      });
      notify();
    }
  };
  const expo_columns = [
    { label: "Member Name", key: "member_name" },
    { label: "Pin Name", key: "name" },
    { label: "Assign Date", key: "date" },
  ];
  const columns = [
    {
      name: "Member Name",
      cell: (row) => row.member_name,
      // width: "280px",

      sortable: true,
    },
    {
      name: "Pin Name",
      selector: (row) => row.name,
      width: "180px",
      sortable: true,
    },
    {
      name: "Assign Date",
      selector: (row) => row.date,
      // width: "180px",
      sortable: true,
    },
  ];

  return (
    <Page title="Chapter's Member All Report | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Pin Assign data
          </Typography>
        </Stack>
        <Card
          style={{
            padding: "25px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select City</label>
                <Select
                  menuPortalTarget={document.body}
                  isDisabled={
                    loginUser?.u_type == 1 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id != 1 &&
                      loginUser?.management_p == 1)
                      ? true
                      : false
                  }
                  value={
                    cityList &&
                    cityList?.map((option) => {
                      if (option.value == filter.city_id) {
                        return { value: option.value, label: option.label };
                      }
                    })
                  }
                  onChange={async (e) => {
                    setFilter({
                      ...filter,
                      city_id: e.value,
                      chpt_id: 0,
                    });
                  }}
                  options={cityList}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={0} style={{ marginBottom: "15px" }}>
                {/* <ChapterSelectDropDown
                  dropDownLabel={"Select Chapter"}
                  selectedState={filter}
                  chapterKey="chpt_id"
                  handleChange={(e) => {
                    setFilter({ ...filter, chpt_id: e.value, m_id: 0 });
                    getmemberbychp(e.value);
                  }}
                /> */}
                <label style={{ marginBottom: "10px" }}>Select Chapter</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    filter.chpt_id == 0
                      ? { value: 0, label: "All Chapter" }
                      : chapoption &&
                        chapoption?.map((c) => {
                          if (c.chpt_id == filter.chpt_id) {
                            return {
                              value: c.chpt_id,
                              label: c.chpt_name,
                            };
                          }
                        })
                  }
                  style={{ marginTop: "0px" }}
                  name="chpt_id"
                  onChange={(e) => {
                    setFilter({ ...filter, chpt_id: e.value, m_id: 0 });
                    getmemberbychp(e.value);
                  }}
                  options={[
                    {
                      value: 0,
                      label: "All Chapter",
                    },
                    ...(chapoption &&
                      chapoption
                        ?.map((chpt) => {
                          if (filter?.city_id != 0) {
                            if (chpt?.city_id == filter?.city_id) {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          } else {
                            return {
                              value: chpt.chpt_id,
                              label: chpt.chpt_name,
                            };
                          }
                        })
                        .filter((f) => f != undefined)),
                  ]}
                ></Select>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Pin</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    filter.p_id == 0
                      ? {
                          value: 0,
                          label: "All ",
                        }
                      : achievementOptions.map((option) => {
                          return (
                            filter.p_id == option.p_id && {
                              value: option.p_id,
                              label: option.name,
                            }
                          );
                        })
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, p_id: e.value });
                  }}
                  options={[
                    {
                      value: 0,
                      label: "All",
                    },
                    ...achievementOptions.map((option) => {
                      return { value: option.p_id, label: option.name };
                    }),
                  ]}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Member</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    // filter.m_id == 0
                    //   ? {
                    //       value: 0,
                    //       label: "All Members",
                    //     }
                    //   :
                    memberData.map((option) => {
                      return (
                        filter.m_id == option.m_id && {
                          value: option.value,
                          label: option.m_name,
                        }
                      );
                    })
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, m_id: e.value });
                  }}
                  options={[
                    // {
                    //   value: 0,
                    //   label: "All Members",
                    // },
                    ...memberData.map((option) => {
                      return { value: option.m_id, label: option.m_name };
                    }),
                  ]}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={0} style={{ marginTop: "15px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px", background: "yellow" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={
                      filter?.startDate != ""
                        ? moment(filter?.startDate, "DD-MM-YYYYY").format(
                            "MM-DD-YYYY"
                          )
                        : null
                    }
                    style={{ background: "red" }}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        startDate: moment(newDate, "DD-MM-YYYY").format(
                          "DD-MM-YYYY"
                        ),
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={0} style={{ marginTop: "15px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px", background: "yellow" }}
                >
                  <DatePicker
                    type="date"
                    name="endDate"
                    label="End Date "
                    style={{ background: "red" }}
                    inputFormat="dd/MM/yyyy"
                    value={
                      filter?.endDate != ""
                        ? moment(filter?.endDate, "DD-MM-YYYYY").format(
                            "MM-DD-YYYY"
                          )
                        : null
                    }
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        endDate: moment(newDate, "DD-MM-YYYY").format(
                          "DD-MM-YYYY"
                        ),
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>
                  Member Active Type
                </label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    memberActiveType &&
                    memberActiveType.map((option) => {
                      if (option.value == filter.mActive) {
                        return { value: option.value, label: option.label };
                      }
                    })
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, mActive: e.value });
                  }}
                  options={
                    memberActiveType &&
                    memberActiveType.map((option) => {
                      return { value: option.value, label: option.label };
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={searchPinDetailsCounterReport}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                onClick={restFilterSearch}
                style={{ marginLeft: "5px" }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Card
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
            marginTop: "25px",
          }}
        >
          <>
            <Button className="btn btn-export">
              <CSVLink
                data={pinAssignData}
                headers={expo_columns}
                filename={"Pin Assign Report.csv"}
                className="btn btn-primary test"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </Button>
            {/* <Button
              color="warning"
              variant="contained"
              className="btn btn-export"
              onClick={exportData}
              disabled={loading}
            >
              Member All Data Export
              {loading && <CircularProgress size={14} />}
            </Button> */}
            <DataTable
              // style={{ overflow: "hidden" }}
              filter
              pagination
              highlightOnHover
              progressPending={loader}
              columns={columns}
              // onRowClicked={(row) => (
              //   setActiveTab(1),
              //   handleClickRow(row),
              //   getMembersDetailReport(1, row.m_id),
              //   toggleOpenDetailModal()
              //   // ,
              //   // toggleOpenDetailModal()
              // )}
              data={pinAssignData}
              customStyles={customStyles}
            />
          </>
        </Card>

        {/* <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 15px",
                }}
              >
                <Typography variant="h6" align="center">
                  V2V
                </Typography>
                <Button
                  className="btn btn-export"
                  style={{ margin: "0", padding: "0" }}
                >
                  <CSVLink
                    data={""}
                    headers={""}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              </div>

              <div style={{ marginTop: "10px" }}>
                <DataTable
                  title={""}
                  style={{ overflow: "hidden" }}
                  filter
                  // progressPending={pending}
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={[]}
                />
              </div>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                marginTop: "25px",
              }}
            >
              xyz
            </Card>
          </Grid>
        </Grid> */}
      </Container>
    </Page>
  );
}
