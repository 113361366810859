import React, { Component, useState, useEffect } from "react";
// routes
import { AuthRouter, Router } from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "./Assets/css/style.css";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { fetchCityAmbDetailById } from "./utils/common";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function App() {
  const [user, setUserData] = useState(null);
  const navigate = useNavigate();

  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const restoreUser = async () => {
    const users = await localStorage.getItem("userData");
    if (users != null) {
      setUserData(users);
    }
  };

  // useEffect for login user password change or not for the city amb

  useEffect(async () => {
    let res = await fetchCityAmbDetailById(loginUser?.u_id);
    if (loginUser?.u_type == 1) {
      if (loginUser?.password != res?.password) {
        await localStorage.removeItem("userData");
        navigate("/");
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    restoreUser();
  }, []);

  return (
    <>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        {user == null ? <AuthRouter /> : <Router />}
      </ThemeConfig>
    </>
  );
}
