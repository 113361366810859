import React, { useState, useEffect } from "react";
import {
  Button,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import Logo from "../components/Logo";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import moment from "moment";
import { insertLead } from "src/api/commonapi";
import { notification } from "src/utils/messages";
import { Routes } from "../constant/api_url";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 556,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "30px 0",
}));

const LoginFormCard = {
  padding: "15px",
  margin: "auto",
  display: "block",
};
const Login_body = {
  margin: "15px",
};
const form_title = {
  margin: "15px 0",
  textAlign: "center",
};
// ----------------------------------------------------------------------

export default function InquiryForm() {
  let { c_id } = useParams();
  let City_amb_id = 1;
  let p_u_id = 0;
  let date = moment().format("DD-MM-YYYY");
  if (c_id != undefined) {
    City_amb_id = Buffer.from(c_id, "base64").toString("ascii");
    p_u_id = 1;
  }
  const [formdata, setformdata] = useState({
    l_created_by: City_amb_id,
    l_type: 0,
    l_allocated_to: City_amb_id,
    p_u_id: p_u_id,
    date: date,
    l_phase: 0,
    c_f: true,
  });

  const submitformdata = async () => {
    if (
      formdata.name == undefined ||
      formdata.name == "" ||
      formdata.l_business_name == undefined ||
      formdata.l_business_name == "" ||
      formdata.l_contact_no == undefined ||
      formdata.l_contact_no == "" ||
      formdata.l_business_category == undefined ||
      formdata.l_business_category == "" ||
      formdata.l_address == undefined ||
      formdata.l_address == ""
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      let resp = await insertLead(JSON.stringify(formdata));
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setformdata({
          l_created_by: City_amb_id,
          l_type: 0,
          l_allocated_to: City_amb_id,
          p_u_id: p_u_id,
          date: date,
          l_phase: 0,
          name: "",
          l_business_name: "",
          l_contact_no: "",
          l_business_category: "",
          l_address: "",
          l_email: "",
          gst_no: "",
        });
      }
    }
  };

  const onChangeFunction = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };
  return (
    <Page title="Inquiry Form | Local Vocal Business Group">
      <Container>
        <ContentStyle>
          <Card className="LoginFormCard" style={LoginFormCard}>
            <Box
              component="img"
              // src="/static/logolvb.png"
              src={Routes.API_HOSTNAME + "logo/LVB-Logo.png"}
              sx={{ display: "block", margin: "auto", height: "100px" }}
            />
            {/* <Logo /> */}
            <h4 className="form_title" style={form_title}>
              BOOK FREE CONSULTING SESSIONS TODAY FROM OUR EXPERTS
            </h4>

            <div className="Login_body" style={Login_body}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Name"
                      name="name"
                      value={formdata?.name}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Contact Number"
                      name="l_contact_no"
                      value={formdata?.l_contact_no}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Email Id"
                      name="l_email"
                      value={formdata?.l_email}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Address"
                      name="l_address"
                      value={formdata?.l_address}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Business Name"
                      name="l_business_name"
                      value={formdata?.l_business_name}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Business Category"
                      name="l_business_category"
                      value={formdata?.l_business_category}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="GST No"
                      name="gst_no"
                      value={formdata?.gst_no}
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button variant="contained" onClick={submitformdata}>
                Submit
              </Button>
            </div>
          </Card>
        </ContentStyle>
      </Container>
    </Page>
  );
}
