import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { fetchchapterbyuser, fetchAttendanceByMember } from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { GetMemberByChptId } from "src/api/commonapi";
import { CSVLink } from "react-csv";
import moment from "moment";
import Label from "src/components/Label";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function AttendaceReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [nattendanceData, setnAttendanceData] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [filter, setFilter] = useState({
    chpt_id: 0,
  });
  const [filtereddata, setFilteredData] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getattendancelist = async (ch_id, m_id) => {
    setAttendanceData(await fetchAttendanceByMember(m_id));
  };

  useEffect(async () => {
    getchptlist();
  }, []);

  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Member Name", key: "member_name" },
    { label: "Meeting Date", key: "date" },
    { label: "Attendance", key: "attend_type" },
  ];

  const filterData = (start, end) => {
    let nattendance = [];

    nattendance =
      attendanceData &&
      attendanceData.filter((atted) => {
        if (
          moment(atted.date, "DD-MM-YYYY").isBetween(
            start,
            end,
            "days",
            "[]"
          ) ||
          (start == "" && end == "")
        ) {
          return atted;
        }
      });

    setnAttendanceData(nattendance);
  };

  useEffect(async () => {
    if (
      filtereddata.startDate != undefined ||
      filtereddata.endDate != undefined
    ) {
      await filterData("", "");
    }
  }, [attendanceData]);

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.row_no,
      sortable: true,
      width: "80px",
    },
    // {
    //   name: "Invite By",
    //   selector: (row) => row.v_by_name + " (" + row.member_Business + ")",
    //   sortable: true
    // },
    {
      name: "Member Name",
      wrap: true,
      selector: (row) => row.member_name,
      sortable: true,
    },
    // {
    //   name: "Business",
    //   selector: (row) => row.n_m_business_name,
    //   sortable: true
    // },
    // {
    //   name: "Contact Number",
    //   selector: (row) => row.n_m_mo_no,
    //   sortable: true
    // },
    // {
    //   name: "Business Category",
    //   selector: (row) => row.n_m_business_category,
    //   sortable: true
    // },
    {
      name: "Meeting Date",
      wrap: true,
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Attendance",
      wrap: true,
      selector: (row) => (
        <>
          <Label
            variant="ghost"
            color={row.status == 0 ? "success" : "error"}
            style={{
              marginBottom: "10px",
              display: "flex",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            {row.attend_type}
          </Label>
        </>
      ),
      sortable: true,
    },
  ];
  return (
    <>
      <Page title="Attendance Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Attendance Report
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <ChapterSelectDropDown
                  dropDownLabel={"Select Chapter"}
                  selectedState={filter}
                  chapterKey="chpt_id"
                  handleChange={(e) => {
                    setFilter({ ...filter, chpt_id: e.value });
                    getmemberbychp(e.value);
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Member</label>
                <Select
                  value={selectedmember.defaulselect}
                  onChange={(e) => {
                    setselectedmember({ defaulselect: e, m_id: e.value });
                    getattendancelist(filter.chpt_id, e.value);
                  }}
                  options={
                    memberdata &&
                    memberdata.map((option) => {
                      return { value: option.m_id, label: option.m_name };
                    })
                  }
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={
                      filtereddata.startDate != ""
                        ? filtereddata?.startDate
                        : null
                    }
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filtereddata,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="enddate"
                    label="End Date "
                    value={
                      filtereddata?.endDate != "" ? filtereddata?.endDate : null
                    }
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filtereddata,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    filterData(filtereddata?.startDate, filtereddata?.endDate);
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilteredData({ startDate: null, endDate: null });
                    filterData("", "");
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {nattendanceData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nattendanceData}
                    headers={expo_columns}
                    filename={
                      "Visitor Data" + moment().format("DD-MM-YYYY") + ".csv"
                    }
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nattendanceData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
