import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import moment from "moment";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden"
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const footerStyle = {
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "45px",
  width: "100%",
  display: "flex",
  padding: "15px",
  backgroundColor: "#1c4766",
  color: "#fff"
};
const footerSection = {
  textAlign: "center",
  fontSize: "0.875rem",
  padding: "10 15px",
  fontFamily: "Public Sans,sans-serif",
  color: "#fff"
};
const Footer_a = {
  textDecoration: "none",
  color: "#fff"
};
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle style={{ position: "relative" }}>
        <Outlet />
        <footer style={footerStyle}>
          <div className="copyright text-center" style={footerSection}>
            © Copyright {moment().format("YYYY")} | Local Vocal Business Group | Created By
            <strong>
              &nbsp;&nbsp;
              <a style={Footer_a} target="_blank" href="https://truelinesolution.com/">
                Trueline Solution
              </a>
            </strong>
            . All Rights Reserved
          </div>
        </footer>
      </MainStyle>
    </RootStyle>
  );
}
