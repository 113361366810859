import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { useTable } from "react-table";
import Label from "../components/Label";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
// components
import Page from "../components/Page";
// StateData
import { StateForm } from "../components/Form/State";
import { Routes } from "src/constant/api_url";
import { fetchallstatelist } from "src/utils/common";
import { UpdateStateStatus } from "src/api/commonapi";
import { notification } from "src/utils/messages";
import { InsertState } from "src/api/commonapi";
import {
  DeleteSharp,
  CalendarMonth,
  EditSharp,
  PersonAddAlt1,
  AssignmentInd,
} from "@mui/icons-material";
import { updateState } from "src/api/commonapi";
// ----------------------------------------------------------------------

export default function State() {
  const [step, setStep] = useState(1);

  const [statelist, setstatelist] = useState([]);
  const [newstatelist, setnewstatelist] = useState([]);
  const [editState, setEditStateData] = useState(null);
  const [filterstate, setfilterstate] = useState("");
  const [getdata, setgetdata] = useState(null);
  const onChangeFunction = (e) => {
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    let resp = null;
    if (step == 1) {
      if (getdata.state_name != null) {
        resp = await InsertState(JSON.stringify(getdata));
        setgetdata(null);
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      }
    } else if (step == 2) {
      if (getdata.state_name != null) {
        resp = await updateState(JSON.stringify(getdata));
        setgetdata(null);
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      }
    }
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    getstatelist();
  };

  const changestatus = async (state_id, s_status) => {
    let fdata = new FormData();
    fdata.append("state_id", state_id);
    fdata.append("s_status", s_status);

    const resp = await UpdateStateStatus(fdata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    getstatelist();
  };

  const filterData = () => {
    let nstate = [];
    nstate =
      statelist &&
      statelist.filter((state) => {
        if (
          state.state_name.toLowerCase().includes(filterstate?.toLowerCase())
        ) {
          return state;
        }
      });
    setnewstatelist(nstate);
  };

  useEffect(async () => {
    if (filterstate != undefined) {
      await filterData();
    }
  }, [filterstate, statelist]);

  const getstatelist = async () => {
    setstatelist(await fetchallstatelist());
  };

  useEffect(async () => {
    getstatelist();
  }, []);

  const exportColumns = [
    { label: "No", key: "state_id" },
    { label: "State Name", key: "state_name" },
  ];
  const columns = [
    {
      name: "No",
      wrap: true,
      label: "No",
      key: "state_id",
      selector: (row) => row.state_id,
      sortable: true,
    },
    {
      name: "State Name",
      wrap: true,
      label: "State Name",
      key: "state_name",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Status",
      wrap: true,
      selector: (row) => (
        <>
          {row.s_status == 0 ? (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="success"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  Active
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.state_id, 1);
                  }}
                  style={{ background: "#ff4842" }}
                >
                  Deactive
                </Button>
              </div>
            </>
          ) : (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="error"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Deactive
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.state_id, 0);
                  }}
                >
                  Active
                </Button>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      wrap: true,
      sortable: true,
      cell: (row) => (
        <>
          <a
            onClick={async () => {
              setgetdata(row);
              setStep(2);
            }}
          >
            <Tooltip title="Edit Chapter">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];
  return (
    <Page title="State | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            State
          </Typography>
        </Stack>

        {/* Start State Form */}
        <Card style={{ padding: "25px", marginBottom: "25px" }}>
          {getdata == null ? (
            ""
          ) : (
            <label style={{ marginBottom: "15px" }}>Enter State Name</label>
          )}
          <Stack spacing={3}>
            <TextField
              onChange={onChangeFunction}
              fullWidth
              value={getdata?.state_name}
              autoComplete="false"
              type="text"
              name="state_name"
              label={getdata == null ? "Enter State Name" : ""}
            />
          </Stack>
          <Button
            onClick={handleSubmit}
            style={{ marginTop: "15px" }}
            type="submit"
            variant="contained"
          >
            Add State
          </Button>
        </Card>
        {/* End State Form */}
        <Card style={{ padding: "25px" }}>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <label> Search State </label>
              <TextField
                style={{ marginTop: "5px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Search State"
                name="search"
                value={filterstate}
                onChange={(e) => {
                  setfilterstate(e.target.value);
                }}
              />
            </Stack>
          </Grid>
          {statelist && (
            <>
              <Button className="btn btn-export">
                <CSVLink
                  data={newstatelist}
                  headers={exportColumns}
                  filename={"State List.csv"}
                  className="btn btn-primary test"
                  target="_blank"
                >
                  Export CSV
                </CSVLink>
              </Button>
              <DataTable
                title="State List"
                filter
                pagination
                highlightOnHover
                columns={columns}
                data={newstatelist}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
