import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { notification } from '../../../components/Notification/notification';
// material
import { Stack, Container, TextField, Button } from "@mui/material";
import { insertcountry } from "src/api/commonapi";

import { notification } from "src/utils/messages";

// ----------------------------------------------------------------------

export default function CountryForm() {
  const notify = () => toast("Wow so easy!");
  const navigate = useNavigate();
  const CountySchema = Yup.object().shape({
    countryname: Yup.string().required("Country Name is required")
  });
  // let inputemail ="";
  // let inputpassword ="";
  const formik = useFormik({
    initialValues: {
      countryname: ""
    },
    validationSchema: CountySchema,

    onSubmit: async (e) => {
      const inputemail = e.countryname;

      const logindata = {
        country_name: inputemail
      };
      const jslogindata = JSON.stringify(logindata);
      const resp = await insertcountry(jslogindata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message
      });
      notify();
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoCorrect="false"
              autoComplete=""
              type="text"
              label="Enter Country Name"
              {...getFieldProps("countryname")}
              error={Boolean(touched.countryname && errors.countryname)}
              helperText={touched.countryname && errors.countryname}
            />
          </Stack>

          <Button style={{ marginTop: "15px" }} type="submit" variant="contained">
            Add Country
          </Button>
        </Form>
      </FormikProvider>
    </Container>
  );
}
