import Request from ".";
import { Routes } from "../constant/api_url";

const insertmember = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTMEMBER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getChapter = async () => {
  try {
    const result = await Request.get(Routes.ALLCHAPTER);
    return result;
  } catch (error) {
    throw error;
  }
};

const UpdateMemberStatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEMEMBERSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const InsertState = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTSTATE, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateMemberPost = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEMEMBERPOST, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateMeeting = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEMEETING, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateMember = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEMEMBER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const userLogout = async (data) => {
  try {
    const result = await Request.get(Routes.USERLOGOUT + "/" + data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const GetMemberById = async (id) => {
  try {
    const result = await Request.get(Routes.GETMEMBERBYID + "?memberid=" + id);
    return result;
  } catch (error) {
    throw error;
  }
};

const GetMemberByChptId = async (id) => {
  try {
    const result = await Request.get(Routes.GETMEMBERBYCHPT + "/" + id);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};
const GetMeetingById = async (id) => {
  try {
    const result = await Request.get(Routes.GETMEETINGBYID + "/" + id);
    return result.data[0];
  } catch (error) {
    throw error;
  }
};

const getState = async () => {
  try {
    const result = await Request.get(Routes.GETSTATE);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getCity = async (id) => {
  try {
    const result = await Request.get(Routes.GETCITY + "?stateid=" + id);
    return result.data;
  } catch (error) {
    throw error;
  }
};
const GetAllUserApi = async (id) => {
  try {
    const result = await Request.get(Routes.GETALLUSER);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const GetAllMemberApi = async (city_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETALLMEMBER + "/" + city_id + "/" + u_type
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const InsertMemberApi = async () => {
  try {
  } catch (error) {}
};

const getPollSelect = async (type, userType, cityId) => {
  try {
    const result = await Request.get(
      Routes.GETPOLLSELECT + "/" + type + "/" + userType + "/" + cityId
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertpoll = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTPOLL, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const insertevent = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTEVENT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertgreeting = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTGREETING, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const insertgallery = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTGALLERY, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllGallery = async () => {
  try {
    const result = await Request.get(Routes.GETALLGALLERY);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deleteGallery = async (g_id) => {
  try {
    const result = await Request.get(Routes.DELETEGALLERY + "/" + g_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllPoll = async (city_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETALLPOLL + "/" + city_id + "/" + u_type
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deletePoll = async (p_id) => {
  try {
    const result = await Request.get(Routes.DELETEPOLL + "/" + p_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllEventAnnou = async (city_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETALLEVENTANNO + "/" + city_id + "/" + u_type
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getAllGreeting = async () => {
  try {
    const result = await Request.get(Routes.GETALLGREETING);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const EventAnnouDelete = async (e_id) => {
  try {
    const result = await Request.get(Routes.EVENTANNOUDELETE + "/" + e_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};
const getpollresult = async (p_id) => {
  try {
    const result = await Request.get(Routes.GETPOLLRESULT + "/" + p_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllCity = async () => {
  try {
    const result = await Request.get(Routes.GETALLCITY);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllState = async (id) => {
  try {
    const result = await Request.get(Routes.GETALLSTATE);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllCountry = async (id) => {
  try {
    const result = await Request.get(Routes.GETALLCOUNTRY);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertcity = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTCITY, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertcountry = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTCOUNTRY, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertlandmark = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTLANDMARK, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertmeeting = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTMEETING, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllLandmark = async (id) => {
  try {
    const result = await Request.get(Routes.GETALLLANDMARK);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateLandmarkStatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATELANDMARKSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateStateStatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATESTATESTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateCityStatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATECITYSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllChapter = async () => {
  try {
    const result = await Request.get(Routes.GETALLCHAPTER);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllBusiness = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.GETALLBUSINESS + "/" + ch_id + "/" + m_id
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const UpdateBamount = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEBAMOUNT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateState = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATESTATE, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const updatecity = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATECITY, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const updatelandmark = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATELANDMARK, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deleteBusiness = async (b_id) => {
  try {
    const result = await Request.get(Routes.DELETEBUSINESS + "/" + b_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllV2v = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.GETV2VLIST + "/" + ch_id + "/" + m_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteV2v = async (v2v_id) => {
  try {
    const result = await Request.get(Routes.DELETEV2V + "/" + v2v_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllvisitor = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.GETVISITORBYID + "/" + ch_id + "/" + m_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteVisitorData = async (id) => {
  try {
    const result = await Request.get(Routes.DELETEVISITOR + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllTestimonial = async (m_id) => {
  try {
    const result = await Request.get(Routes.GETTESTIMONIALLIST + "/" + m_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteTestimonialData = async (id) => {
  try {
    const result = await Request.get(Routes.DELETETESTIMONIAL + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteMeeting = async (m_id) => {
  try {
    const result = await Request.get(Routes.DELETEMEETING + "/" + m_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAllReference = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.GETREFERENCELIST + "/" + ch_id + "/" + m_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteReference = async (r_id) => {
  try {
    const result = await Request.get(Routes.DELETEREFERENCE + "/" + r_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteChapter = async (chpt_id, u_id) => {
  try {
    const result = await Request.get(
      Routes.DELETECHAPTER + "/" + chpt_id + "/" + u_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const resetMeetingMember = async (m_id) => {
  try {
    const result = await Request.get(Routes.RESETMEETINGMEMBER + "/" + m_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const GetStateById = async (id) => {
  try {
    const result = await Request.get(Routes.GETSTATEBYID + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getattendance = async (id) => {
  try {
    const result = await Request.get(Routes.GETATTENDANCE + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateEventAnnouncement = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEEVENTANNOUNCEMENT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getMemberAttendanceByChepter = async (data) => {
  try {
    const result = await Request.post(
      Routes.GETMEMBERATTENDANCEBYCHEPER,
      data,
      {
        headers: {
          Accept: "application/json , */*",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getMemberAttendanceById = async (data) => {
  try {
    const result = await Request.post(Routes.GETMEMBERATTENDANCEBYID, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const GreetingDelete = async (g_id) => {
  try {
    const result = await Request.get(Routes.GREETINGDELETE + "/" + g_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const ResendGreeting = async (g_id) => {
  try {
    const result = await Request.get(Routes.RESENDGREETING + "/" + g_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getChapterByCity = async (city_id) => {
  try {
    const result = await Request.get(Routes.GETCHAPTERBYCITY + "/" + city_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertcityambassador = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTCITYAMBASSODAR, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getCityAmbassadorById = async (member_id) => {
  try {
    const result = await Request.get(
      Routes.GETCITYAMBASSADORBYID + "/" + member_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const GetActiveMemberByChpt = async (id) => {
  try {
    const result = await Request.get(Routes.GETACTIVEMEMBERBYCHPT + "/" + id);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

const updateCityAmbssadorDetails = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATECITYAMBSSADORDEATILS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertStaff = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTSTAFF, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const GetStaffByPid = async (id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETSTAFFBYPID + "/" + id + "/" + u_type
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateStaffStatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATESTAFFSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deleteStaff = async (data) => {
  try {
    const result = await Request.post(Routes.DELETESTAFF, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateStaffDetails = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATESTAFFDETAILS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllocateListById = async (u_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETALLOCATELISTBYID + "/" + u_id + "/" + u_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getStaffById = async (u_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETSTAFFBYPID + "/" + u_id + "/" + u_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertLead = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTLEAD, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertTask = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTTASK, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const updatetaskstatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATETASKSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateleadstatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATELEADSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getTaskList = async (u_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETTASKLIST + "/" + u_id + "/" + u_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getLeadList = async (u_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETLEADLIST + "/" + u_id + "/" + u_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getTaskByTid = async (t_id) => {
  try {
    const result = await Request.get(Routes.GETTASKBYTID + "/" + t_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updatetaskdetails = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATETASKDETAILS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deleteTask = async (t_id) => {
  try {
    const result = await Request.get(Routes.DELETETASK + "/" + t_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};
const updateTaskPrority = async (t_id, t_priority) => {
  try {
    const result = await Request.get(
      Routes.UPDATETASKPRIORITY + "/" + t_id + "/" + t_priority
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getLeadByLid = async (l_id) => {
  try {
    const result = await Request.get(Routes.GETLEADBYLID + "/" + l_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateleaddetails = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATELEADDETAILS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertFollowUp = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTFOLLOWUP, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getLeadHistory = async (l_id) => {
  try {
    const result = await Request.get(Routes.GETLEADHISTORY + "/" + l_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const leadTransferTO = async (data) => {
  try {
    const result = await Request.post(Routes.LEADTRANSFERTO, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const uploadPaymentImage = async (data) => {
  try {
    const result = await Request.post(Routes.UPLOADPAYMENTIMAGE, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getChapterByUser = async (city_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETCHAPTERBYUSER + "/" + city_id + "/" + u_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getMeetingByUser = async (city_id, u_type) => {
  try {
    const result = await Request.get(
      Routes.GETMEETINGBYUSER + "/" + city_id + "/" + u_type
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const insertNewcityambassador = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTNEWCITYAMBASSADOR, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getAllCityAmbassador = async () => {
  try {
    const result = await Request.get(Routes.GETALLCITYAMABASSADOR);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteCityAmbassador = async (u_id) => {
  try {
    const result = await Request.get(Routes.DELETECITYAMBASSADOR + "/" + u_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const paymentWithdrawal = async (data) => {
  try {
    const result = await Request.post(Routes.PAYMENTWITHDRAWAL, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getRenewalMemberData = async (l_id) => {
  try {
    const result = await Request.get(Routes.GETRENEWALMEMBERDATA + "/" + l_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const memberRenewal = async (data) => {
  try {
    const result = await Request.post(Routes.MEMBERRENEWAL, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const changeCityAmbassador = async (data) => {
  try {
    const result = await Request.post(Routes.CHANGECITYAMBASSADOR, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getDashboardCounter = async (u_id, u_type, city_id) => {
  try {
    if (u_id != undefined || u_type != undefined || city_id != undefined) {
      const result = await Request.get(
        Routes.GETDASHBOARDCOUNTER + "/" + u_id + "/" + u_type + "/" + city_id
      );
      return result?.data;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

const getWalletHistoryById = async (c_a_id) => {
  try {
    const result = await Request.get(
      Routes.GETWALLETHISTORYBYID + "/" + c_a_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getMemberFeeHistory = async (m_id) => {
  try {
    const result = await Request.get(Routes.GETMEMBERFEEHISTORY + "/" + m_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const uploadLvbDocument = async (data) => {
  try {
    const result = await Request.post(Routes.UPLOADLVBDOCUMENT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getLvbDocument = async (city_id) => {
  try {
    const result = await Request.get(Routes.GETLVBDOCUMENT + "/" + city_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteDocuement = async (d_id) => {
  try {
    const result = await Request.get(Routes.DELETEDOCUEMENT + "/" + d_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const updateCityAmbassadorProfile = async (data) => {
  try {
    const result = await Request.post(
      Routes.UPDATECITYAMBASSADORPROFILE,
      data,
      {
        headers: {
          Accept: "application/json , */*",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertFolder = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTFOLDER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getFolderByCityId = async (city_id) => {
  try {
    const result = await Request.get(Routes.GETFOLDERBYCITYID + "/" + city_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getDocumentByFid = async (f_id) => {
  try {
    const result = await Request.get(Routes.GETDOCUMENTBYFID + "/" + f_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const deleteSubFolder = async (f_id) => {
  try {
    const result = await Request.get(Routes.DELETESUBFOLDER + "/" + f_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getV2vByMemberReport = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.V2VREPORTBYMEMBER + "/" + ch_id + "/" + m_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getBusinessByMemberReport = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.BUSINESSREPORTBYMEMBER + "/" + ch_id + "/" + m_id
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getReferenceByMemberReport = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.GETREFERENCEREPORTBYMEMBER + "/" + ch_id + "/" + m_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getVisitorByMemberReport = async (ch_id, m_id) => {
  try {
    const result = await Request.get(
      Routes.GETVISITORREPORTBYMEMBER + "/" + ch_id + "/" + m_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getAttendanceByMemberReport = async (m_id) => {
  try {
    const result = await Request.get(Routes.GETATTENDANCEREPORT + "/" + m_id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getLvbCounterWeb = async (id) => {
  try {
    const result = await Request.get(Routes.GETLVBCOUNTERWEB + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getlvbsearchcounter = async (data) => {
  try {
    const result = await Request.post(Routes.GETLVBSEARCHCOUNTER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getCityCounterWeb = async (id) => {
  try {
    const result = await Request.get(Routes.GETLVBCOUNTERWEB + "/" + id);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getcitysearchcounter = async (data) => {
  try {
    const result = await Request.post(Routes.GETCITYSEARCHCOUNTER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getcitychaptersearchcounter = async (data) => {
  try {
    const result = await Request.post(
      Routes.GETCITYCHAPTERSEARCHCOUNTER,
      data,
      {
        headers: {
          Accept: "application/json , */*",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getcityambdetailbyid = async (id) => {
  try {
    if (id != undefined) {
      const result = await Request.get(Routes.GETCITYAMBDETAILBYID + "/" + id);
      return result?.data;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

const findTopper = async (data) => {
  try {
    const result = await Request.post(Routes.FINDTOPPER, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const findChaptersMemberData = async (data) => {
  try {
    const result = await Request.post(Routes.CHAPTERSMEMBERDATA, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

// PIN MODULES DATE : 19-07-2023
const insertpinBadge = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTPINBADGE, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getPinBadge = async (type) => {
  try {
    const result = await Request.get(Routes.GETPINLIST + "/" + type);
    return result?.data;
  } catch (error) {
    throw error;
  }
};
const updatePinFunction = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEPIN, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const assginPinFunction = async (data) => {
  try {
    const result = await Request.post(Routes.ASSIGNPIN, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getPinByMemberId = async (id) => {
  try {
    const result = await Request.get(Routes.GETPINBYMEMBERID + "/" + id);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateMemberVerificationStatus = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATEMEMBERVERIFYSTATUS, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getMembersDetailedReport = async (data) => {
  try {
    const result = await Request.post(Routes.GETMEMBERSDETAILREPORT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getMembersSpecificDetailedReport = async (data) => {
  try {
    const result = await Request.post(
      Routes.GETMEMBERSPECIFICDETAILREPORT,
      data,
      {
        headers: {
          Accept: "application/json , */*",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const insertBlackListVisitorFunction = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTBLACKLISTVISITOR, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const fetchBlockVisitorList = async (city_id) => {
  try {
    const result = await Request.get(
      Routes.GETBLOCKVISITORLIST + "/" + city_id
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const fetchLeadDetailsById = async (id) => {
  try {
    const result = await Request.get(Routes.GETLEADDETAILBYID + "/" + id);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updateLeadMemberForm = async (data) => {
  try {
    const result = await Request.post(Routes.UPDATELVBLEAD, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const InsertPublicLeadMemberForm = async (data) => {
  try {
    const result = await Request.post(Routes.INSERTPUBLICLEADFORM, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const getSuggestionList = async (data) => {
  try {
    const result = await Request.post(Routes.GETSUGGESTIONLIST, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const updatePublicLeadFormEditMode = async (data) => {
  try {
    const result = await Request.post(
      Routes.UPDATEPUBLICLEADFORMEDITMODE,
      data,
      {
        headers: {
          Accept: "application/json , */*",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getMemberInductionList = async (data) => {
  try {
    const result = await Request.post(Routes.GETMEMBERINDUCTIONLIST, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const exportMemberSpecificData = async (data) => {
  try {
    const result = await Request.post(
      Routes.EXPORTMEMBERALLSPECIFICDATA,
      data,
      {
        headers: {
          Accept: "application/json , */*",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
const thankYouNoteApprovalData = async (city_id) => {
  try {
    try {
      const result = await Request.get(
        Routes.THANKYOUNOTEWAITINGLIST + "/" + city_id
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  } catch (error) {}
};

const approvedThankyouNoteRequest = async (id) => {
  try {
    const result = await Request.get(Routes.APPROVETHANKYOUNOTE + "/" + id);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deleteThankyouNoteRequest = async (id) => {
  try {
    const result = await Request.get(
      Routes.DELETEUNAPPROVEDTHANKYOUNOTE + "/" + id
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getNewLeadList = async (data) => {
  try {
    const result = await Request.post(Routes.GETNEWLEADLIST, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getNewLeadDataForExport = async (data) => {
  try {
    const result = await Request.post(Routes.GETNEWLEADFOREXPORT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getPinAssignReport = async (data) => {
  try {
    const result = await Request.post(Routes.PINASSIGNREPORT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const exportCityChapterReport = async (data) => {
  try {
    const result = await Request.post(Routes.EXPORTCITYCHAPTERREPORT, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
const deleteVisitorBlockList = async (g_id) => {
  try {
    const result = await Request.get(
      Routes.DELETEVISITORBLOCKLIST + "/" + g_id
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const getBlockListVisitorListByMobile = async (data) => {
  try {
    const result = await Request.post(Routes.GETVISITORPREVIOUSELIST, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const checkMemberValidation = async (data) => {
  try {
    const result = await Request.post(Routes.CHECKMOBILEVALIDATION, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const chapterReActivation = async (data) => {
  try {
    const result = await Request.post(Routes.CHAPTERREACTIVE, data, {
      headers: {
        Accept: "application/json , */*",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getChapterActivityLog = async (id) => {
  try {
    const result = await Request.get(Routes.CHAPTERLOGHISTORY + "/" + id);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export {
  insertmember,
  getState,
  getCity,
  GetAllUserApi,
  GetAllMemberApi,
  GetMemberById,
  UpdateMember,
  getPollSelect,
  insertpoll,
  insertevent,
  insertgallery,
  getAllGallery,
  deleteGallery,
  getAllPoll,
  deletePoll,
  getAllEventAnnou,
  EventAnnouDelete,
  UpdateMemberStatus,
  userLogout,
  GetMemberByChptId,
  UpdateMemberPost,
  getpollresult,
  getAllCity,
  getAllState,
  getAllCountry,
  insertcity,
  insertcountry,
  insertlandmark,
  insertmeeting,
  InsertState,
  getAllLandmark,
  UpdateLandmarkStatus,
  UpdateStateStatus,
  UpdateCityStatus,
  GetMeetingById,
  UpdateMeeting,
  getAllChapter,
  getAllBusiness,
  UpdateBamount,
  deleteBusiness,
  getAllV2v,
  deleteV2v,
  getAllReference,
  deleteReference,
  deleteChapter,
  resetMeetingMember,
  GetStateById,
  updateState,
  updatecity,
  updatelandmark,
  deleteMeeting,
  getattendance,
  updateEventAnnouncement,
  getMemberAttendanceByChepter,
  getMemberAttendanceById,
  insertgreeting,
  getAllGreeting,
  GreetingDelete,
  ResendGreeting,
  getChapterByCity,
  insertcityambassador,
  getCityAmbassadorById,
  GetActiveMemberByChpt,
  updateCityAmbssadorDetails,
  insertStaff,
  GetStaffByPid,
  updateStaffStatus,
  deleteStaff,
  updateStaffDetails,
  getAllocateListById,
  insertLead,
  // Jil
  getStaffById,
  insertTask,
  getTaskList,
  getLeadList,
  updatetaskstatus,
  updateleadstatus,
  getTaskByTid,
  updatetaskdetails,
  deleteTask,
  updateTaskPrority,
  getLeadByLid,
  updateleaddetails,
  insertFollowUp,
  getLeadHistory,
  leadTransferTO,
  uploadPaymentImage,
  getChapterByUser,
  getMeetingByUser,
  insertNewcityambassador,
  getAllCityAmbassador,
  deleteCityAmbassador,
  paymentWithdrawal,
  getRenewalMemberData,
  memberRenewal,
  changeCityAmbassador,
  getDashboardCounter,
  getWalletHistoryById,
  getMemberFeeHistory,
  uploadLvbDocument,
  getLvbDocument,
  deleteDocuement,
  updateCityAmbassadorProfile,
  insertFolder,
  getFolderByCityId,
  getDocumentByFid,
  deleteSubFolder,
  getAllvisitor,
  deleteVisitorData,
  getAllTestimonial,
  deleteTestimonialData,
  getV2vByMemberReport,
  getBusinessByMemberReport,
  getReferenceByMemberReport,
  getVisitorByMemberReport,
  getAttendanceByMemberReport,
  getLvbCounterWeb,
  getlvbsearchcounter,
  getcitysearchcounter,
  getcitychaptersearchcounter,
  getcityambdetailbyid,
  findTopper,
  findChaptersMemberData,
  insertpinBadge,
  getPinBadge,
  updatePinFunction,
  assginPinFunction,
  getPinByMemberId,
  updateMemberVerificationStatus,
  getMembersDetailedReport,
  getMembersSpecificDetailedReport,
  insertBlackListVisitorFunction,
  fetchBlockVisitorList,
  fetchLeadDetailsById,
  updateLeadMemberForm,
  getSuggestionList,
  updatePublicLeadFormEditMode,
  getMemberInductionList,
  InsertPublicLeadMemberForm,
  exportMemberSpecificData,
  thankYouNoteApprovalData,
  approvedThankyouNoteRequest,
  deleteThankyouNoteRequest,
  getNewLeadList,
  getNewLeadDataForExport,
  getPinAssignReport,
  exportCityChapterReport,
  deleteVisitorBlockList,
  getBlockListVisitorListByMobile,
  checkMemberValidation,
  chapterReActivation,
  getChapterActivityLog,
};
