import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { saveAs } from "file-saver";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  Icon,
} from "@mui/material";

import Page from "../components/Page";
import { useParams, useNavigate } from "react-router-dom";
import { Routes } from "../constant/api_url";
import { fetchwallethistorybyid } from "src/utils/common";
import {
  AddCircleRounded,
  CancelRounded,
  CheckCircleRounded,
  DownloadSharp,
  RemoveCircleRounded,
  RowingRounded,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";

export default function WalletHistory() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  let navigate = useNavigate();
  let { c_a_id } = useParams();
  const [walletHistory, setWalletHistory] = useState([]);

  useEffect(async () => {
    await getwalletrhistory();
  }, []);

  const getwalletrhistory = async () => {
    setWalletHistory(await fetchwallethistorybyid(c_a_id));
  };

  const clickofback = async () => {
    if (loginUser?.u_type == 0) {
      navigate("/cityambassador", { replace: true });
    } else {
      navigate("/dashboard", { replace: true });
    }
  };
  const expo_columns = [
    { label: "No", key: "w_h_id" },
    { label: "Member", key: "m_name" },
    { label: "Date", key: "date" },
    { label: "City Ambassador", key: "c_a_name" },
    { label: " Amount", key: "amount" },
    { label: "Note", key: "note" },
    { label: "In/Out", key: "type" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.w_h_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Member",
      wrap: true,
      selector: (row) => row.m_name,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "City Ambassador",
      wrap: true,
      selector: (row) => row.c_a_name,
    },
    {
      name: "Amount",
      wrap: true,
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Note",
      wrap: true,
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "In/Out",
      wrap: true,
      cell: (row) => (
        <>
          <Icon>
            {row.type == 0 ? (
              <AddCircleRounded color="success" />
            ) : (
              <RemoveCircleRounded color="error" />
            )}
          </Icon>
          {row.payment_screenshot != null && (
            <a
              onClick={() => {
                saveAs(
                  `${Routes.API_HOSTNAME}images/cityambassadorpayment/${row.payment_screenshot}`,
                  `${row.payment_screenshot}`
                );
              }}
            >
              <Tooltip title="Download Screenshot">
                <IconButton>
                  <DownloadSharp color="info" />
                </IconButton>
              </Tooltip>
            </a>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Page title="Wallet History | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Wallet History
            </Typography>
          </Stack>
          <Button
            onClick={() => {
              clickofback();
            }}
            style={{ marginBottom: "15px" }}
            variant="contained"
          >
            Back
          </Button>
          <Card>
            <Button className="btn btn-export">
              <CSVLink
                data={walletHistory}
                headers={expo_columns}
                filename={"WalletHistory.csv"}
                className="btn btn-primary test"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </Button>
            <DataTable
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={walletHistory}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
