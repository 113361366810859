import { React, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// material
import {
  Stack,
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import { GetAllChapterList, GetAllMember } from "src/utils/jilcommon";
import { notification } from "src/utils/messages";
import { UpdateMeeting } from "src/api/commonapi";

// ----------------------------------------------------------------------

export default function MeetingForm({ setStep, data, step, fetchMeeting }) {
  let MDate;
  const navigate = useNavigate();
  const [chapterlist, setchapterlist] = useState(null);
  const [duedate, setdueDate] = useState(null);
  const [memberlist, setmemberlist] = useState(null);
  const [formData, setFormData] = useState({
    date: "",
    meeting_id: 0,
  });
  useEffect(async () => {
    setmemberlist(await GetAllMember());
    setchapterlist(await GetAllChapterList());
    if (step == 3) {
      setFormData({ ...formData, date: data.date });
      setFormData({ ...formData, meeting_id: data.meeting_id });
    }
  }, []);
  const handleSubmit = async (e) => {
    let resp = null;
    if (formData.date == "") {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      resp = await UpdateMeeting(JSON.stringify(formData));
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      fetchMeeting();
      if (resp.status == 1) setStep(1);
    }
  };
  return (
    <Container>
      <Stack spacing={10} style={{ marginBottom: "15px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack
              spacing={3}
              style={{ marginBottom: "15px", marginTop: "35px" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                style={{ marginTop: "15px" }}
              >
                <DatePicker
                  type="date"
                  label="Select Meeting Date"
                  value={moment(formData.date, "DD-MM-YYYY")}
                  toolbarFormat="dd-MM-yyyy"
                  inputFormat="dd-MM-yyyy"
                  onChange={(newDate) => {
                    setFormData({
                      ...formData,
                      date: moment(newDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Button
        style={{ marginTop: "15px" }}
        type="submit"
        variant="contained"
        onClick={handleSubmit}
      >
        Change Meeting Date
      </Button>
    </Container>
  );
}
