import Axios from "axios";
import { Routes } from "../constant/api_url";

export const axios = Axios.create({
  baseURL: Routes.API_HOSTNAME,
  timeout: 1000000000,
  responseType: "json"
});

axios.interceptors.request.use(
  async (config) => {
    config.headers.authorization = `Bearer admintoken`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
