import { React, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "@mui/lab/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// material
import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Grid,
} from "@mui/material";

import Select from "react-select";
import { fetchCityList, fetchStateList } from "src/utils/common";
import { GetAllUserApi } from "src/api/commonapi";
import { InsertChapterApi, UpdateChapter } from "src/api/chapterSetting";
import { notification } from "src/utils/messages";
// ----------------------------------------------------------------------

export default function ChapterForm({ setStep, data, step, myFunction }) {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const user = JSON.parse(localStorage.getItem("userData"));
  const daylist = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];
  const [stateList, setStateList] = useState(null);
  const [citylist, setcitylist] = useState(null);
  const [formData, setFormData] = useState({
    city_id:
      user?.u_type == 1 ||
      (user?.u_type == 2 && user?.p_user_id != 1 && user?.management_p == 1)
        ? user?.city_id
        : 0,
    state_id:
      user?.u_type == 1 ||
      (user?.u_type == 2 && user?.p_user_id != 1 && user?.management_p == 1)
        ? user?.state_id
        : 0,
    chpt_name: "",
    metting_day: 0,
    launch_d_id: 0,
    support_d_id: 0,
    launch_date: "",
    v2v: 0,
    r_give: 0,
    r_receive: 0,
    b_give: 0,
    b_receive: 0,
    visitor: 0,
  });
  const [userlist, setuserlist] = useState(null);
  useEffect(async () => {
    setStateList(await fetchStateList());
    setuserlist(await GetAllUserApi());
    if (
      user?.u_type == 1 ||
      (user?.u_type == 2 && user?.p_user_id != 1 && user?.management_p == 1)
    ) {
      setcitylist(await fetchCityList(user?.state_id));
    }
    if (step == 3) {
      setFormData(data);
      setcitylist(await fetchCityList(data.state_id));
    }
  }, []);
  const handleStateSelect = async (selectedState) => {
    setcitylist(await fetchCityList(selectedState.value));
    setFormData({ ...formData, state_id: selectedState.value });
  };
  const handleTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    let resp = null;
    if (
      formData.chpt_name == "" ||
      formData.launch_date == "" ||
      formData.state_id == 0 ||
      formData.state_id == undefined ||
      formData.city_id == 0 ||
      formData.city_id == undefined ||
      formData.metting_day == undefined
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      if (step == 2) resp = await InsertChapterApi(JSON.stringify(formData));
      else resp = await UpdateChapter(JSON.stringify(formData));
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setStep(1);
        myFunction();
      }
    }
  };

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                name="chpt_name"
                value={formData.chpt_name}
                label="Chapter Name"
                onChange={handleTextChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                style={{ marginTop: "15px" }}
              >
                <DatePicker
                  type="date"
                  label="Select Launch Date"
                  value={moment(formData.launch_date, "DD-MM-YYYY")}
                  toolbarFormat="dd-MM-yyyy"
                  inputFormat="dd-MM-yyyy"
                  onChange={(newDate) => {
                    setFormData({
                      ...formData,
                      launch_date: moment(newDate, "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      ),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select State</label>
              <Select
                isDisabled={
                  user?.u_type == 1 ||
                  (user?.u_type == 2 &&
                    user?.p_user_id != 1 &&
                    user?.management_p == 1)
                    ? true
                    : false
                }
                value={
                  stateList == null
                    ? { value: 0, label: "Select State" }
                    : stateList
                        .map((c) => {
                          if (c.state_id == formData.state_id) {
                            return { value: c.state_id, label: c.state_name };
                          }
                        })
                        .filter((n) => n)[0]
                }
                onChange={handleStateSelect}
                options={
                  stateList == null
                    ? []
                    : [
                        { value: 0, label: "Select State" },
                        ...stateList.map((p) => {
                          return { value: p.state_id, label: p.state_name };
                        }),
                      ]
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select City</label>
              <Select
                isDisabled={
                  user?.u_type == 1 ||
                  (user?.u_type == 2 &&
                    user?.p_user_id != 1 &&
                    user?.management_p == 1)
                    ? true
                    : false
                }
                value={
                  citylist == null
                    ? { value: 0, label: "Select City" }
                    : citylist
                        .map((c) => {
                          if (c.city_id == formData.city_id) {
                            return { value: c.city_id, label: c.city_name };
                          }
                        })
                        .filter((n) => n)[0]
                }
                onChange={(e) => setFormData({ ...formData, city_id: e.value })}
                options={
                  citylist == null
                    ? []
                    : [
                        { value: 0, label: "Select City" },
                        ...citylist.map((p) => {
                          return { value: p.city_id, label: p.city_name };
                        }),
                      ]
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select Meeting Day</label>
              <Select
                value={
                  daylist
                    .flatMap((u) => {
                      if (u.value == formData.metting_day) {
                        return { value: u.value, label: u.label };
                      } else return null;
                    })
                    .filter((n) => n)[0]
                }
                onChange={(e) =>
                  setFormData({ ...formData, metting_day: e.value })
                }
                options={daylist}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select Director</label>
              <Select
                value={
                  userlist == null
                    ? { value: 0, label: "Select Director" }
                    : userlist
                        .flatMap((u) => {
                          if (u.m_id == formData.launch_d_id) {
                            return {
                              value: u.m_id,
                              label: u.m_name + "(" + u.business_name + ")",
                            };
                          } else return null;
                        })
                        .filter((n) => n)[0]
                }
                onChange={(e) =>
                  setFormData({ ...formData, launch_d_id: e.value })
                }
                options={
                  userlist == null
                    ? []
                    : [
                        { value: 0, label: "Select Director" },
                        ...userlist.map((p) => {
                          return {
                            value: p.m_id,
                            label: p.m_name + "(" + p.business_name + ")",
                          };
                        }),
                      ]
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select Director</label>
              <Select
                value={
                  userlist == null
                    ? { value: 0, label: "Select Support Director" }
                    : userlist
                        .flatMap((u) => {
                          if (u.m_id == formData.support_d_id) {
                            return {
                              value: u.m_id,
                              label: u.m_name + "(" + u.business_name + ")",
                            };
                          } else return null;
                        })
                        .filter((n) => n)[0]
                }
                onChange={(e) =>
                  setFormData({ ...formData, support_d_id: e.value })
                }
                options={
                  userlist == null
                    ? []
                    : [
                        { value: 0, label: "Select Support Director" },
                        ...userlist.map((p) => {
                          return {
                            value: p.m_id,
                            label: p.m_name + "(" + p.business_name + ")",
                          };
                        }),
                      ]
                }
              />
            </Stack>
          </Grid>

          {(loginUser?.u_type == 0 ||
            (loginUser?.u_type == 2 &&
              loginUser?.p_user_id == 1 &&
              loginUser?.management_p != 0)) && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="number"
                    name="v2v"
                    value={formData?.v2v}
                    label="Initial V2V"
                    onChange={handleTextChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="number"
                    name="r_give"
                    value={formData?.r_give}
                    label="Initial Reference Given"
                    onChange={handleTextChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="number"
                    name="r_receive"
                    value={formData?.r_receive}
                    label="Initial Reference Receive"
                    onChange={handleTextChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="number"
                    name="b_give"
                    value={formData?.b_give}
                    label="Initial Business Given"
                    onChange={handleTextChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="number"
                    name="b_receive"
                    value={formData?.b_receive}
                    label="Initial Business Receive"
                    onChange={handleTextChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="number"
                    name="visitor"
                    value={formData?.visitor}
                    label="Initial Visitor"
                    onChange={handleTextChange}
                  />
                </Stack>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              style={{ marginTop: "15px" }}
              onClick={handleSubmit}
              variant="contained"
            >
              {step == 2 ? "Add Chapter" : "Edit Chapter"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
