import { CSVLink } from "react-csv";
import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { fetchallcountrylist } from "src/utils/common";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Icon,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";

import { CountryForm } from "../components/Form/Country";
import { DownloadDone, Downloading, FileDownload } from "@mui/icons-material";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Country() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [countrylist, setcountrylist] = useState(null);

  useEffect(async () => {
    setcountrylist(await fetchallcountrylist());
  }, []);
  const columns = [
    {
      name: "No",
      wrap: true,
      label: "No",
      key: "c_id",
      selector: (row) => row.c_id,
      sortable: true,
    },
    {
      name: "Country Name",
      wrap: true,
      label: "Country Name",
      key: "country_name",
      selector: (row) => row.country_name,
      sortable: true,
    },
  ];

  return (
    <Page title="Country | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Country
          </Typography>
        </Stack>
        {/* Start Category Form */}
        <Card
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
            marginBottom: "25px",
          }}
        >
          <CountryForm />
        </Card>
        {/* End Category Form */}

        <Card>
          {countrylist && (
            <>
              <Button className="btn btn-export">
                <CSVLink
                  data={countrylist}
                  headers={columns}
                  filename={"Country List.csv"}
                  className="btn test"
                  target="_blank"
                >
                  <span>Export CSV </span>
                </CSVLink>
              </Button>

              <DataTable
                filter
                pagination
                highlightOnHover
                columns={columns}
                data={countrylist}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
