import { GetAllChapterApi } from "src/api/chapterSetting";
import {
  getChapter,
  insertmember,
  getState,
  getCity,
  GetAllMemberApi,
  getPollSelect,
  getAllGallery,
  getAllPoll,
  getAllEventAnnou,
  getAllCity,
  getAllState,
  getAllCountry,
  getAllLandmark,
  getAllChapter,
  getAllBusiness,
  getAllV2v,
  getAllReference,
  GetStateById,
  getattendance,
  getMemberAttendanceByChepter,
  getMemberAttendanceById,
  getAllGreeting,
  getChapterByCity,
  getCityAmbassadorById,
  GetStaffByPid,
  getAllocateListById,
  getStaffById,
  getTaskList,
  getLeadList,
  getTaskByTid,
  getLeadHistory,
  getChapterByUser,
  getMeetingByUser,
  getAllCityAmbassador,
  getRenewalMemberData,
  getDashboardCounter,
  getWalletHistoryById,
  getMemberFeeHistory,
  getFolderByCityId,
  getDocumentByFid,
  getAllvisitor,
  getAllTestimonial,
  getV2vByMemberReport,
  getBusinessByMemberReport,
  getReferenceByMemberReport,
  getVisitorByMemberReport,
  getAttendanceByMemberReport,
  getLvbCounterWeb,
  getcityambdetailbyid,
  findTopper,
  getPinBadge,
  getPinByMemberId,
  getMembersDetailedReport,
  getMembersSpecificDetailedReport,
  fetchBlockVisitorList,
  fetchLeadDetailsById,
  getSuggestionList,
  getMemberInductionList,
  thankYouNoteApprovalData,
  getNewLeadList,
  getPinAssignReport,
} from "src/api/commonapi";
import { getLvbDocument } from "../api/commonapi";

export async function fetchStateList() {
  try {
    const resp = await getState();
    return resp;
  } catch {
    return null;
  }
}

export async function fetchCityList(cid) {
  try {
    const resp = await getCity(cid);
    return resp;
  } catch {
    return null;
  }
}
export async function GetAllChapter(cid) {
  try {
    const resp = await GetAllChapterApi(cid);
    return resp;
  } catch {
    return null;
  }
}

export async function GetAllMember(city_id, u_type) {
  try {
    const resp = await GetAllMemberApi(city_id, u_type);
    return resp.data;
  } catch {
    return null;
  }
}

export async function fetchGalleryList() {
  try {
    const resp = await getAllGallery();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchPollList(city_id, u_type) {
  try {
    const resp = await getAllPoll(city_id, u_type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function PollSelect(e, userType, cityId) {
  try {
    const resp = await getPollSelect(e, userType, cityId);
    if (resp.status == 1) return resp.data;
    else return resp;
  } catch {
    return null;
  }
}

export async function fetchEventAnnouList(city_id, u_type) {
  try {
    const resp = await getAllEventAnnou(city_id, u_type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}
export async function fetchGreetingList() {
  try {
    const resp = await getAllGreeting();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchallcitylist() {
  try {
    const resp = await getAllCity();
    return resp.data;
  } catch {
    return null;
  }
}

export async function fetchallstatelist(cid) {
  try {
    const resp = await getAllState(cid);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchallcountrylist(cid) {
  try {
    const resp = await getAllCountry(cid);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchLandmarkList() {
  try {
    const resp = await getAllLandmark();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchChaptList() {
  try {
    const resp = await getAllChapter();
    return resp;
  } catch {
    return null;
  }
}

export async function fetchBusinessList(ch_id, m_id) {
  try {
    const resp = await getAllBusiness(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchV2vList(ch_id, m_id) {
  try {
    const resp = await getAllV2v(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchVisitorList(ch_id, m_id) {
  try {
    const resp = await getAllvisitor(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchTestimonialList(m_id) {
  try {
    const resp = await getAllTestimonial(m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchReferenceList(ch_id, m_id) {
  try {
    const resp = await getAllReference(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchStateById(id) {
  try {
    const resp = await GetStateById(id);
    if (resp.status == 1) return resp;
  } catch {
    return null;
  }
}

export async function fetchAttendance(id) {
  try {
    const resp = await getattendance(id);
    if (resp.status == 1) return resp;
  } catch {
    return null;
  }
}

export async function fetchMemberAttendanceByChepter(data) {
  try {
    const resp = await getMemberAttendanceByChepter(data);
    if (resp.status == 1) return resp.data;
  } catch {
    return null;
  }
}

export async function fetchMemberAttendanceById(data) {
  try {
    const resp = await getMemberAttendanceById(data);
    if (resp.status == 1) return resp;
  } catch {
    return null;
  }
}

export async function fetchchapterlistbycity(data) {
  try {
    const resp = await getChapterByCity(data);
    if (resp.status == 1) return resp.data;
  } catch {
    return null;
  }
}

export async function fetchcityambassadorbyid(data) {
  try {
    const resp = await getCityAmbassadorById(data);
    if (resp.status == 1) return resp.data;
  } catch {
    return null;
  }
}

export async function fetchNewLeadList(data) {
  try {
    const resp = await getNewLeadList(data);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchstaffbypid(u_id, u_type) {
  try {
    const resp = await GetStaffByPid(u_id, u_type);
    if (resp.status == 1) return resp.data;
  } catch {
    return null;
  }
}

export async function fetchallocatelistbyid(u_id, u_type) {
  try {
    const resp = await getAllocateListById(u_id, u_type);
    if (resp.status == 1) return resp.data;
  } catch {
    return null;
  }
}

export async function fetchstaffListbypid(u_id, u_type) {
  try {
    const resp = await getStaffById(u_id, u_type);
    if (resp.status == 1) return resp.data;
  } catch {
    return null;
  }
}

export async function fetchtasklistbyid(u_id, u_type) {
  try {
    const resp = await getTaskList(u_id, u_type);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchleadlistbyid(u_id, u_type) {
  try {
    const resp = await getLeadList(u_id, u_type);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchtaskbytid(t_id) {
  try {
    const resp = await getTaskByTid(t_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function featchleadhistory(l_id) {
  try {
    const resp = await getLeadHistory(l_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchchapterbyuser(city_id, u_type) {
  try {
    const resp = await getChapterByUser(city_id, u_type);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchmeetingbyuser(city_id, u_type) {
  try {
    const resp = await getMeetingByUser(city_id, u_type);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchallcityamabassador() {
  try {
    const resp = await getAllCityAmbassador();
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchrenewalmemberdata(l_id) {
  try {
    const resp = await getRenewalMemberData(l_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchdashboardcounter(u_id, u_type, city_id) {
  try {
    const resp = await getDashboardCounter(u_id, u_type, city_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchwallethistorybyid(c_a_id) {
  try {
    const resp = await getWalletHistoryById(c_a_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchmemberfeehistory(m_id) {
  try {
    const resp = await getMemberFeeHistory(m_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchlvbdocument(city_id) {
  try {
    const resp = await getLvbDocument(city_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchFolderByCityId(city_id) {
  try {
    const resp = await getFolderByCityId(city_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchDocumentByFid(f_id) {
  try {
    const resp = await getDocumentByFid(f_id);
    if (resp.status == 1) return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchV2vListByMember(ch_id, m_id) {
  try {
    const resp = await getV2vByMemberReport(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchBusinessListByMember(ch_id, m_id) {
  try {
    const resp = await getBusinessByMemberReport(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchReferenceByMember(ch_id, m_id) {
  try {
    const resp = await getReferenceByMemberReport(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchVisitorByMember(ch_id, m_id) {
  try {
    const resp = await getVisitorByMemberReport(ch_id, m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAttendanceByMember(m_id) {
  try {
    const resp = await getAttendanceByMemberReport(m_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchLvbCounterWeb(id) {
  try {
    const resp = await getLvbCounterWeb(id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export function strToAmountNumberConvert(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val =
      (val / 10000000).toFixed(2) + " Cr" + " (" + value.toLocaleString() + ")";
  } else if (val >= 100000) {
    val =
      (val / 100000).toFixed(2) + " Lac" + " (" + value.toLocaleString() + ")";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(2) + " K" + " (" + value.toLocaleString() + ")";
  } else {
    val = val.toLocaleString();
  }
  return val;
}

export async function fetchCityAmbDetailById(id) {
  try {
    const resp = await getcityambdetailbyid(id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function fetchPinList(type) {
  try {
    const resp = await getPinBadge(type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function getPinBadgeByMemberId(id) {
  try {
    const resp = await getPinByMemberId(id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function getMembersDetailedReportHandler(data) {
  try {
    const resp = await getMembersDetailedReport(data);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function getMembersSpecificDetailedReportHandler(data) {
  try {
    const resp = await getMembersSpecificDetailedReport(data);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

// export async function FetchfindTopper(data) {
//   try {
//     const resp = await findTopper(data);
//     if (resp.status == 1) return resp.data;
//     else return null;
//   } catch {
//     return null;
//   }
// }

export async function getBlockVisitorCommonFunction(city_id) {
  try {
    const resp = await fetchBlockVisitorList(city_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function getLeadDetailsUsingId(id) {
  try {
    const resp = await fetchLeadDetailsById(id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function getSuggestionListByCity(data) {
  try {
    const resp = await getSuggestionList(data);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function fetchMemberInductionList(data) {
  try {
    const resp = await getMemberInductionList(data);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function fetchThankYouNoteWaitingsForApprovalList(city_id) {
  try {
    const resp = await thankYouNoteApprovalData(city_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

export async function getPinAssignReportHandler(data) {
  try {
    const resp = await getPinAssignReport(data);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch (error) {
    return null;
  }
}

// approvedThankyouNoteRequest
