import Select from "react-select";
import React, { useState, useEffect } from "react";
import { fetchchapterbyuser } from "src/utils/common";

export default function ChapterSelectDropDown({
  handleChange,
  selectedState,
  chapterKey,
  dropDownLabel,
  defaultOptionSelectAll,
  makeDisableSelect,
  customMenuPortalTarget = true,
}) {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [chapterData, setChapterData] = useState([]);
  const getchptlist = async () => {
    setChapterData(
      await fetchchapterbyuser(loginUser.city_id, loginUser.u_type)
    );
  };
  useEffect(async () => {
    getchptlist();
  }, []);

  return (
    <>
      <label style={{ marginBottom: "10px" }}>{dropDownLabel}</label>
      <Select
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        className="react-select"
        classNamePrefix="select"
        menuPortalTarget={customMenuPortalTarget ? document.body : ""}
        isDisabled={makeDisableSelect ? true : false}
        value={
          selectedState[chapterKey] == 0
            ? {
                value: 0,
                label: defaultOptionSelectAll ? "All" : "Select Chapter",
              }
            : chapterData.map((ch) => {
                if (selectedState[chapterKey] == ch.chpt_id) {
                  return { value: ch.chpt_id, label: ch.chpt_name };
                }
              })
        }
        onChange={handleChange}
        options={chapterData?.map((ch) => {
          return { value: ch.chpt_id, label: ch.chpt_name };
        })}
      />
    </>
  );
}
