import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DeleteSharp, CalendarMonth, EditSharp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Page from "../components/Page";
import { deleteGallery, getAllGallery, insertgallery } from "src/api/commonapi";
import { notification } from "src/utils/messages";
import { fetchGalleryList } from "src/utils/common";
import DataTable from "react-data-table-component";
import { Routes } from "src/constant/api_url";
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  FormLabel,
  CardFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Deletemodal from "./DeleteModal";
import Label from "src/components/Label";

//---------------------------------------------------------

export default function Gallery() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [setfile, setFile] = useState({});
  const [getdata, setgetdata] = useState({
    g_type: 0,
    title: "",
    file: "",
    g_youtube_url: "",
  });
  const [gallerylist, setgallerylist] = useState([]);
  const [response, setresponse] = useState(0);
  const [fileimage, setfileimage] = useState();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  useEffect(async () => {
    setgallerylist(await fetchGalleryList());
  }, [response]);

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.g_id,
      sortable: true,
      width: "110px",
    },
    {
      name: "Gallery Title",
      wrap: true,
      selector: (row) => (row.g_title != "" ? row.g_title : "-"),
    },
    {
      name: "Image / YouTube URL",
      wrap: true,
      selector: (row) =>
        row.g_type == 0 ? (
          <img
            src={`${Routes.API_HOSTNAME}images/event/${row.g_photo}`}
            alt="img"
            width="80"
          />
        ) : (
          row.g_youtube_url
        ),
    },
    {
      name: "Type",
      wrap: true,
      width: "110px",
      selector: (row) => (
        <Label
          variant="ghost"
          color={row.g_type == 0 ? "success" : "error"}
          style={{
            marginBottom: "10px",
            display: "flex",
            fontSize: "12px",
            fontWeight: "normal",
          }}
        >
          {row.g_type == 0 ? "Photo" : "Video"}
        </Label>
      ),
      //
    },

    (loginUser.u_type == 0 ||
      (loginUser.u_type == 2 && loginUser.p_user_id == 1)) && {
      name: "Action",
      wrap: true,
      width: "110px",
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setDvisible({ mval: true, id: row.g_id });
            }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ].filter((f) => f != false);

  const deletegallery = async (g_id) => {
    const resp = await deleteGallery(g_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const onChangeFunction = async (e) => {
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value,
    });
  };
  const resetFormData = (e) => {
    setgetdata({
      g_type: e.target.value,
      title: "",
      file: "",
      g_youtube_url: "",
    });
  };

  const handleSubmit = async () => {
    let fdata = new FormData();
    fdata.append("g_title", getdata.title);
    fdata.append("g_photo", getdata.file);
    fdata.append("g_type", getdata.g_type);
    fdata.append("g_youtube_url", getdata.g_youtube_url);

    if (getdata.g_type == 0) {
      // for image validation
      if (
        getdata.title == "" ||
        getdata.title == undefined ||
        getdata.file == "" ||
        getdata.file == undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      } else {
        const resp = await insertgallery(fdata);

        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });

        notify();

        if (resp.status == 1) {
          setgetdata({ g_type: 0, title: "", file: "", g_youtube_url: "" });
          setfileimage();
          setresponse(response + 1);
        }
      }
    } else {
      // for video validation
      if (getdata.g_youtube_url == "" || getdata.g_youtube_url == undefined) {
        let notify = notification({
          type: "error",
          message: "Please Enter youtube Link",
        });
        notify();
      }
      const resp = await insertgallery(fdata);

      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });

      notify();

      if (resp.status == 1) {
        setgetdata({ g_type: 0, title: "", file: "", g_youtube_url: "" });
        setfileimage();
        setresponse(response + 1);
      }
    }
  };

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletegallery}
      />
      <Page title="Gallery | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Gallery
            </Typography>
          </Stack>
          {/* Start Category Form */}
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            <Container>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{ marginBottom: "0px" }}
                  >
                    Select Upload File Type
                  </FormLabel>
                  <RadioGroup
                    style={{ marginTop: "0px" }}
                    row
                    defaultValue={0}
                    value={getdata.g_type}
                    onChange={(e) => resetFormData(e)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="g_type"
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Photo"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Video"
                    />
                  </RadioGroup>
                </Stack>
              </Grid>

              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={getdata?.title ? getdata?.title : ""}
                  type="text"
                  label="Title"
                  name="title"
                  onChange={onChangeFunction}
                />
              </Stack>
              {getdata.g_type == 2 && (
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={getdata?.g_youtube_url ? getdata?.g_youtube_url : ""}
                    type="text"
                    label="YouTube URL"
                    name="g_youtube_url"
                    onChange={onChangeFunction}
                  />
                </Stack>
              )}
              {getdata.g_type == 0 && (
                <>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Image
                  </FormLabel>{" "}
                  <br />
                  {fileimage != undefined && (
                    <img
                      src={fileimage}
                      alt="img"
                      width="100px"
                      height="80px"
                      style={{ margin: "5px" }}
                    />
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    style={{ marginBottom: "20px" }}
                  >
                    Click To Upload File
                    <input
                      id="file"
                      name="file"
                      type="file"
                      hidden
                      onChange={(event) => {
                        setgetdata({
                          ...getdata,
                          file: event.currentTarget.files[0],
                        });
                        setfileimage(
                          URL.createObjectURL(event.currentTarget.files[0])
                        );
                      }}
                      className="form-control"
                    />
                  </Button>
                </>
              )}
              <br />
              <Button
                style={{ marginTop: "15px" }}
                onClick={handleSubmit}
                variant="contained"
              >
                Add
              </Button>
            </Container>
          </Card>

          {/* End Category Form */}

          <Card>
            {gallerylist && (
              <DataTable
                filter
                pagination
                highlightOnHover
                columns={columns}
                data={gallerylist != null && gallerylist}
              />
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
