import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import { styled } from "@mui/material/styles";
import "../Assets/css/style.css";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Page from "src/components/Page";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Select from "react-select";
import { GetMemberByChptId } from "src/api/commonapi";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import { notification } from "src/utils/messages";
import {
  fetchMemberInductionList,
  fetchallcitylist,
  fetchchapterbyuser,
  fetchchapterlistbycity,
  getMembersDetailedReportHandler,
  getMembersSpecificDetailedReportHandler,
} from "src/utils/common";
import { BorderBottom } from "@mui/icons-material";
import Label from "src/components/Label";

export default function MemberInductionReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const reportType = [
    { value: 0, label: "All" },
    { value: 1, label: "New Member" },
    { value: 2, label: "Renewal Member" },
  ];
  const memberActiveType = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "De-Active" },
  ];
  const expo_columns = [
    { label: "Member Name", key: "member_name" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Joining Date", key: "joining_date" },
    { label: "Expire Date", key: "due_date" },
    { label: "Duration", key: "duration" },
    { label: "Member Renewal Type", key: "member_renewal" },
  ];
  const [filteredData, setFilteredData] = useState({
    startDate: null,
    endDate: null,
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
    mType: 0,
    activeType: -1,
  });
  const [chapoption, setchptoption] = useState([]);
  const [cityList, setClityList] = useState([{ value: 0, label: "All" }]);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(async () => {
    let res = await fetchallcitylist();
    let newCityList = [{ value: 0, label: "All" }];
    res?.map((res) => {
      newCityList.push({ value: res.city_id, label: res.city_name });
    });
    setClityList(newCityList);

    setchptoption(
      await fetchchapterbyuser(loginUser.city_id, loginUser.u_type)
    );
  }, []);

  const restFilterSearch = () => {
    setFilteredData({
      startDate: null,
      endDate: null,
      no_of_top: 1,
      city_id:
        loginUser?.u_type == 1 ||
        (loginUser?.u_type == 2 &&
          loginUser?.p_user_id != 1 &&
          loginUser?.management_p == 1)
          ? loginUser?.city_id
          : 0,
      chpt_id: 0,
      mType: 0,
      activeType: -1,
    });
    setTableData([]);
  };

  const columns = [
    {
      name: "Member Name",
      cell: (row) => row.member_name,
      // width: "280px",
      sortable: true,
    },
    {
      name: "Chapter Name",
      selector: (row) => row.chpt_name,
      width: "220px",
      sortable: true,
    },
    {
      name: "Joining Date",
      selector: (row) => row.joining_date,
      width: "220px",
    },
    {
      name: "Expire Date",
      selector: (row) => row.due_date,
      width: "220px",
    },
    {
      name: "Duration",
      sortable: true,
      selector: (row) => row.duration,
      width: "220px",
    },
    {
      name: "Member Renewal Type",
      selector: (row) => (
        <Label
          variant={"ghost"}
          color={row.member_renewal == "Renewal Member" ? "success" : "primary"}
        >
          {row.member_renewal}
        </Label>
      ),
      width: "220px",
    },
  ];

  const searchMemberInductionList = async () => {
    setLoader(true);
    let res = await fetchMemberInductionList({ ...filteredData });
    if (res?.length > 0) {
      setTableData(res);
    } else {
      setTableData([]);
    }
    setLoader(false);
  };
  return (
    <Page title="Chapter's Member All Report | Local Vocal Business Group">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Member's induction Report
          </Typography>
        </Stack>
        <Card
          style={{
            padding: "25px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Stack spacing={3}>
                <label style={{ marginBottom: "10px" }}>Select City</label>
                <Select
                  menuPortalTarget={document.body}
                  isDisabled={
                    loginUser?.u_type == 1 ||
                    (loginUser?.u_type == 2 &&
                      loginUser?.p_user_id != 1 &&
                      loginUser?.management_p == 1)
                      ? true
                      : false
                  }
                  value={
                    cityList &&
                    cityList?.map((option) => {
                      if (option.value == filteredData.city_id) {
                        return { value: option.value, label: option.label };
                      }
                    })
                  }
                  onChange={async (e) => {
                    setFilteredData({
                      ...filteredData,
                      city_id: e.value,
                      chpt_id: 0,
                    });
                  }}
                  options={cityList}
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3}>
                {/* <ChapterSelectDropDown
                  dropDownLabel={"Select Chapter"}
                  selectedState={filteredData}
                  defaultOptionSelectAll={true}
                  chapterKey="chpt_id"
                  handleChange={(e) => {
                    // setsearchdata({
                    //   ...searchdata,
                    //   chept_id: e.value,
                    // });
                    setFilteredData({ ...filteredData, chpt_id: e.value });
                  }}
                /> */}
                <label style={{ marginBottom: "10px" }}>Select Chapter</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    filteredData.chpt_id == 0
                      ? { value: 0, label: "All Chapter" }
                      : chapoption &&
                        chapoption?.map((c) => {
                          if (c.chpt_id == filteredData.chpt_id) {
                            return {
                              value: c.chpt_id,
                              label: c.chpt_name,
                            };
                          }
                        })
                  }
                  style={{ marginTop: "0px" }}
                  name="chpt_id"
                  onChange={(e) => {
                    setFilteredData({ ...filteredData, chpt_id: e.value });
                  }}
                  options={[
                    {
                      value: 0,
                      label: "All Chapter",
                    },
                    ...(chapoption &&
                      chapoption
                        ?.map((chpt) => {
                          if (filteredData?.city_id != 0) {
                            if (chpt?.city_id == filteredData?.city_id) {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          } else {
                            return {
                              value: chpt.chpt_id,
                              label: chpt.chpt_name,
                            };
                          }
                        })
                        .filter((f) => f != undefined)),
                  ]}
                ></Select>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3}>
                <label style={{ marginBottom: "10px" }}>Select Type</label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    reportType &&
                    reportType?.map((option) => {
                      if (option.value == filteredData.mType) {
                        return { value: option.value, label: option.label };
                      }
                    })
                  }
                  onChange={(e) => {
                    setFilteredData({ ...filteredData, mType: e.value });
                  }}
                  options={
                    reportType &&
                    reportType?.map((option) => {
                      return { value: option.value, label: option.label };
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3}>
                <label style={{ marginBottom: "10px" }}>
                  Select Member Status
                </label>
                <Select
                  menuPortalTarget={document.body}
                  value={
                    memberActiveType &&
                    memberActiveType?.map((option) => {
                      if (option.value == filteredData.activeType) {
                        return { value: option.value, label: option.label };
                      }
                    })
                  }
                  onChange={(e) => {
                    setFilteredData({ ...filteredData, activeType: e.value });
                  }}
                  options={
                    memberActiveType &&
                    memberActiveType?.map((option) => {
                      return { value: option.value, label: option.label };
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2} className="d-flex align-item-end">
              <Stack spacing={3} className="mt-5">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date"
                    value={filteredData?.startDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filteredData,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={2} className="d-flex align-item-end">
              <Stack spacing={3} className="mt-5">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    type="date"
                    name="enddate"
                    label="End Date"
                    value={filteredData?.endDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filteredData,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              className="d-flex align-item-center justify-content-end"
            >
              <Button variant="contained" onClick={searchMemberInductionList}>
                Search
              </Button>
              <Button
                variant="outlined"
                onClick={restFilterSearch}
                className="ms-5"
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Card>
        <Card className="pb-15 mt-20">
          <Button className="btn btn-export">
            <CSVLink
              data={tableData}
              headers={expo_columns}
              filename={`Member's induction Report.csv`}
              className="btn btn-primary test"
              target="_blank"
            >
              Export CSV
            </CSVLink>
          </Button>
          <DataTable
            filter
            pagination
            highlightOnHover
            progressPending={loader}
            columns={columns}
            data={tableData}
          />
        </Card>
      </Container>
    </Page>
  );
}
