import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import Select from "react-select";

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Grid,
  MenuItem,
  FormLabel,
  CardFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
// components
import { EventForm } from "../components/Form/Event";
import { PollSelect } from "src/utils/common";
import "react-toastify/dist/ReactToastify.css";
import { notification } from "src/utils/messages";
import { insertevent } from "src/api/commonapi";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Page from "../components/Page";
import { Routes } from "../constant/api_url";
import { fetchEventAnnouList } from "src/utils/common";
import { DeleteSharp, CalendarMonth, EditSharp } from "@mui/icons-material";
import { EventAnnouDelete, updateEventAnnouncement } from "src/api/commonapi";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";

export default function Event() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [citylist, setcitylist] = useState(null);
  const [eventlist, seteventlist] = useState(null);
  const [gettabledata, settabledata] = useState([]);
  const [response, setresponse] = useState(0);
  const [step, setStep] = useState(0);
  //strat
  const notify = () => toast("Wow so easy!");
  const navigate = useNavigate();
  const [statelist, setstatelist] = useState(null);
  const [getdata, setgetdata] = useState({
    e_type: loginUser?.u_type == 0 ? 0 : 2,
    e_status: 0,
    e_name: "",
    e_description: "",
    s_date: "",
    e_date: "",
  });
  const [option, setOption] = useState(null);
  const [setfile, setFile] = useState({});
  const [fileimage, setfileimage] = useState();

  const EventSchema = Yup.object().shape({
    eventname: Yup.string().required("Event Name is required"),
  });

  const getlandmarklist = async () => {
    settabledata(
      await fetchEventAnnouList(loginUser.city_id, loginUser.u_type)
    );
  };
  const onChangeFunction = (e) => {
    if (e.target.name == "e_type") {
      setOption([]);
      setSelectData(e.target.value);
    }
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(async () => {
    if (loginUser?.u_type == 1) {
      setSelectData(2);
    }
  }, []);
  const setSelectData = async (type = 0) => {
    let optiondata = [];
    if (type != 0) {
      const resp = await PollSelect(
        type,
        loginUser?.u_type,
        loginUser?.city_id
      );
      resp?.map((e) => optiondata.push({ value: e.value, label: e.name }));
      setOption([{ value: 0, label: "Select" }, ...optiondata]);
    }
  };

  const handleSubmit = async () => {
    let fdata = new FormData();
    fdata.append("e_status", getdata?.e_status);
    fdata.append("e_type", getdata?.e_type);
    fdata.append("e_type_id", getdata?.e_type == 0 ? 0 : getdata?.e_type_id);
    fdata.append("e_name", getdata?.e_name);
    fdata.append("event_img", setfile?.file);
    fdata.append("e_description", getdata?.e_description);
    fdata.append("s_date", getdata?.s_date);
    fdata.append("e_date", getdata?.e_date);

    if (step == 1) {
      if (
        (getdata.e_type != 0 &&
          (getdata.e_type_id == 0 || getdata.e_type_id == undefined)) ||
        setfile == "" ||
        setfile == undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      } else {
        const resp = await insertevent(fdata);

        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
        setgetdata({
          e_type: loginUser?.u_type == 0 ? 0 : 2,
          e_status: 0,
          e_name: "",
          e_description: "",
        });
        setStep(0);
        getlandmarklist();
      }
    } else {
      fdata.append("e_id", getdata?.e_id);
      const resp = await updateEventAnnouncement(fdata);

      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      setgetdata({
        e_type: loginUser?.u_type == 0 ? 0 : 2,
        e_status: 0,
        e_name: "",
        e_description: "",
      });
      setStep(0);
      getlandmarklist();
    }

    getlandmarklist();
  };
  //end

  useEffect(async () => {
    // geteventlist();
    settabledata(
      await fetchEventAnnouList(loginUser.city_id, loginUser.u_type)
    );
  }, [response]);

  const landmarkresponse = () => {
    geteventlist();
  };

  const geteventlist = async () => {
    settabledata(
      await fetchEventAnnouList(loginUser.city_id, loginUser.u_type)
    );
  };

  const eventannoudelete = async (e_id) => {
    const resp = await EventAnnouDelete(e_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const expo_columns = [
    { label: "No", key: "e_id" },
    { label: "Type", key: "Event_type" },
    { label: "Name", key: "e_name" },
    { label: "Event/Announcement For", key: "Event_For" },
    { label: "Start Date", key: "s_date" },
    { label: "End Date", key: "e_date" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.e_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Type",
      wrap: true,
      selector: (row) => (row.e_status == 0 ? "Event" : "Announcement"),
    },
    {
      name: "Image",
      wrap: true,
      cell: (row) => (
        <img
          src={`${Routes.API_HOSTNAME}images/event/${row.e_url}`}
          alt="img"
          width="80"
        />
      ),
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row.e_name,
      sortable: true,
    },
    {
      name: "Event/Announcement Type",
      wrap: true,
      selector: (row) =>
        row.e_type == 1
          ? "State"
          : row.e_type == 2
          ? "City"
          : row.e_type == 3
          ? "Chapter"
          : "LVB",
    },
    {
      name: "Start Date",
      wrap: true,
      selector: (row) => row.s_date,
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => row.e_date,
      wrap: true,
      sortable: true,
    },
    {
      name: "Location",
      wrap: true,
      selector: (row) =>
        loginUser.u_type != 0
          ? row.city_name
          : row.e_type == 1
          ? row.s_name
          : row.e_type == 2
          ? row.city_name
          : row.e_type == 3
          ? row.ch_name
          : "LVB",
    },
    {
      name: "Action",
      wrap: true,
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setDvisible({ mval: true, id: row.e_id });
            }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={() => {
              setStep(2);
              setgetdata(row);
              setSelectData(row.e_type);
              setfileimage();
            }}
          >
            <Tooltip title="Edit">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={eventannoudelete}
      />
      <Page title="Event And Announcement |Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Event / Announcement
            </Typography>
          </Stack>
          {/* Start Category Form */}
          {step != 0 ? (
            <>
              <Button
                onClick={() => {
                  setStep(0);
                  setgetdata({
                    e_type: loginUser?.u_type == 0 ? 0 : 2,
                    e_status: 0,
                    e_name: "",
                    e_description: "",
                  });
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card
                style={{
                  padding: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  Select Type
                </FormLabel>
                <Grid item xs={12}>
                  <Stack spacing={3} style={{ marginBottom: "15px" }}>
                    <RadioGroup
                      style={{ marginTop: "0px" }}
                      row
                      defaultValue={0}
                      value={getdata?.e_status}
                      onChange={(e) => {
                        onChangeFunction(e);
                      }}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="e_status"
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Event"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Announcement"
                      />
                    </RadioGroup>
                  </Stack>
                </Grid>
                <FormLabel id="demo-radio-buttons-group-label">
                  Display
                </FormLabel>
                <Grid item xs={12}>
                  <Stack spacing={3} style={{ marginBottom: "15px" }}>
                    <RadioGroup
                      style={{ marginTop: "0px" }}
                      row
                      defaultValue={0}
                      value={getdata.e_type}
                      onChange={(e) => {
                        onChangeFunction(e);
                      }}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="e_type"
                    >
                      {loginUser?.u_type == 0 && (
                        <>
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="LVB"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="State"
                          />
                        </>
                      )}
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="City"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Chapter"
                      />
                    </RadioGroup>
                  </Stack>
                </Grid>
                {getdata.e_type != 0 ? (
                  <Grid item xs={12}>
                    <Stack spacing={10} style={{ marginBottom: "15px" }}>
                      <label style={{ marginBottom: "10px" }}>
                        Select
                        {getdata.e_type == 1
                          ? " State"
                          : getdata.e_type == 2
                          ? " City"
                          : getdata.e_type == 3
                          ? " Chapter"
                          : " LVB"}
                      </label>
                      <Select
                        style={{ marginTop: "0px" }}
                        required={true}
                        value={
                          option != null &&
                          option.map((c) => {
                            if (c.value == getdata?.e_type_id) {
                              return { value: c.value, label: c.label };
                            }
                          })
                        }
                        onChange={(e) => {
                          setgetdata({ ...getdata, e_type_id: e.value });
                        }}
                        menuPortalTarget={document.body}
                        options={option}
                      />
                    </Stack>
                  </Grid>
                ) : (
                  ""
                )}
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={getdata?.e_name ? getdata?.e_name : ""}
                    type="text"
                    label="Name"
                    name="e_name"
                    onChange={onChangeFunction}
                  />
                </Stack>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="text"
                    value={getdata?.e_description ? getdata?.e_description : ""}
                    label="Description"
                    name="e_description"
                    onChange={onChangeFunction}
                  />
                </Stack>
                <Grid item xs={6}>
                  <Stack
                    spacing={3}
                    style={{ marginBottom: "15px", marginTop: "35px" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      style={{ marginTop: "15px" }}
                    >
                      <DatePicker
                        type="date"
                        name="s_date"
                        label="Start Date "
                        inputFormat="dd/MM/yyyy"
                        value={
                          getdata.s_date != ""
                            ? moment(getdata.s_date, "DD-MM-YYYY")
                            : null
                        }
                        onChange={(newDate) => {
                          setgetdata({
                            ...getdata,
                            s_date: moment(newDate, "DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            ),
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    spacing={3}
                    style={{ marginBottom: "15px", marginTop: "35px" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      style={{ marginTop: "15px" }}
                    >
                      <DatePicker
                        type="date"
                        name="e_date"
                        label="End Date "
                        inputFormat="dd/MM/yyyy"
                        value={
                          getdata.e_date != ""
                            ? moment(getdata.e_date, "DD-MM-YYYY")
                            : null
                        }
                        onChange={(newDate) => {
                          setgetdata({
                            ...getdata,
                            e_date: moment(newDate, "DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            ),
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <FormLabel id="demo-radio-buttons-group-label">Image</FormLabel>{" "}
                <br />
                {step == 2 &&
                  (fileimage === undefined ? (
                    <img
                      src={`${Routes.API_HOSTNAME}images/event/${getdata.e_url}`}
                      alt="img"
                      width="100px"
                      height="80px"
                      style={{ margin: "5px" }}
                    />
                  ) : (
                    <img
                      src={fileimage}
                      alt="img"
                      width="100px"
                      height="80px"
                      style={{ margin: "5px" }}
                    />
                  ))}
                <Button
                  variant="contained"
                  component="label"
                  style={{ marginBottom: "20px" }}
                >
                  Click To Upload File
                  <input
                    id="file"
                    name="file"
                    type="file"
                    hidden
                    onChange={(event) => {
                      setFile({ file: event.currentTarget.files[0] });
                      setfileimage(
                        URL.createObjectURL(event.currentTarget.files[0])
                      );
                    }}
                    className="form-control"
                  />
                </Button>
                <br />
                <Button
                  style={{ marginTop: "15px" }}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  submit
                </Button>
              </Card>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setStep(1);
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Add Event/Announcement
              </Button>
              <Card>
                {gettabledata && (
                  <>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={gettabledata}
                        headers={expo_columns}
                        filename={"EventAnnouncement.csv"}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                    <DataTable
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={gettabledata != null && gettabledata}
                    />
                  </>
                )}
              </Card>
            </>
          )}
          {/* End Category Form */}
        </Container>
      </Page>
    </>
  );
}
