import React, { Component, useState, useEffect } from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const [user, setUserData] = useState(null);
  const restoreUser = async () => {
    const users = await localStorage.getItem("userData");
    if (users != null) {
      setUserData(users);
    }
  };

  useEffect(() => {
    restoreUser();
  }, []);

  return (
    <>
      <HeaderStyle>
        {user != null && <Logo />}
        {/* <RouterLink to="/">{user != null && <Logo />}</RouterLink> */}
      </HeaderStyle>
      <Outlet />
    </>
  );
}
