import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/person-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import refrence from "@iconify/icons-eva/bulb-fill";
import v2v from "@iconify/icons-eva/message-circle-fill";
import camera from "@iconify/icons-eva/image-2-fill";
import setting from "@iconify/icons-eva/settings-2-fill";
import member from "@iconify/icons-eva/person-add-fill";
import login from "@iconify/icons-eva/log-in-fill";
import poll from "@iconify/icons-eva/bar-chart-fill";
import meeting from "@iconify/icons-eva/people-fill";
import event from "@iconify/icons-eva/bookmark-fill";
import country from "@iconify/icons-eva/flag-fill";
import state from "@iconify/icons-eva/funnel-fill";
import city from "@iconify/icons-eva/grid-fill";
import landmark from "@iconify/icons-eva/pin-fill";
import report from "@iconify/icons-eva/file-text-outline";
import chp from "@iconify/icons-eva/file-add-fill";
import mic from "@iconify/icons-eva/volume-up-fill";
import staff from "@iconify/icons-eva/people-outline";
import task from "@iconify/icons-eva/briefcase-fill";
import lead from "@iconify/icons-eva/trending-up-fill";
import dashboard from "@iconify/icons-eva/globe-fill";
import cityambassador from "@iconify/icons-eva/shield-fill";
import documents from "@iconify/icons-eva/folder-fill";
import npmFill from "@iconify/icons-eva/npm-fill";
import personDeleteFill from "@iconify/icons-eva/person-delete-fill";
import pricetagsFill from "@iconify/icons-eva/pricetags-fill";
import TourIcon from "@mui/icons-material/Tour";
import Present from "@mui/icons-material/Co2Rounded";
import { CoPresentRounded, FolderShared } from "@mui/icons-material";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
let loginUser = JSON.parse(localStorage.getItem("userData"));

// eslint-disable-next-line no-sparse-arrays
const adminconfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon(dashboard),
  },
  {
    title: "Documents",
    path: "/documents",
    icon: getIcon(documents),
  },

  {
    title: "City Ambassador",
    path: "/cityambassador",
    icon: getIcon(cityambassador),
  },
  {
    title: "Member",
    path: "/member",
    icon: getIcon(member),
  },
  {
    title: "Meeting",
    path: "/meeting",
    icon: getIcon(meeting),
  },
  {
    title: "Greeting",
    path: "/greeting",
    icon: getIcon(mic),
  },
  // {
  //   title: "Member Attendance",
  //   path: "/memberattendance",
  //   icon: getIcon(peopleFill)
  // },
  {
    title: "Event-Announcement",
    path: "/event-announcement",
    icon: getIcon(event),
  },
  loginUser?.u_type == 0 && {
    title: "Staff",
    path: "/staff",
    icon: getIcon(staff),
  },

  ,
  {
    title: "Task",
    path: "/task",
    icon: getIcon(task),
  },
  {
    title: "Lead",
    path: "/lead",
    icon: getIcon(lead),
  },
  {
    title: "Suggestion",
    path: "/lvb-suggestion",
    icon: getIcon(lead),
  },
  {
    title: "Chapter",
    path: "/chapter",
    icon: getIcon(chp),
  },

  {
    title: "Gallery",
    path: "/gallery",
    icon: getIcon(camera),
  },
  {
    title: "Poll",
    path: "/poll",
    icon: getIcon(poll),
  },
  // {
  //   title: "V2V",
  //   path: "/v2v",
  //   icon: getIcon(v2v)
  // },
  // {
  //   title: "Reference",
  //   path: "/reference",
  //   icon: getIcon(refrence)
  // },
  // {
  //   title: "Business",
  //   path: "/business",
  //   icon: getIcon(shoppingBagFill)
  // },
  {
    title: "Visitor Block List",
    path: "/visitor-block-list",
    icon: getIcon(personDeleteFill),
  },
  {
    title: "Thankyou Note Approval",
    path: "/business-approval",
    icon: getIcon(pricetagsFill),
  },
  {
    title: "Topper Report",
    path: "/topper-report",
    icon: getIcon(report),
  },
  // {
  //   title: "Chapter Report",
  //   path: "/chapter-report",
  //   icon: getIcon(report)
  // },
  {
    title: "Chapter Attendance Report",
    path: "/chapter-attendance-report",
    icon: getIcon(report),
  },
  {
    title: "Chapter's Member Report",
    path: "/chapters-member-report",
    icon: getIcon(report),
  },
  {
    title: "Member Induction Report",
    path: "/member-induction-report",
    icon: getIcon(report),
  },
  {
    title: "Pin Assign Report",
    path: "/pin-assign-report",
    icon: getIcon(report),
  },
  {
    title: "LVB, City, Chapter's Reports",
    path: "",
    icon: getIcon(report),
    children: [
      { title: "LVB Report", path: "/lvb-report" },
      { title: "City Report", path: "/city-report" },
      { title: "City's Chapter Report", path: "/city-chapter-report" },
      // { title: "Reference Report", path: "/reference-report" },
      // { title: "Visitor Report", path: "/visitorreport" },
      // { title: "Testimonial Report", path: "/testimonial-report" },
      // { title: "Attendance Report", path: "/attendance-report" }
    ],
  },

  // {
  //   title: "Chapter Reports",
  //   path: "",
  //   icon: getIcon(report),
  //   children: [
  //     { title: "Chapter V2V Report", path: "/chapter-v2v-report" },
  //     { title: "Chapter Business Report", path: "/chapter-business-report" },
  //     { title: "Chapter Reference Report", path: "/chapter-reference-report" },
  //     { title: "Chapter Visitor Report", path: "/chapter-visitor-report" },
  //     { title: "Chapter Testimonial Report", path: "/chapter-testimonial-report" },
  //     { title: "Chapter Attendance Report", path: "/chapter-attendance-report" }
  //   ]
  // },
  {
    title: "Member's Reports",
    path: "",
    icon: getIcon(report),
    children: [
      { title: "Business Report", path: "/business-report" },
      { title: "Reference Report", path: "/reference-report" },
      { title: "Testimonial Report", path: "/testimonial-report" },
      { title: "V2V Report", path: "/v2v-report" },
      { title: "Visitor Report", path: "/visitorreport" },
      { title: "Attendance Report", path: "/attendance-report" },
    ],
  },
  {
    title: "Current Record",
    path: "/location",
    icon: getIcon(landmark),
    children: [
      { title: "Business", path: "/business" },
      { title: "Reference", path: "/reference" },
      { title: "Testimonial", path: "/testimonial" },
      { title: "V2V", path: "/v2v" },
      { title: "Visitor", path: "/visitor" },
    ],
  },
  {
    title: "Location",
    path: "/location",
    icon: getIcon(landmark),
    children: [
      { title: "Country", path: "/country" },
      { title: "State", path: "/state" },
      { title: "City", path: "/city" },
      { title: "Landmark", path: "/landmark" },
    ],
  },
  {
    title: "LVB Pin Badge",
    path: "/lvb-pin-badge",
    icon: getIcon(npmFill),
  },
  {
    title: "Setting",
    path: "/setting",
    icon: getIcon(setting),
  },
].filter((f) => f != false);

const cityconfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon(dashboard),
  },
  {
    title: "Documents",
    path: "/documents",
    icon: getIcon(documents),
  },
  // {
  //   title: "Member Attendance",
  //   path: "/memberattendance",
  //   icon: getIcon(peopleFill)
  // },
  {
    title: "Member",
    path: "/member",
    icon: getIcon(member),
  },
  {
    title: "Meeting",
    path: "/meeting",
    icon: getIcon(meeting),
  },
  {
    title: "Chapter",
    path: "/chapter",
    icon: getIcon(chp),
  },
  {
    title: "Poll",
    path: "/poll",
    icon: getIcon(poll),
  },

  {
    title: "Gallery",
    path: "/gallery",
    icon: getIcon(camera),
  },
  {
    title: "Event-Announcement",
    path: "/event-announcement",
    icon: getIcon(event),
  },

  loginUser?.u_type != 2 && {
    title: "Staff",
    path: "/staff",
    icon: getIcon(staff),
  },
  {
    title: "Task",
    path: "/task",
    icon: getIcon(task),
  },
  {
    title: "Lead",
    path: "/lead",
    icon: getIcon(lead),
  },
  {
    title: "Suggestion",
    path: "/lvb-suggestion",
    icon: getIcon(lead),
  },
  {
    title: "Visitor Block List",
    path: "/visitor-block-list",
    icon: getIcon(personDeleteFill),
  },
  {
    title: "Thankyou Note Approval",
    path: "/business-approval",
    icon: getIcon(pricetagsFill),
  },
  {
    title: "Topper Report",
    path: "/topper-report",
    icon: getIcon(report),
  },
  {
    title: "City's Chapter Report",
    path: "/city-chapter-report",
    icon: getIcon(report),
  },
  {
    title: "Chapter Attendance Report",
    path: "/chapterAttendance-report",
    icon: getIcon(report),
  },
  {
    title: "Chapter's Member Report",
    path: "/chapters-member-report",
    icon: getIcon(report),
  },
  {
    title: "Member Induction Report",
    path: "/member-induction-report",
    icon: getIcon(report),
  },
  {
    title: "Pin Assign Report",
    path: "/pin-assign-report",
    icon: getIcon(report),
  },
  {
    title: "Member's Reports",
    path: "",
    icon: getIcon(report),
    children: [
      { title: "V2V Report", path: "/v2v-report" },
      { title: "Business Report", path: "/business-report" },
      { title: "Reference Report", path: "/reference-report" },
      { title: "Visitor Report", path: "/visitorreport" },
      { title: "Testimonial Report", path: "/testimonial-report" },
      { title: "Attendance Report", path: "/attendance-report" },
    ],
  },
  {
    title: "Current Record",
    path: "/location",
    icon: getIcon(landmark),
    children: [
      { title: "V2V", path: "/v2v" },
      { title: "Business", path: "/business" },
      { title: "Reference", path: "/reference" },
      { title: "Visitor", path: "/visitor" },
      { title: "Testimonial", path: "/testimonial" },
    ],
  },
].filter((f) => f != false);

const staffconfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon(dashboard),
  },
  {
    title: "Task",
    path: "/task",
    icon: getIcon(task),
  },
  {
    title: "Lead",
    path: "/lead",
    icon: getIcon(lead),
  },
];

// const sidebarConfig = [
//   {
//     title: "Country",
//     path: "/country",
//     icon: getIcon(country)
//   },
//   {
//     title: "State",
//     path: "/state",
//     icon: getIcon(state)
//   },
//   {
//     title: "City",
//     path: "/city",
//     icon: getIcon(city)
//   },
//   {
//     title: "Landmark",
//     path: "/landmark",
//     icon: getIcon(home)
//   },
//   {
//     title: "Member",
//     path: "/member",
//     icon: getIcon(member)
//   },
//   {
//     title: "Meeting",
//     path: "/meeting",
//     icon: getIcon(meeting)
//   },
//   {
//     title: "Greeting",
//     path: "/greeting",
//     icon: getIcon(mic)
//   },
//   {
//     title: "Member Attendance",
//     path: "/memberattendance",
//     icon: getIcon(peopleFill)
//   },
//   {
//     title: "Event-Announcement",
//     path: "/event-announcement",
//     icon: getIcon(event)
//   },
//   // {
//   //   title: "Staff",
//   //   path: "/staff",
//   //   icon: getIcon(staff)
//   // },
//   // {
//   //   title: "Task",
//   //   path: "/task",
//   //   icon: getIcon(task)
//   // },
//   // {
//   //   title: "Lead",
//   //   path: "/lead",
//   //   icon: getIcon(lead)
//   // },
//   {
//     title: "Chapter",
//     path: "/chapter",
//     icon: getIcon(chp)
//   },

//   {
//     title: "Gallery",
//     path: "/gallery",
//     icon: getIcon(camera)
//   },
//   {
//     title: "Poll",
//     path: "/poll",
//     icon: getIcon(poll)
//   },
//   {
//     title: "V2V",
//     path: "/v2v",
//     icon: getIcon(v2v)
//   },
//   {
//     title: "Reference",
//     path: "/reference",
//     icon: getIcon(refrence)
//   },
//   {
//     title: "Business",
//     path: "/business",
//     icon: getIcon(shoppingBagFill)
//   },
//   {
//     title: "Setting",
//     path: "/setting",
//     icon: getIcon(setting)
//   }
//   // {
//   //   title: "login",
//   //   path: "/login",
//   //   icon: getIcon(login)
//   // },
//   // {
//   //   title: "Not found",
//   //   path: "/404",
//   //   icon: getIcon(refrence)
//   // }
// ];

const sidebarConfig =
  loginUser?.u_type == 0
    ? adminconfig
    : loginUser?.u_type == 2 &&
      loginUser?.p_user_id == 1 &&
      loginUser?.management_p != 0
    ? adminconfig
    : loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 && loginUser?.management_p != 0)
    ? cityconfig
    : staffconfig;

export default sidebarConfig;
