import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";

import { Routes } from "src/constant/api_url";
// components
// import { CityForm } from 'src/components/Form/City';

import Page from "../components/Page";
import { MeetingForm } from "../components/Form/Meeting";
import { getAllMeeting } from "src/utils/jilcommon";
import {
  EditSharp,
  RestartAlt,
  DeleteSharp,
  PeopleAlt,
} from "@mui/icons-material";
import {
  deleteMeeting,
  GetMeetingById,
  resetMeetingMember,
} from "src/api/commonapi";
import Select from "react-select";
import {
  fetchchapterbyuser,
  fetchChaptList,
  fetchmeetingbyuser,
} from "src/utils/common";
import Label from "src/components/Label";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function Meeting() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [step, setStep] = useState(1);
  const [meetinglist, setMeetingList] = useState([]);
  const [memberlist, setmemberlist] = useState(null);
  const [meetingData, setmeetingData] = useState(null);
  const [chaptlist, setchaplist] = useState([]);
  const [newmeetinglist, setnewmeetinglist] = useState([]);
  const [filterOption, setFilterOption] = useState([
    { value: 0, label: "All Chapters" },
  ]);
  const [filter, setFilter] = useState({
    defaulselect: { value: 0, label: "All Chapters" },
    chpt_id: 0,
  });

  useEffect(async () => {
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
    // setchaplist(await fetchChaptList());
    fetchMeeting();
  }, []);

  // Select Chapter
  useEffect(async () => {
    chaptlist.map((option) => {
      filterOption.push({ value: option.chpt_id, label: option.chpt_name });
    });
  }, [chaptlist]);

  const fetchMeeting = async () => {
    // setMeetingList(await getAllMeeting());
    setMeetingList(
      await fetchmeetingbyuser(loginUser.city_id, loginUser.u_type)
    );
  };

  const resetmeeting = async (m_id) => {
    const resp = await resetMeetingMember(m_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      fetchMeeting();
    }
  };

  // Delete Meeting
  const deletemeeting = async (m_id) => {
    const resp = await deleteMeeting(m_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();

    if (resp.status == 1) {
      fetchMeeting();
      setDvisible({ mval: false, id: 0 });
    }
  };

  const filterData = () => {
    let nmeeting = [];
    nmeeting =
      meetinglist &&
      meetinglist.filter((meeting) => {
        if (meeting.c_id == filter?.chpt_id || filter?.chpt_id == 0) {
          return meeting;
        }
      });

    setnewmeetinglist(nmeeting);
  };
  const getAttendance = async (e, row) => {
    navigate(`/attendace/${e}`, { state: { id: e, name: row.meeting_end_by } });
  };
  const getMeeting = async (e) => {
    const response = await GetMeetingById(e);
    if (Object.keys(response).length != 0) {
      setmeetingData(response);
      setStep(3);
    }
  };
  useEffect(async () => {
    if (filter.chpt_id != undefined) {
      await filterData();
    }
  }, [filter, meetinglist]);

  const expo_columns = [
    { label: "No", key: "meeting_id" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Date", key: "date" },
    { label: "Presentative", key: "bus_pre_name" },
    { label: "Second Presentative", key: "bus_s_pre_name" },
    { label: "Meeting End By", key: "meeting_end_by_per" },
    { label: "Address", key: "address" },
  ];
  const columns = [
    {
      name: "No",
      selector: (row) => row.meeting_id,
      sortable: true,
      width: "65px",
    },
    {
      name: "Chapter Name",
      selector: (row) => row.chpt_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "110px",
    },
    {
      name: "Presentative ",
      selector: (row) => row.bus_pre_name,
      sortable: true,
      width: "155px",
    },
    {
      name: "Second Presentative",
      selector: (row) => row.bus_s_pre_name,
      sortable: true,
      width: "155px",
    },
    {
      name: " Address",
      selector: (row) => row.address,
      sortable: true,
      width: "155px",
    },

    {
      name: "Meeting Close By",
      selector: (row) => row.meeting_end_by_per,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.meet_note,
      sortable: true,
      width: "180px",
      selector: (row) => (
        <>
          {row.status != "-1" ? (
            <>
              <Tooltip title="Edit Meeting Date">
                <IconButton
                  onClick={() => {
                    getMeeting(row.meeting_id);
                  }}
                >
                  <EditSharp color="warning" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove Presentative">
                <IconButton
                  onClick={() => {
                    resetmeeting(row.meeting_id);
                  }}
                >
                  <RestartAlt color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Meeting">
                <IconButton
                  onClick={() => {
                    setDvisible({ mval: true, id: row.meeting_id });
                    // deletemeeting(row.meeting_id);
                  }}
                >
                  <DeleteSharp color="error" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Attendace Of Meeting">
              <IconButton
                onClick={() => {
                  getAttendance(row.meeting_id, row);
                }}
              >
                <PeopleAlt color="info" />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletemeeting}
      />
      <Page title="Meeting | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Meeting
            </Typography>
          </Stack>

          {step != 1 && (
            <>
              <Card
                style={{
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                <MeetingForm
                  fetchMeeting={fetchMeeting}
                  data={meetingData}
                  setStep={setStep}
                  step={step}
                />
              </Card>
            </>
          )}

          <Card
            style={{
              padding: "25px",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          >
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <ChapterSelectDropDown
                dropDownLabel={"Select Chapter"}
                selectedState={filter}
                chapterKey="chpt_id"
                handleChange={(e) => {
                  setFilter({ chpt_id: e.value });
                }}
              />
            </Stack>
            {meetinglist && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={newmeetinglist}
                    headers={expo_columns}
                    filename={"MettingList.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  title="Meeting List"
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={newmeetinglist}
                />
              </>
            )}
          </Card>
          {/* End Category Form */}
        </Container>
      </Page>
    </>
  );
}
