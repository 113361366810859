import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchVisitorList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchVisitorByMember,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { deleteVisitorData, GetMemberByChptId } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

export default function VisitorReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [visitorData, setvisitorData] = useState([]);
  const [nvisitorData, setnvisitorData] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
  });

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const [filtereddata, setFilteredData] = useState({
    startDate: "",
    endDate: "",
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const filterData = (satrt, end) => {
    let nvisitor = [];

    nvisitor =
      visitorData &&
      visitorData.filter((visitor) => {
        if (
          moment(visitor.n_m_date, "DD-MM-YYYY").isBetween(
            satrt,
            end,
            "days",
            "[]"
          ) ||
          (satrt == "" && end == "")
        ) {
          return visitor;
        }
      });

    setnvisitorData(nvisitor);
  };

  useEffect(async () => {
    if (
      filtereddata.startDate != undefined ||
      filtereddata.endDate != undefined
    ) {
      await filterData("", "");
    }
  }, [visitorData]);

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getvisitorlist = async (ch_id, m_id) => {
    setvisitorData(await fetchVisitorByMember(ch_id, m_id));
  };
  const deleteVisitor = async (v_id) => {
    const resp = await deleteVisitorData(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getvisitorlist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Invite By", key: "m_name" },
    { label: "Date", key: "n_m_date" },
    { label: "Visitor Name", key: "n_m_person_name" },
    { label: "Visitor Business", key: "n_m_business_name" },
    { label: "Contact Number", key: "n_m_mo_no" },
    { label: "Business Category", key: "n_m_business_category" },
    { label: "Visitor Type", key: "visitor_in_chapter_type" },
    { label: "Invited At", key: "other_chapter_name" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.row_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Invite By",
      wrap: true,
      selector: (row) => row.v_by_name + " (" + row.member_Business + ")",
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.n_m_date,
      sortable: true,
    },
    {
      name: "Visitor Name",
      wrap: true,
      selector: (row) => row.n_m_person_name,
      sortable: true,
    },
    {
      name: "Visitor Business",
      wrap: true,
      selector: (row) => row.n_m_business_name,
      sortable: true,
    },
    {
      name: "Contact Number",
      wrap: true,
      selector: (row) => row.n_m_mo_no,
      sortable: true,
    },
    {
      name: "Business Category",
      selector: (row) => row.n_m_business_category,
      wrap: true,
      sortable: true,
    },
    {
      name: "Visitor Type",
      sortable: true,
      wrap: true,
      selector: (row) => row.visitor_in_chapter_type,
    },
    {
      name: "Invited At",
      sortable: true,
      wrap: true,
      selector: (row) => row.other_chapter_name,
    },
  ];
  const changeChapterHandler = (e) => {
    setFilter({ defaulselect: e, chpt_id: e.value });
    getmemberbychp(e.value);
  };

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deleteVisitor}
      />
      <Page title="Visitor Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Visitor Report
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <ChapterSelectDropDown
                  dropDownLabel={"Select Chapter"}
                  selectedState={filter}
                  chapterKey="chpt_id"
                  handleChange={changeChapterHandler}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Member</label>
                <Select
                  value={selectedmember.defaulselect}
                  onChange={(e) => {
                    setselectedmember({ defaulselect: e, m_id: e.value });
                    getvisitorlist(filter.chpt_id, e.value);
                  }}
                  options={
                    memberdata &&
                    memberdata.map((option) => {
                      return { value: option.m_id, label: option.m_name };
                    })
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={
                      filtereddata?.startDate != ""
                        ? filtereddata?.startDate
                        : null
                    }
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filtereddata,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="enddate"
                    label="End Date "
                    value={
                      filtereddata?.endDate != "" ? filtereddata?.endDate : null
                    }
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilteredData({
                        ...filtereddata,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    filterData(filtereddata?.startDate, filtereddata?.endDate);
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilteredData({ startDate: null, endDate: null });
                    filterData("", "");
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {nvisitorData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nvisitorData}
                    headers={expo_columns}
                    filename={
                      "Visitor Data" + moment().format("DD-MM-YYYY") + ".csv"
                    }
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nvisitorData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
